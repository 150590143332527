import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import CustomPagination from "../../components/pagination";
import { i18n } from "../../translate/i18n";
import { useKeycloak } from "@react-keycloak/web";
import FilterList from "../../components/filterList/FilterList";
import { AddBoxRounded } from "@mui/icons-material";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import AlertDialogForConfirm from "../../components/confirmation/AlertDialog";
import Loading from "../../components/loading/loading";
import statusNY from "../../assets/statusNY.svg";
import ConfirmationModal from "../../components/confirmModal/ConfirmationModal";
import usePagination from '../../utils/hooks/usePagination';

export default function SchoolYearListingPage() {
  const { keycloak } = useKeycloak();
  const [employeeIncomingData, setEmployeesIncomingData] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [newStatus, setNewStatus] = useState('');

  const {
    currentPage,
    totalPages,
    setCurrentPage,
    pageSize,
    handlePageSizeChange,
    availablePageSizes,
    handlePaginationData,
  } = usePagination(employeeIncomingData, [5, 10, 20, 100]);
  console.log("TOTAL PAGE: ", totalPages, currentPage, employeeIncomingData.length)
  const CreateLesson = () => {
    navigate("/SchoolYear/create");
  };

  const handleCloseDeleteModal = () => {
    setDeleteItemId(null);
    setOpenDeleteModal(false);
  };


  const handleCloseStatusModal = () => {
    setStatusId(null);
    setOpenStatusModal(false);
  };


  const handleClickOpenDeleteModal = (id) => {
    console.log("Opening delete modal. ID:", id);
    setDeleteItemId(id);
    setOpenDeleteModal(true);
  };

  const handleClickOpenStatusModal = (id, newStatus) => {
    console.log("Opening status modal. ID:", id);
    setStatusId(id);
    setNewStatus(newStatus);
    setOpenStatusModal(true);
  };
  const getEmployee = async () => {
    setLoading(true);
    if (!page) {
      setLoading(false);
      return;
    }

    const params = new URLSearchParams({ page: page });

    try {

      /* let response
      // Check if data is in localStorage
      const cachedData = localStorage.getItem(
        `/school-year${currentPage}Size${pageSize}`
      );
      if (cachedData) {
        response = JSON.parse(cachedData);
        console.log(response.data?.data, "DATA")
      } else {
        const url = `${apiUrl}/school-year?page=${currentPage}&limit=${pageSize}`;

        response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        });

       localStorage.setItem(
          `/school-year${currentPage}Size${pageSize}`,
          JSON.stringify(response)
        );
      } */
      const url = `${apiUrl}/school-year?page=${currentPage}&limit=${pageSize}`;

       const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        });

      setEmployeesIncomingData(response.data?.data);
      handlePaginationData(response)
      if (localStorage.getItem("enployeeslPage")) setPage(response.data.page);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    handleClickOpenStatusModal(id, newStatus);
  };

  const handleConfirmStatusChange = async (newStatus) => {
    try {
      const response = await axios.put(
        `${apiUrl}/school-year/${statusId}/status/${newStatus}`,
        null,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );

      if (response.status === 200) {
        getEmployee();
      } else {
        console.error(
          "Failed to update status. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      handleCloseStatusModal();
    }
  };

  useEffect(() => {
    if (keycloak.token) {
      getEmployee();
    }
  }, [keycloak.token, currentPage]);

  const handleDisplayDataToVisualize = (id) => {
    navigate(`/SchoolYear/${id}?disabled=true`);
  };

  const handleEdit = (id) => {
    navigate(`/SchoolYear/${id}`);
  };

  const handleDeleteItem = async (id) => {
    console.log("Deleting item with ID:", id);
    setDeleteItemId(id);
    try {
      const response = await axios.delete(`/school-year/${id}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      if (response.status === 200) {
        getEmployee();
      } else {
        console.error(
          "Failed to delete employee. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      handleCloseDeleteModal();
    }
  };


  const { format } = require('date-fns');

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'yyyy-MM-dd');
  };

  const solveDate = (employeeIncomingData) => {
    let d = new Date(employeeIncomingData.startDate);
    let EndDate = new Date(employeeIncomingData.endDate);
    return (
      employeeIncomingData.startDate &&
      d.getFullYear() +
      (employeeIncomingData.endDate && "-" + EndDate.getFullYear())
    );
  };


  return (
    <>
      <Grid
        container
        component={Paper}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "relative",
          boxShadow: "none",
          padding: { xs: 2, md: 3 },
        }}
      >
        {loading && <Loading />}
        <Grid item sx={{ width: "100%" }}>
          {!loading && (
            <div className="container-da-tabela-responsive">
              <TableContainer
                sx={{
                  overflowX: "auto",
                  border: "1px solid #E9ECF5",
                }}
              >
                <Table
                  sx={{
                    minWidth: "100%",
                    tableLayout: "fixed",
                  }}
                  aria-label="a dense table"
                >
                <TableHead>
                  <TableRow>
                    <TableCell
                    align="left"
                    colSpan={5} 
                    sx={{
                    height: "59px",
                    fontSize: { xs: "12px", sm: "14px" },
                     }}
                     >
                      <Typography
                        variant="h3"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {i18n.t("sidebar.Leave")}
                        <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                          <Box sx={{ width: { xs: "100%", sm: "300px" } }}>
                            <AutoSearch
                              placeholder={i18n.t("others.search")}
                              search={search}
                              setSearch={setSearch}
                              sx={{ width: "100%" }}
                            />
                          </Box>
                          <Box sx={{ width: { xs: "100%", sm: "100px" }, marginLeft: { xs: 0, sm: "20px" } }}>
                            <Select
                              value={pageSize}
                              onChange={handlePageSizeChange}
                              displayEmpty
                              inputProps={{ "aria-label": "Page size" }}
                              sx={{
                                width: "80px",
                                height: "40px",
                              }}
                            >
                              {availablePageSizes.map((size) => (
                                <MenuItem key={size} value={size}>
                                  {size}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                          <Box>
                            <Button
                               variant="outlined"
                               sx={{
                                 height: "35px",
                                 width: "80px",
                                 color: "#FFC107",
                                 backgroundColor: "#FFFFFF",
                                 borderColor: "#FFC107",
                                 "&:hover": {
                                   color: "#FFC107",
                                   backgroundColor: "#FFFFFF",
                                   borderColor: "#FFC107",
                                 },
                               }}
                              onClick={CreateLesson}
                            >
                              <AddBoxRounded />
                              {i18n.t("button.new")}
                            </Button>
                          </Box>
                        </Box>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                     height="56px"
                     sx={{
                       backgroundColor: "#F8FAFD",
                       "& th": {
                         overflow: "hidden",
                         textOverflow: "ellipsis",
                         whiteSpace: "nowrap",
                         fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
                       },
                     }}
                   
                  >
                    <TableCell sx={{ color: "#939a9f" }} align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("sidebar.Leave")}</Typography></TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.startDate")}</Typography></TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.endDate")}</Typography></TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="center"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.status")}</Typography></TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="center"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.options")}</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeIncomingData.map((data, i) => (
                    <TableRow key={i}
                    sx={{
                      "& td": {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
                      },
                    }}
                  >
                      <TableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> {solveDate(data)}</Typography></TableCell>
                      <TableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> {formatDate(data.startDate)}</Typography></TableCell>
                      <TableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> {formatDate(data.endDate)}</Typography></TableCell>
                      <TableCell align="center"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> 
                        <Box display="flex" alignItems="center" justifyContent="center">
                          {data.status}
                          <button
                            style={{
                              border: "none",
                              background: "none",
                              cursor: "pointer",
                              padding: "0",
                            }}
                            onClick={() => {
                              let newStatus;
                              if (data.status === "Em criação") {
                                newStatus = "in_progress";
                              } else if (data.status === "A decorrer") {
                                newStatus = "completed";
                              } else {
                                newStatus = "creating";
                              }
                              handleStatusChange(data.id, newStatus);
                            }}
                          >
                            <img
                              src={statusNY}
                              alt="Status Icon"
                              style={{ width: "18px", height: "18px" }}
                            />
                          </button>
                        </Box>
                        </Typography> </TableCell>
                      <TableCell align="center">
                        <ActionButtons
                          id={data.id}
                          handleVisualize={handleDisplayDataToVisualize}
                          handleEdit={handleEdit}
                          handleDeleteItem={() => handleClickOpenDeleteModal(data.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </div>

          )}
       
        </Grid>
      </Grid>
  
      {!loading && employeeIncomingData?.length ? (
        <Grid sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <CustomPagination
            setPage={setCurrentPage}
            totalPages={totalPages}
            page={currentPage}
          />
        </Grid>
      ) : null}
  
      {deleteItemId && (
        <AlertDialogForConfirm
          open={openDeleteModal}
          id={deleteItemId}
          handleClose={handleCloseDeleteModal}
          handleDeleteItem={handleDeleteItem}
        />
      )}
      {statusId && (
        <ConfirmationModal
          open={openStatusModal}
          handleClose={handleCloseStatusModal}
          handleConfirm={() => handleConfirmStatusChange(newStatus)}
          newStatus={newStatus}
        />
      )}
    </>
  );
}