import {
  Box,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import Textfield from "../../components/form-components/text-field";
import { MainButton } from "../../components/main-button/MainButton";
import { Form, Formik } from "formik";
import { i18n } from "../../translate/i18n";
import Button from "@mui/material/Button";
import "./AddCurricularPlan.css";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select from "../../components/form-components/select";
import HandleUrl from "../../utils/HandleUrl";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import CollapsibleTableVariant from "./CollapsibleTableVariant";
import { v4 as uuidv4 } from "uuid";
import { useSearch } from "../../utils/hooks/useSearch";
import * as Yup from "yup";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import FallBackCard from "../../components/FallBackComponents/FallBackCard";
import DraggableSubject from "./DraggableSubject";
import DisciplinaModal from "../staff/components/forms-fields/modal";
import ButtonWithTitle from "../../components/styleButton/styleButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  extractSemesterName,
  getErrorMessage,
  handleFailure,
} from "../../utils/functions/utilFunctions";
import { fetchAllItems } from "../../utils/functions/formCRUDFunctions";
import { MessageDialog } from "../../components/my-modal/MessageDialog";
import { useMessageModal } from "../../utils/hooks/useModals";
import { Alert, Snackbar } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export const columnsTitle = [
  { label: "Unidade curricular", field: "name" },
  { label: "Créditos", field: "creditos", input: true },
  { label: "Procedente", field: "procedente", select: true },
  { label: "Dispensa", field: "dispenca", showCheckBox: true },
];

const initializeListData = (totalNumberOfYears) => {
  return Array.from({ length: Number(totalNumberOfYears) }, (_, index) => {
    const yearNumber = index + 1;

    return {
      year: `${yearNumber}º Ano`,
      "1º Semestre": [],
      "2º Semestre": [],
    };
  });
};


export function revertSemesterToListData(
  semesterInfo,
  totalNumberOfYears,
  setListData,
  setStatus
) {
  // Inicializa a estrutura para os anos e semestres
  let listData = initializeListData(totalNumberOfYears);

  console.log("LIST DATA INICIAL: ", listData);

  if (semesterInfo?.length) {
    semesterInfo.forEach((info) => {
      const { semester, year, ...item } = info;

      // Define o semestre com base no valor (1 -> 1º Semestre, 2 -> 2º Semestre)
      const relativeSemester = semester === 2 ? "2º Semestre" : "1º Semestre";

      // Monta o objeto da matéria
      const newItem = {
        id: uuidv4(),
        name: item?.subject?.name || item?.subject,
        subjectId: item?.subject?.id,
        procedente: item?.procedente
          ? item?.procedente?.name || item?.procedente
          : undefined,
        procedentId: item?.procedente ? item?.procedente?.id : undefined,
        creditos: Number(item.credits),
        dispenca: Boolean(Number(item.dispensation)),
      };

      // Encontra o objeto correspondente ao ano atual
      const yearObject = listData.find(
        (yearObj) => yearObj.year === `${year}º Ano`
      );

      if (yearObject && yearObject[relativeSemester]) {
        // Adiciona a matéria ao semestre correto
        yearObject[relativeSemester].push(newItem);
      } else {
        console.error(
          `Chave de semestre ${relativeSemester} não encontrada para o ${year}º Ano`
        );
      }
    });
  }

  // Atualiza o estado com os dados finais
  setListData(listData);
  setStatus((prev) => ({ ...prev, isLoading: false }));

  console.log("LIST DATA FINAL: ", listData);
}

const AddCurricularPlan = () => {
  const [listData, setListData] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  //Form states
  const initialFormMock = {
    course: {
      name: "",
      graduation: "",
      totalNumberOfYears: "",
      workingHours: "",
      price: "",
    },
  };
  const [iNITIAL_FORM_STATE, setINITIAL_FORM_STATE] = useState(initialFormMock);

  const isDisabled = HandleUrl();

  const { id: idPC } = useParams();
  const [id, setId] = useState(idPC);
  const navigate = useNavigate(); // Hook para navegação

  const { keycloak } = useKeycloak();
  const [departmentsIncommingData, setDepartmentsIncommingData] = useState("");
  const [graduationIncommingData, setGraduationIncommingData] = useState("");
  const [subjectsIncommingData, setSubjectsIncommingData] = useState([]);

  //Table states
  let isEditing = !isDisabled;
  const [openSemester, setOpenSemester] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState("");

  const [status, setStatus] = useState({
    isLoading: true,
    totalYears: iNITIAL_FORM_STATE?.course?.totalNumberOfYears,
    fetchingSubjects: true,
    noSubjects: false,
  });

  const [textBox, setTextBox] = useState([
    i18n.t(idPC ? "messages.updateTitle" : "messages.createTitle"),
    "",
  ]);

  const {
    open: openMessage,
    message,
    loading,
    setLoading,
    handleClose,
    handleClickOpen,
    changeState,
    handleSuccess,
    errorFallback,
  } = useMessageModal();
  const handleBadRequest = (response) => {
    if (response.status === 409) {
      alert("O curso já existe! Tente outro nome ou faça alterações no curso existente.");
    } else {
      alert("Esse Curso Já Exite, Tente Outro!");
    }
  };

  //Form validation
  const validationMessage = {
    required: i18n.t("messages.required"),
    positive: i18n.t("messages.bePositiveNumber"),
  };

  const validationSchema = Yup.object().shape({
    course: Yup.object().shape({
      name: Yup.string().required(validationMessage.required),
      price: Yup.number()
        .min(0, validationMessage.positive)
        .required(validationMessage.required),
      workingHours: Yup.number()
        .min(0, validationMessage.positive)
        .required(validationMessage.required),
      totalNumberOfYears: Yup.number()
        .min(0, validationMessage.positive)
        .required(validationMessage.required),
      graduation: Yup.string().required(validationMessage.required),
    }),
  });

  //On updating a PC

  const handleActivateSemester = (semester) => {
    setSelectedSemester(semester);
  };

  const fetchAll = fetchAllItems(keycloak);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requests = [
          fetchAll("departments", setDepartmentsIncommingData),
          fetchAll(
            "graduations",
            setGraduationIncommingData,
            (input) => input && input.map((graduation) => graduation.name)
          ),
          fetchAll("subject", setSubjectsIncommingData),
        ];

        const [, , subjectsData] = await Promise.all(requests);
        const fetchingSubjects = !Array.isArray(subjectsData);
        const noSubjects =
          !Array.isArray(subjectsData) || subjectsData?.length === 0;

        setStatus((prev) => ({
          ...prev,
          isLoading: false,
          fetchingSubjects,
          noSubjects,
        }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("fetching updated id: ", id);
        const responsePC = await axios.get(`/curricular-plan/${id}`);

        console.log("Curricular plan: ", responsePC.data);
        setINITIAL_FORM_STATE(responsePC.data);
        revertSemesterToListData(
          responsePC.data?.course?.courseSemesterSubjects,
          responsePC.data?.course?.totalNumberOfYears,
          setListData,
          setStatus
        );
        setStatus((prev) => ({
          ...prev,
          totalYears: responsePC.data?.course?.totalNumberOfYears,
        }));
        setSelectedDeparts(responsePC.data?.course?.department);
      } catch (error) {
        console.error(error);
      }
    };

    if (id) fetchData();
  }, [id, status.totalYears]);

  console.log("initial Updated id: ", id);
  //Getting semesterInfo

  console.log("List data: ", listData);

  const [lastInputData, setLastInputData] = useState([]); //To save the last changed creditos and dispensa
  console.log("Last input data: ", lastInputData);

  const computeNewList = (prevList, updatedValues) => {
    let newList = [...prevList];
    updatedValues.forEach((updatedData) => {
      if (updatedData) {
        const { id, creditos, dispenca } = updatedData;
        const [yearIndex, semester, dataIndex] = id.split("-");
        newList[yearIndex][semester][dataIndex] = {
          ...newList[yearIndex][semester][dataIndex],
          creditos,
          dispenca,
        };
      }
    });
    return newList;
  };
  const saveSemesterInputData = (
    updatedValues,
    setInputValues,
    removeEmptyFields = null
  ) => {
    if (!isDisabled) {
      // Compute the new list based on the previous state and the updated values
      const newList = computeNewList(listData, updatedValues);

      // Update the state with the new list
      setListData(newList);

      // Clear input values if there are updated values
      if (updatedValues?.length) {
        setInputValues([]);
      }

      // Return the new list
      return typeof removeEmptyFields === "function"
        ? removeEmptyFields(newList)
        : newList;
    }
  };

  //Form handlers
  const optimizeFormDataForCreate = (values) => {
    return {
      course: {
        name: values?.course?.name,
        graduation: values?.course?.graduation,
        totalNumberOfYears: values?.course?.totalNumberOfYears,
        workingHours: values?.course?.workingHours,
        price: values?.course?.price,
        departmentId: selectedDeparts.map((department) =>
          String(department.id)
        ),
        // Add the classes and subjects fields
        classes: [], // Replace with the actual data
        subjects: [], // Replace with the actual data
      },
    };
  };

  console.log("subject incoming data: ", subjectsIncommingData);

  const optimizeFormDataForUpdate = (values) => {
    try {
      // Obter os dados atualizados da última entrada
      const newListData = saveSemesterInputData(
        lastInputData,
        setLastInputData,
        removeEmptyItems
      );
      console.log("Last ListData: ", newListData);

      return {
        course: {
          name: values?.course?.name,
          graduation: values?.course?.graduation,
          totalNumberOfYears: parseInt(values?.course?.totalNumberOfYears || 0, 10),
          workingHours: parseInt(values?.course?.workingHours || 0, 10),
          price: parseFloat(values?.course?.price || 0),
          departmentId: Array.isArray(selectedDeparts)
            ? selectedDeparts.map((department) => department.id)
            : [],
        },
        SemesterInfo: newListData.flatMap((yearData, yearIndex) => {
          const calculatedYear = yearIndex + 1;

          const semesterKeys = Object.keys(yearData).filter(
            (key) => key !== "year"
          );

          return semesterKeys.flatMap((semesterKey) => {
            const semesterId = parseInt(semesterKey, 10);

            return yearData[semesterKey].map((item) => ({
              credits: Number(item.creditos),
              dispensation: Boolean(item.dispensa),
              subjectId: Number(item.subjectId),
              semester: semesterId,
              year: calculatedYear,
              procedentId:
                item.procedentId === undefined
                  ? null
                  : Number(item.procedentId),
            }));
          });
        }),
      };
    } catch (error) {
      console.error("Error: ", error);
      setStatus((prev) => ({ ...prev, isLoading: false }));
    }
  };


  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("error");  // 'error', 'success', 'info'

  const createPC = (values) => {
    setLoading(true);

    try {
      const formData = optimizeFormDataForCreate(values);
      console.log("Creating: ", values);
      axios
        .post("curricular-plan", formData)
        .then(function (response) {
          if (response && response.status <= 201) {
            console.log("Successfully created!", response);
            handleSuccess();
            setId(response.data?.id);
          } else {
            console.error("Falha: ", response);
            handleBadRequest(response);  // Função genérica para lidar com erros
          }
        })
        .catch(function (error) {
          // Verifica se o erro é de conflito (curso já existente)
          if (error.response && error.response.status === 409) {
            const errorMessage = error.response.data.message || "O curso já existe! Tente outro nome ou faça alterações no curso existente.";
            setSeverity("error");
            setSnackbarMessage(errorMessage);
            setOpenSnackbar(true);
          } else {
            const fallbackMessage = error.response?.data?.message || "Esse Curso ja existe, Tente Outro!";
            setSeverity("error");
            setSnackbarMessage(fallbackMessage);
            setOpenSnackbar(true);
          }
        });
    } catch (error) {
      console.error(error);
      setSeverity("error");
      setSnackbarMessage("Erro inesperado. Por favor, tente novamente.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const updatePC = (values) => {
    console.log("Updating: ", values);

    const formData = optimizeFormDataForUpdate(values);

    console.log("formData.SemesterInfo: ", formData.SemesterInfo);

    axios
      .put(`/curricular-plan/${id}/${values.course.id}`, formData)
      .then(function (response) {
        // Handle success (if needed)
        if (response && response?.status <= 201) {
          console.log("Successfully updated! ", response?.data);
          console.log("Updating Data: ", formData);

          //Because the updated Curricular plan has a new id
          setId(response.data?.id);

          // Usando a nova mensagem de sucesso
          setTextBox((prev) => {
            const textCopy = [...prev];
            textCopy[1] = "Curso atualizado com sucesso!"; // Mensagem atualizada
            return textCopy;
          });
          setStatus((prev) => ({ ...prev, isLoading: false }));
        } else {
          console.log("Failed request: ", handleFailure(response));
          setTextBox([i18n.t("messages.wrong"), handleFailure(response)]);
          setStatus((prev) => ({ ...prev, isLoading: false }));
        }
      })
      .catch(function (error) {
        setTextBox([i18n.t("messages.wrong"), getErrorMessage(error)]);
        setStatus((prev) => ({ ...prev, isLoading: false }));
        console.error("Erro: ", error);
      });

    //Refetch the Curricular plan, if total years changed
    if (values?.course?.totalNumberOfYears !== status.totalYears) {
      setStatus((prev) => ({
        ...prev,
        totalYears: values.course.totalNumberOfYears,
      }));
    }
  };

  console.log("LIST DATA: ", listData)

  //Table handlers

  const createDroppableItem = (stateSetter, selectedSemester) => {
    stateSetter((prev) => {
      const newList = [...prev];
      const semesterName = extractSemesterName(selectedSemester);
      const yearIndex = newList.findIndex(
        (item) => item.year === selectedSemester.split("º")[0] + "º Ano"
      );
      if (yearIndex !== -1) {
        newList[yearIndex][semesterName].push({
          id: uuidv4(),
          name: " ",
          procedente: " ",
          creditos: 0,
          dispenca: false,
          semester: selectedSemester,
        });
      }
      return newList;
    });
  };

  const removeEmptyItems = (currentList) => {
    // Clone the current list to avoid direct mutation
    const newList = [...currentList];

    // Extract the semester name using the provided utility function
    const semesterName = extractSemesterName(selectedSemester);

    // Find the index of the year corresponding to the selected semester
    const yearIndex = newList.findIndex(
      (item) => item.year === selectedSemester.split("º")[0] + "º Ano"
    );

    // Proceed only if the year is found
    if (yearIndex !== -1) {
      // Safely access the semester within the year and filter out items with a name of " "
      const semesterData = newList[yearIndex][semesterName];
      if (semesterData) {
        newList[yearIndex][semesterName] = semesterData.filter(
          (item) => item.name !== " "
        );
      }
    }

    // Return the updated list
    return newList;
  };

  const removeDragEndEmptyItems = (stateSetter) => {
    stateSetter((prev) => {
      const newList = [...prev];
      const semesterName = extractSemesterName(selectedSemester);
      const yearIndex = newList.findIndex(
        (item) => item.year === selectedSemester.split("º")[0] + "º Ano"
      );
      newList[yearIndex][semesterName] = newList[yearIndex][
        semesterName
      ].filter((item) => item.name !== " " || item.procedente !== " ");
      return newList;
    });
  };

  const handleDelete = (index, field) => {
    setListData((prev) => {
      const newList = [...prev];
      const semesterName = extractSemesterName(selectedSemester);
      const yearIndex = newList.findIndex(
        (item) => item.year === selectedSemester.split("º")[0] + "º Ano"
      );
      const [, , itemIndex] = index.split("-");
      newList[yearIndex][semesterName][itemIndex][field] = " ";
      return newList;
    });

    removeDragEndEmptyItems(setListData);
  };

  const handleDragStart = () => {
    if (!isEditing) {
      return;
    }
    createDroppableItem(setListData, selectedSemester);
  };
  //Drag and Drop
  const handleDragEnd = (event) => {
    const { active, over } = event;

    console.log("Over dragged: ", over);
    // Exit the function if dropped outside a droppable area
    if (!isEditing || !over) {
      console.log("Not editting or no destination");
      return;
    }

    const sourceIndex = active.id;
    const [field, destinationIndex] = over.id.split("*");

    if (!destinationIndex) return;
    handleFieldDrag(field, parseInt(sourceIndex), destinationIndex);

    removeDragEndEmptyItems(setListData);
  };

  const handleFieldDrag = (field, sourceIndex, destinationIndex) => {
    const draggedName = filteredData[sourceIndex].name;
    const id = filteredData[sourceIndex]?.id?.toString();

    const semesterName = extractSemesterName(selectedSemester);

    const isAlreadyInList = listData.some((year) =>
      year[semesterName]
        ? year[semesterName].some((item) => item[field] === draggedName)
        : false
    );

    if (isAlreadyInList) {
      return "Item is already in the list";
    }

    const [year, semester, dataIndex] = destinationIndex.split("-");

    const updatedItems = listData.map((item) => {
      if (item.year === year) {
        return {
          ...item,
          [semester]: item[semester]
            ? item[semester].map((item, index) =>
              index === parseInt(dataIndex)
                ? {
                  ...item,
                  [field]: draggedName,
                  ...(field === "name" ? { subjectId: id } : {}),
                  ...(field === "procedente" ? { procedentId: id } : {}),
                }
                : item
            )
            : [],
        };
      }
      return item;
    });

    setListData(updatedItems);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  //Seach subjects
  const matchFunction = (item, compareValue, property) => {
    console.log("compareValue: ", compareValue);
    const lowercaseItem = item[property].toLowerCase();
    const lowercaseValue = compareValue.toLowerCase();
    return lowercaseItem.includes(lowercaseValue);
  };

  const { searchTerm, setSearchTerm, filteredData } = useSearch(
    subjectsIncommingData,
    matchFunction,
    "name"
  );

  //Departments Modal functionalities
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeparts, setSelectedDeparts] = useState([]);

  const saveDepartmentNames = () => {
    // Salve os nomes das disciplinas em seu estado ou estrutura de dados preferida
    console.log(
      "Salvando nomes das Unidade curriculares:",
      selectedDeparts.map((depart) => depart.name)
    );
  };

  const handleRemoveDepartment = (department) => {
    console.log("Unidade curricular para remover:", department);
    console.log("Unidade curriculares selecionadas antes da remoção:", selectedDeparts);

    const updatedDeparts = selectedDeparts.filter(
      (currentDepart) => currentDepart?.id !== department?.id
    );
    setSelectedDeparts(updatedDeparts);
  };

  console.log("Initial data: ", iNITIAL_FORM_STATE);
  console.log("Selected Departs: ", selectedDeparts);

  return (
    <>
      <Box sx={{
        backgroundColor: "#FFFFFF",
        marginRight: "10px",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        padding: "10px",
        marginTop: "-30px",
        width: { xs: "100%", sm: "100%", md: "80%", lg: "75%" },
        top: "100px",
        marginLeft: "auto",
        height: "auto",
        minHeight: "30px",
        position: "absolute",
        zIndex: 1000,
      }}>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
          <IconButton sx={{
            color: "#FBC02D",
            marginRight: "0px",
            display: "flex",
            alignItems: "center",
          }}
            onClick={() => navigate('/curricular-plan')}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px", marginTop: "-8px", transform: "translateY(21px)" }} />
            <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px", marginTop: "-8px", transform: "translateY(21px)" }} />
          </IconButton>
          <Typography variant="h6" sx={{
            fontWeight: 400,
            fontSize: "18px",
            color: "#FBC02D",
            transform: "translateY(21px)",
            marginRight: "10px",
            marginTop: "-8px",
          }}>
            Voltar
          </Typography>
          <Typography variant="h6" sx={{
            fontWeight: 4600,
            fontSize: "18px",
            color: "#8893A9",
            marginTop: "-8px",
            transform: "translateY(21px)",
            gap: "20px",
          }}>
            Curso
          </Typography>
        </Box>
      </Box>
      <Box
        paddingTop="2rem"
        bgcolor="white"
        paddingLeft={{ xs: "1rem", sm: "1.5rem" }}
        paddingRight={{ xs: "1rem", sm: "1.5rem" }}
        sx={{
          fontWeight: "500",
        }}
      >
        <Formik
          initialValues={{
            ...iNITIAL_FORM_STATE,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { validateForm }) => {
            validateForm(values).then((errors) => {
              if (Object.keys(errors)?.length === 0) {
                setStatus((prev) => ({ ...prev, isLoading: true }));
                console.log("Submitting Values: ", values);
                if (id) {
                  updatePC(values);
                  console.log("values:", values);
                } else {
                  createPC(values);
                }
              } else {
                console.log("Form validation errors:", errors);
              }
            });
          }}
          enableReinitialize
        >
          {({ values }) => (
            <Form>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 600,
                  fontSize: "1.125rem",
                  paddingBottom: "1.25rem",
                }}
              >
                {iNITIAL_FORM_STATE?.course?.name ||
                  i18n.t("curricularPlan.titleCreate")}
              </Typography>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert onClose={() => setOpenSnackbar(false)} severity={severity} sx={{ width: '100%' }}>
                  {snackbarMessage}
                </Alert>
              </Snackbar>

              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={5}>
                  <Textfield
                    name={"course.name"}
                    label={i18n.t("others.name")}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Select
                    name="course.graduation"
                    options={graduationIncommingData}
                    label={i18n.t("others.graduation")}
                    disabled={isDisabled}
                    sendName={true}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <label>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "#42474B",
                        margin: "0px",
                        border: "none",
                        width: "100%",
                      }}
                    >
                      {i18n.t("others.department")}
                    </p>
                  </label>

                  <Grid item xs={12} sx={{ maxWidth: '600px', margin: '0 auto' }}> {/* Define uma largura máxima e centraliza o Grid */}
                    <ButtonWithTitle
                      title={
                        selectedDeparts?.length > 0
                          ? `${i18n.t("department.addedDeparts")}`
                          : `${i18n.t("department.addDepart")}`
                      }
                      onClick={() => setIsModalOpen(true)}
                      disabled={isDisabled}
                      style={{
                        width: "60%",
                        margin: "0 auto",
                        marginLeft: "-2px",
                      }}
                    />
                    <Grid container spacing={1} sx={{ marginTop: "8px" }}>
                      {selectedDeparts?.length
                        ? selectedDeparts.map((department) => (
                          <Grid item xs={12} sm={6} md={4} key={department.id}> {/* Responsivo: um item por linha em telas pequenas, dois em médias e três em grandes */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: 2,
                                marginBottom: 1,
                                background: "#E9ECF5",
                                padding: "1px",
                                marginTop: "8px",
                              }}
                            >
                              <Typography key={department.id}>
                                {department.name}
                              </Typography>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleRemoveDepartment(department)
                                }
                              >
                                {!isDisabled && <CloseIcon />}
                              </IconButton>
                            </div>
                          </Grid>
                        ))
                        : null}
                    </Grid>
                  </Grid>

                  <DisciplinaModal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onConfirm={(selectedDeparts) => {
                      setSelectedDeparts(selectedDeparts);
                      saveDepartmentNames();
                      setIsModalOpen(false);
                    }}
                    title={i18n.t("others.department")}
                    data={departmentsIncommingData}
                    selected={selectedDeparts}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.3}>
                  <Textfield
                    type="number"
                    name="course.price"
                    label={i18n.t("others.bribe")}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.3}>
                  <Textfield
                    type="number"
                    name="course.workingHours"
                    label={i18n.t("others.workload")}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <Textfield
                    type="number"
                    name="course.totalNumberOfYears"
                    label={i18n.t("others.year")}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isDisabled}
                    sx={{
                      width: "100%",
                      height: "35.5px",
                      marginTop: "15.5px",
                    }}
                  >
                    {i18n.t("button.addDisciplines")}
                  </Button>
                </Grid>
              </Grid>
              {id && (
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  onDragStart={handleDragStart}
                >
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={7}>
                      <FallBackLoader
                        isLoading={status.isLoading && id && !listData?.length}
                      />
                      {listData.map((year, index) => (
                        <Grid key={year.year}>
                          <CollapsibleTableVariant
                            title={year.year}
                            secondTitles={Object.keys(year).filter(
                              (key) => key !== "year"
                            )}
                            columnsTitle={columnsTitle}
                            tableData={year}
                            onIconClick={handleActivateSemester}
                            selectedSemester={selectedSemester}
                            handleDelete={handleDelete}
                            isEditing={isEditing}
                            saveSemesterInputData={saveSemesterInputData}
                            setLastInputData={setLastInputData}
                            year={year}
                            yearIndex={index}
                            openRow={openSemester}
                            setOpenRow={setOpenSemester}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <div className="subjects-container">
                        {id && (
                          <div className="header">
                            <div className="title">Adicionar Unidade curriculares</div>
                            <AutoSearch
                              placeholder={i18n.t("others.search")}
                              setSearch={setSearchTerm}
                              search={searchTerm}
                              sx={{ width: "100%", bgcolor: "white" }}
                              inputSx={{ width: "100%", bgcolor: "white" }}
                              iconPosition="end"
                              onClear={() => setSearchTerm("")}
                            />
                          </div>
                        )}
                        <div
                          className="body"
                          style={
                            status.fetchingSubjects
                              ? null
                              : {
                                overflowY: "scroll",
                                height: "165vh",
                              }
                          }
                        >
                          {filteredData?.length > 0 &&
                            filteredData.map((subject, index) => (
                              <DraggableSubject
                                key={subject.id}
                                subject={subject}
                                index={index}
                                isDraggable={isEditing && openSemester}
                              />
                            ))}
                          <FallBackLoader isLoading={status.fetchingSubjects} />
                          <FallBackCard
                            data={status.noSubjects ? [] : columnsTitle}
                            isLoading={status.fetchingSubjects}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </DndContext>
              )}

              {id && (
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "20px 0",
                    }}
                  >
                    <MainButton
                      url="/curricular-plan"
                      name={i18n.t("button.cancel")}
                    />
                    <AlertDialog
                      button={
                        id
                          ? i18n.t("button.update")
                          : i18n.t("button.register")
                      }
                      goBack={i18n.t("curricularPlan.title")}
                      head={textBox[0]}
                      body={
                        textBox[1] || (
                          <FallBackLoader isLoading={status.isLoading} />
                        )
                      }
                      urlGoBack="/curricular-plan"
                      stay={
                        id
                          ? i18n.t("others.curricularPlan")
                          : i18n.t("curricularPlan.titleCreate")
                      }
                      customStayHandler={
                        id
                          ? () => {
                            setTextBox([
                              i18n.t("messages.updateTitle"),
                              "",
                            ]);
                          }
                          : () => {
                            setINITIAL_FORM_STATE(initialFormMock);
                            setId(null);
                            setOpenSemester(null);
                            setSelectedSemester("");
                          }
                      }
                      urlStay={
                        id
                          ? `/curricular-plan/${id}`
                          : `/curricular-plan`
                      }
                      type="submit"
                      isValid={true}
                      isLoading={status.isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </Grid>
              )}
            </Form>
          )}
        </Formik>
      </Box>
      <br />

      {changeState && !id && (
        <MessageDialog
          id={id}
          open={openMessage}
          handleClose={handleClose}
          message={message}
          isLoading={loading}
        />
      )}
    </>
  );
};

export default AddCurricularPlan;
