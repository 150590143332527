import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import CustomPagination from "../../components/pagination";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import { useKeycloak } from "@react-keycloak/web";
import { useCallback } from "react";


import { i18n } from "../../translate/i18n";
import FilterList from "../../components/filterList/FilterList";
import { AddBoxRounded } from "@mui/icons-material";
import Loading from "../../components/loading/loading";
import { truncateText } from "../../utils/functions/utilFunctions";
import { useFilter, useSearch } from "../../utils/hooks/useSearch";

import FallBackCard from "../../components/FallBackComponents/FallBackCard";
import usePagination from "../../utils/hooks/usePagination";
import AlertDialogForConfirm from "../../components/confirmation/AlertDialog";
import { useConfirmDeleteModal } from "../../utils/hooks/useModals";
import {
  extractUniqueValues,
  flattenArray,
} from "../../utils/functions/tableFilterFunctions";
import { formatCurrencyToLocale } from "../../utils/functions/utilFunctions";
import { debounce } from "lodash";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8FAFD",
    color: "#939a9f",
  },
}));

const CurricularPlanListPage = () => {
  const [incomingData, setIncomingData] = useState([]);


  const navigate = useNavigate();
  const apiUrl = `${process.env.REACT_APP_API_URL}/curricular-Plan`;
  const { keycloak } = useKeycloak();
  const pathUrl = "/curricular-plan";

  const NewCurricularPlan = () => {
    navigate("/curricular-plan/add");
  };
  const [isLoading, setIsLoading] = useState(true);

  const {
    open,
    deleteItemId,
    message,
    loading,
    setLoading,
    success,
    handleClose,
    handleClickOpen,
    handleSuccess,
    handleBadRequest,
    errorFallback,
  } = useConfirmDeleteModal();

  const curricularPlanAnalyze = (id, status = true) => {
    let url = `${pathUrl}/${id}`;
    if (status) {
      url += `?disabled=${status}`;
    }
    navigate(url);
  };

  const [currentFilters, setCurrentFilters] = useState({});

  const editCurricularPan = (id) => {

    curricularPlanAnalyze(id, false);
  };

  const deleteCurricularPlan = (id) => {
    setLoading(true);
    axios
      .delete(`${apiUrl}/${id}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      })
      .then((response) => {
        if (response && response.status === 200) {
          handleSuccess();


          const queryString = Object.entries(currentFilters)
            .flatMap(([key, values]) =>
              Array.isArray(values)
                ? values.map(
                    (value) =>
                      `filter.course.${key}${encodeURIComponent(
                        key
                      )}=${encodeURIComponent(value)}`
                  )
                : `filter.course.${encodeURIComponent(
                    key
                  )}=${encodeURIComponent(values)}`
            )
            .join("&");
          setQueryString(queryString);

          // Clear the specific localStorage entries
          for (let i = 1; i <= totalPages; i++) {
            localStorage.removeItem(
              `curricularPlanPage${i}Size${pageSize}${queryString}`
            );
          }
          getCurricularPlan(currentFilters);
        } else {
          handleBadRequest(response);
        }
      })
      .catch(function (error) {
        console.error("Erro:", error);
        errorFallback(error);
      });
  };

  //Table filters
  const [selectedGraduations, setSelectedGraduations] = useState([]);
  const [selectedTotalYears, setSelectedTotalYears] = useState([]);
  const [selectedBribes, setSelectedBribes] = useState([]);

  const flattenedData = flattenArray(incomingData?.data, "course");
  const graduations = extractUniqueValues(flattenedData, "graduation");
  const totalYears = extractUniqueValues(flattenedData, "totalNumberOfYears");
  const prices = extractUniqueValues(flattenedData, "price");

  const filters = [
    {
      label: i18n.t("others.graduationType"),
      options: graduations,
      selectedOptions: selectedGraduations,
      setSelectedOptions: setSelectedGraduations,
    },
    {
      label: i18n.t("others.yearNumber"),
      options: totalYears,
      selectedOptions: selectedTotalYears,
      setSelectedOptions: setSelectedTotalYears,
    },
    {
      label: i18n.t("others.bribe"),
      options: prices,
      selectedOptions: selectedBribes,
      setSelectedOptions: setSelectedBribes,
    },
  ];

  //Search functionality

  const { searchTerm, setSearchTerm } = useSearch();

  const { setCanFilter, canFilter } = useFilter();
  const [queryString, setQueryString] = useState("");

  const filteringProps = {
    graduation: selectedGraduations,
    totalNumberOfYears: selectedTotalYears,
    price: selectedBribes,
    search: searchTerm,
  };

  //Pagination  & Items per page filter
  const items = incomingData?.data || [];

  const {
    currentPage,
    totalPages,
    setCurrentPage,
    pageSize,
    handlePageSizeChange,
    availablePageSizes,
    handlePaginationData,
  } = usePagination(items, [5, 10, 20, 100]);

  const getCurricularPlan = async (filters = {}) => {
    setIsLoading(true);
    setCurrentFilters(filters);
    try {
      let response;

      // Convert filters object to query string
      const queryString = Object.entries(filters)
        .flatMap(([key, values]) =>
          Array.isArray(values)
            ? values.map(
                (value) =>
                  `filter.course.${encodeURIComponent(
                    key
                  )}=${encodeURIComponent(value)}`
              )
            : `filter.course.${encodeURIComponent(key)}=${encodeURIComponent(
                values
              )}`
        )
        .join("&");
      setQueryString(queryString);

      console.log("queryString: ", queryString);

      // Check if data is in localStorage
      const cachedData = localStorage.getItem(
        `curricularPlanPage${currentPage}Size${pageSize}${queryString}`
      );
      if (cachedData) {
        response = JSON.parse(cachedData);
      } else {
        console.log("filters: ", filters);
        // Only include page and limit parameters if no filters are applied
        const url =
          canFilter || searchTerm
            ? `${apiUrl}?${queryString}`
            : `${apiUrl}?page=${currentPage}&limit=${pageSize}&${queryString}`;

        response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        });

        // Store data in localStorage
        localStorage.setItem(
          `curricularPlanPage${currentPage}Size${pageSize}${queryString}`,
          JSON.stringify(response)
        );
      }

      console.log("IncomingData: ", response?.data?.meta);
      setIncomingData(response?.data);
      setIsLoading(false);

      handlePaginationData(response);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const debouncedGetCurricularPlan = useCallback(
    debounce((filters) => getCurricularPlan(filters), 400),
    []
  );

  useEffect(() => {
    if (keycloak.token) {
      if (searchTerm) {
        debouncedGetCurricularPlan(filteringProps);
      } else {
        getCurricularPlan(filteringProps);
      }
    }
  }, [keycloak.token, currentPage, pageSize, canFilter, searchTerm]);

  useEffect(() => {
    // Clear the specific localStorage entries for the current page
    for (let i = 1; i <= totalPages; i++) {
      localStorage.removeItem(
        `curricularPlanPage${i}Size${pageSize}${queryString}`
      );
    }
  }, [pageSize]);

  useEffect(() => {
    // This function will run when the component unmounts
    return () => {
      // Clear all localStorage entries for the current page
      for (let i = 1; i <= totalPages; i++) {
        localStorage.removeItem(
          `curricularPlanPage${i}Size${pageSize}${queryString}`
        );
      }
    };
  }, [pageSize, totalPages, queryString]); 
  return (
    <>
      <Grid
        container
        component={Paper}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "relative",
          boxShadow: "none",
          padding: { xs: 2, md: 3 },
        }}
      >
        {isLoading && <Loading />}
        {!isLoading && (
          <Grid item xs={12}>
            <div className="container-da-tabela-responsiva">
            <TableContainer
                sx={{
                  overflowX: "auto",
                  border: "1px solid #E9ECF5",
                }}
              >
                <Table
                  sx={{
                    minWidth: "100%",
                    tableLayout: "fixed",
                  }}
                  aria-label="a dense table"
                >
                <TableHead>
                  <TableRow>
                    <TableCell
                    align="left"
                     colSpan={4}
                    sx={{
                     height: "59px",
                    fontSize: { xs: "12px", sm: "14px" },
                       }}
                     >
                        <Typography
                          variant="h3"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {i18n.t("curricularPlan.title")}
                          <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                            <Box sx={{ width: { xs: "100%", sm: "300px" } }}>
                              <AutoSearch
                                placeholder={i18n.t("others.search")}
                                search={searchTerm}
                                setSearch={setSearchTerm}
                                sx={{ width: "100%" }}
                              />
                            </Box>
                            <Box sx={{ width: { xs: "100%", sm: "100px" }, marginLeft: { xs: 0, sm: "20px" } }}>
                              <Select
                                value={pageSize}
                                onChange={handlePageSizeChange}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Page size",
                                }}
                                sx={{
                                  width: "80px",
                                  height: "40px",
                                }}
                              >
                                {availablePageSizes.map((size) => (
                                  <MenuItem key={size} value={size}>
                                    {size}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "120px",
                                height: "40px",
                                border: "1px solid #C3CAD9",
                                marginRight: "20px",
                                borderRadius: "5px",
                              }}
                            >
                              <FilterList
                                cardStyle={{
                                  position: "absolute",
                                  zIndex: "1",
                                  top: "140px",
                                  right: "323px",
                                }}
                                filters={filters}
                                setCanFilter={setCanFilter}
                                canFilter={canFilter}
                              />
                              {i18n.t("others.filter")}
                            </Box>
                            <Box>
                              <Button
                                variant="outlined"
                                sx={{
                                  height: "35px",
                                  width: "80px",
                                  color: "#FFC107",
                                  backgroundColor: "#FFFFFF",
                                  borderColor: "#FFC107",
                                  "&:hover": {
                                    color: "#FFC107",
                                    backgroundColor: "#FFFFFF",
                                    borderColor: "#FFC107",
                                  },
                                }}
                                onClick={NewCurricularPlan}
                              >
                                <AddBoxRounded />
                                {i18n.t("button.new")}
                              </Button>
                            </Box>
                          </Box>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ backgroundColor: "#F8FAFD", height: "56px", color: "#939a9f" }}>
                      <StyledTableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }}}> {i18n.t("others.name")}</Typography></StyledTableCell>
                      <StyledTableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.graduationType")}</Typography></StyledTableCell>
                      <StyledTableCell align="center"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.yearNumber")}</Typography></StyledTableCell>
                      <StyledTableCell align="center"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.options")}</Typography></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((data) => (
                      <TableRow key={data.id}>
                        <TableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500,  }}> {data?.course?.name}</Typography></TableCell>
                        <TableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> {data?.course?.graduation}</Typography></TableCell>
                        <TableCell align="center"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> {data?.course?.totalNumberOfYears}</Typography></TableCell>
                        <TableCell align="center"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> 
                          <ActionButtons
                            id={data.id}
                            handleVisualize={curricularPlanAnalyze}
                            handleEdit={editCurricularPan}
                            //handleDeleteItem={() => handleClickOpen(data.id)}
                          />
                       </Typography> </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <FallBackCard data={incomingData?.data} isLoading={isLoading} />
              </TableContainer>
            </div>
          </Grid>
        )}
      </Grid>

      {!isLoading && incomingData?.data?.length ? (
        <Grid sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <CustomPagination
            setPage={setCurrentPage}
            totalPages={totalPages}
            page={currentPage}
          />
        </Grid>
      ) : null}

      {deleteItemId && (
        <AlertDialogForConfirm
          id={deleteItemId}
          open={open}
          handleClose={handleClose}
          handleDeleteItem={deleteCurricularPlan}
          message={message}
          isSuccessful={success}
          isLoading={loading}
        />
      )}
    </>
  );
};

export default CurricularPlanListPage;