import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import HandleUrl from "../../utils/HandleUrl";
import { MainButton } from "../../components/main-button/MainButton";
import { useEffect, useState} from "react";
import { useKeycloak } from "@react-keycloak/web";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import { getItem as get } from "../../utils/functions/tableCRUDFunctions";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import {
  createItem as create,
  updateItem as update,
} from "../../utils/functions/formCRUDFunctions";
import "./contract.css";

export default function ContractForm() {
  let isDisabled = HandleUrl();
  const { keycloak } = useKeycloak();
  let page=1;
  const apiUrl = `${process.env.REACT_APP_API_URL}/contract-type`;

  const [INITIAL_FORM_STATE, setINITIAL_FORM_STATE] = useState({
    name: "",
    description: "",
    id: "",
  });
  const { id } = useParams();
  const navigate = useNavigate(); // Hook para navegação

  const isUpdate = !!id;

  const [status, setStatus] = useState({
    isLoading: true,
  });

  const [textBox, setTextBox] = useState([
    i18n.t(id ? "messages.updateTitle" : "messages.createTitle"),
    "",
  ]);

  const FORM_VALIDATION = Yup.object().shape({
    description: Yup.string().required(i18n.t("messages.required")),
    name: Yup.string().required(i18n.t("messages.required")),
    id: Yup.string().optional(),
  });
  console.log(INITIAL_FORM_STATE)
  const getItem = get(
    `${apiUrl}/${id}`,
    keycloak,
    setINITIAL_FORM_STATE,
    undefined,
    page
  );
  useEffect(() => {
    if (id) {
      getItem();
    }
  }, [id]);

  const formatFormData = (values) => ({
    name: values.name,
    description: values.description,
  });

  const createItem = create(
    formatFormData, 
    apiUrl, 
    setTextBox, 
    setStatus, 
    keycloak, 
    "Contracto criado com sucesso!"
  );

  const updateItem = update(
    formatFormData, 
    apiUrl, 
    setTextBox, 
    setStatus, 
    keycloak, 
    "Contracto atualizado com sucesso!"
  );


  return (
    <>
   <Box sx={{
    backgroundColor: "#FFFFFF",
    marginRight: "10px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    padding: "10px", // Reduzido o padding
    marginTop: "-30px",
    width: { xs: "100%", sm: "100%", md: "80%", lg: "75%" },
    top: "100px",
    marginLeft: "auto", 
    height: "auto", 
    minHeight: "30px", 
    position: "absolute",
    zIndex: 1000,
}}>
    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <IconButton sx={{
            color: "#FBC02D",
            marginRight: "0px",
            display: "flex",
            alignItems: "center",
        }}
        onClick={() => navigate('/contract')}
        >
            <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px", marginTop: "-8px", transform: "translateY(21px)" }} />
            <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px", marginTop: "-8px", transform: "translateY(21px)" }} />
        </IconButton>
        <Typography variant="h6" sx={{
            fontWeight: 400,
            fontSize: "18px",
            color: "#FBC02D",
            transform: "translateY(21px)",
            marginRight: "10px",
            marginTop: "-8px",
        }}>
            Voltar
        </Typography>
        <Typography variant="h6" sx={{
            fontWeight: 4600,
            fontSize: "18px",
            color: "#8893A9",
            marginTop: "-8px",
            transform: "translateY(21px)",
            gap: "20px",
        }}>
      Contracto
        </Typography>
    </Box>
    </Box>
     <div
     style={{
     backgroundColor: "white",
      padding: "40px", 
      }}
   >
  <Formik
    initialValues={{
      ...INITIAL_FORM_STATE,
    }}
    validationSchema={FORM_VALIDATION}
    onSubmit={(values) => {
      if (id) {
        updateItem(values);
      } else {
        createItem(values);
      }
    }}
    enableReinitialize
  >
    {({ values }) => (
      <Form>
        <Typography variant="h1">
          {isUpdate ? values.name : i18n.t("contract.titleCreate")}
        </Typography>
        <br />

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}> {/* Ajustado para ser responsivo */}
            <Textfield
              name="name"
              label={i18n.t("others.name")}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}> {/* Ajustado para ser responsivo */}
            <Textfield
              name="description"
              label={i18n.t("others.description")}
              disabled={isDisabled}
              multiline={true}
            />
          </Grid>
        </Grid>
        
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="button" style={{ display: "flex", justifyContent: "flex-end", gap: "10px", paddingTop: "20px" }}>
              <MainButton
                url="/contract"
                name={i18n.t("button.cancel")}
              />

              <AlertDialog
                button={
                  id ? i18n.t("button.update") : i18n.t("button.register")
                }
                goBack={i18n.t("contract.title")}
                head={textBox[0]}
                body={
                  textBox[1] || (
                    <FallBackLoader isLoading={status.isLoading} />
                  )
                }
                urlGoBack="/contract"
                stay={
                  id
                    ? i18n.t("others.contract")
                    : i18n.t("contract.titleCreate")
                }
                urlStay={id ? `/contract/${id}` : `/contract/add`}
                type="submit"
                isValid={true}
                isLoading={status.isLoading}
                disabled={isDisabled}
              />
            </div>
          </Grid>
        </Grid>
      </Form>
    )}
  </Formik>
</div>
      </>

    
  );
}
