import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, Grid, Box } from '@mui/material';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; 
import { useNavigate } from 'react-router-dom'; 
import axios from'axios';

function DisciplinaCard({ titulo, descricao, onClick, totalStudents,cursoId }) {
  const navigate = useNavigate(); 

  const handleDetalhesClick = (id) => {
    navigate(`/disciplinas/${cursoId}`); 
};

  return (
    <Card
      sx={{
          width: '260px', 
          height: '152px', 
          borderRadius: '6px', 
          padding: '10px', 
          backgroundColor: '#FFFFFF', 
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '14px', 
          marginTop: '-30px', 
      }}
    >
        <CardContent sx={{ padding: 0 }}>
            <Typography 
                variant="h6" 
                sx={{ fontWeight: 'bold', color: '#003B76', mb: 1, fontSize: 16 }}
            >
                {titulo}
            </Typography>
            <Typography 
                variant="body2" 
                color="#212529" 
                sx={{ fontSize: 14, lineHeight: 1.5, mb: 2 }}
            >
                {descricao || 'Descrição não disponível.'}  {/* Verifica se a descrição é null */}
            </Typography>
        </CardContent>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 1,
            }}
        >
        <Button
                onClick={handleDetalhesClick}
                sx={{
                    fontSize: 14,
                    color: '#FFC107',
                    background: 'transparent',
                    border: 'none',
                    textTransform: 'none',
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: -2,
                }}
            >
                VER DETALHES
                <ArrowForwardIcon sx={{ marginLeft: 1, fontSize: 18, color: '#FFC107' }} />
            </Button>

            <Typography
  variant="body2"
  sx={{ fontWeight: 'bold', color: '#003B76', marginTop: -2 }}
>
  {totalStudents ? `+${totalStudents}` : '+0'}
</Typography>

        </Box>
    </Card>
  );
}

DisciplinaCard.propTypes = {
  titulo: PropTypes.string.isRequired,
  descricao: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default function AvailableCourses() {
  const [licenciaturaCourses, setLicenciaturaCourses] = useState([]);
  const [mestradoCourses, setMestradoCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = `${process.env.REACT_APP_API_URL}/courses`;

  
  useEffect(() => {
    const fetchCourses = async () => {
        try {
            const response = await axios.get('/courses');
            const courses = response.data.data;

            // Filtrar os cursos por graduação
            const licenciatura = courses.filter(course => course.graduation === 'Licenciatura');
            const mestrado = courses.filter(course => course.graduation === 'Mestrado');

            setLicenciaturaCourses(licenciatura);
            setMestradoCourses(mestrado);
            setLoading(false);
        } catch (error) {
            console.error('Erro ao buscar cursos:', error);
            setLoading(false);
        }
    };

    fetchCourses();
}, [apiUrl]);
  if (loading) {
      return <Typography>Carregando cursos...</Typography>;
  }

  return (
      <Box sx={{ marginTop: '-30px' }}>
          <Typography variant="h4" sx={{ mb: 2, fontWeight: 600, fontSize: '20px' }}>
              Horário
          </Typography>

          {/* Lista de Cursos de Licenciatura */}
          <Typography variant="h5" sx={{ mb: 1, fontWeight: 600, fontSize: '20px' }}>
              Licenciatura
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '19px',
              lineHeight: '0px',
              color: '#F1C61B',
              mb: 0.5,
            }}
          >
            Disponíveis Acedidas
          </Typography>
          <Grid container sx={{ marginTop: 2 }} rowSpacing={8} columnSpacing={12.5}>
          {licenciaturaCourses.map(course => (
              <Grid item xs={12} sm={4} md={2} key={course.id}>
                  <DisciplinaCard 
                      titulo={course.name}
                      descricao={course.description}
                      onClick={() => console.log(`Detalhes de ${course.name}`)} 
                      totalStudents={course.totalStudents}
                      cursoId={course.id} // Passando o ID do curso

                  />
              </Grid>
            ))}
          </Grid>

          {/* Lista de Cursos de Mestrado */}
          <Typography variant="h5" sx={{ mt: 4, mb: 1, fontWeight: 600, fontSize: '20px' }}>
              Mestrado
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600, 
              fontFamily: 'Work Sans, sans-serif', 
              fontSize: '19px', 
              lineHeight: '2px', 
              color: '#F1C61B', 
              mb: 0.5, 
            }}
          >
            Inscreva-se às Unidades curriculares!
          </Typography>
          
          <Grid container sx={{ marginTop: 2 }} rowSpacing={8} columnSpacing={12.5}>
          {mestradoCourses.map(course => (
              <Grid item xs={12} sm={4} md={2} key={course.id}>
                  <DisciplinaCard 
                      titulo={course.name} 
                      descricao={course.description}
                      onClick={() => console.log(`Detalhes de ${course.name}`)} 
                      totalStudents={course.totalStudents}
                      cursoId={course.id} // Passando o ID do curso

                      
                  />
              </Grid>
            ))}
          </Grid>
      </Box>
  );
}

