import React, { useState } from "react";
import { Box, IconButton, Paper, Table, TableContainer, TableRow, TableCell } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PropTypes from 'prop-types';
function Collapsible({items, extractCellContent, levels = [] }) {
  /* let  update = 0;
  const forceUpdate = React.useCallback(() => {
    update((prevState) => prevState + 1);
  }, []); */

  const fill = (items, state = true) =>
    items.map((item) => ({
      open: state,
      items: item.items ? fill(item.items, true) : undefined,
    }));
  const fillArray = (items) => fill(items, false);

  const [openLevels, setOpenLevels] = useState(fillArray(items));

  const handleCollapseToggle = (levels) => {
    let count = levels.split("-");
    if (openLevels.length <= 0) setOpenLevels(fillArray(items))
    setOpenLevels((prevOpenLevels) => {
      console.log(prevOpenLevels, "loucura")
      let temp = prevOpenLevels;
      console.log(levels);
      if (count.length > 1)
        for (let index = 0; index < count.length; index++) {
          if (count.length === index + 1) temp = temp[parseInt(count[index])];
          else temp = temp[parseInt(count[index])].items;
        }
      else temp = temp[count[0]];
      temp.open = !temp?.open || false;
      console.log("AFTER", prevOpenLevels);
      return [...prevOpenLevels];
    });
    /* forceUpdate(); */
  };

  const renderNestedLevels = (items, level, levels, currentLevel) => {
    const isStart=currentLevel ==0;
    const passLevel=(index)=>String(levels ? levels + "-" + index : index);
    
    return (
      openLevels && <React.Fragment >
        {items.map((nestedItem, index) => (
          <React.Fragment key={`${nestedItem.name}-${index}`}>
            {!nestedItem.items ? (
              <TableRow style={{ height: "40px" , marginBottom:"20px"}}>
                <TableCell
                  style={{
                    display: "flex",
                    alignItems: "center",
                   
                    paddingLeft: "120px",
                    borderSpacing: "0",
                    paddingBottom: "15px",
                    paddingTop: "15px",
                    borderCollapse: "separate",
                  width: "710px",
                  }}
                >
                  <Box sx={{ flexGrow:1}}>{extractCellContent(nestedItem)}</Box>
                </TableCell>
              </TableRow>
            ) : (
              < >
                {isStart && (
              <div  height="100px">
                <br></br>
              </div>)}
                <TableRow  >
                  <TableCell
                    colSpan={10}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      paddingLeft: `${30 + currentLevel * 20}px`, 
                      backgroundColor: isStart ? "#E4EBF5" : "#F7F9FC",
                      borderCollapse: "separate",
                    }}
                  >
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleCollapseToggle(passLevel(index))}
                    >
                      {openLevels[currentLevel + 1] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                    <Box sx={{ alignContent: "start", flexGrow: 1, width: "100%" }}>{extractCellContent(nestedItem)}</Box>
                    {isStart && (
                      <ModeEditIcon
                        onClick={nestedItem.edit}
                        style={{
                          color: "#FFC107",
                          marginTop: "10px",
                          border: "1px solid #FFC107",

                        }}
                      />
                    )}
                  </TableCell>
                  <Collapse in={level[index]?.open} sx={{ width: "845px" }}>
                    {renderNestedLevels(nestedItem.items, level[index]?.items ||[], passLevel(index), currentLevel + 1)}
                  </Collapse>
                  
                </TableRow>
              
              </>
           
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  return (
    <div style={{ marginBottom: "30px", }}>
      <TableContainer sx={{ display: 'flex', flexDirection: 'column', marginTop: '30px', width: '100%',marginBottom: "30px" }} component={Paper}>
        <Table size="small" style={{ borderCollapse: "separate", width: "100%" }}>
          {items && (
            <React.Fragment>
              {renderNestedLevels(items, openLevels, null, 0)}

            </React.Fragment>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
Collapsible.propTypes = {
  items: PropTypes.array.isRequired,
  extractCellContent: PropTypes.func.isRequired,
  levels: PropTypes.array,
};
export default Collapsible;
