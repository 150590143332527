import { Grid, Typography, Button, Box, Breadcrumbs, Link, IconButton } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import HandleUrl from "../../utils/HandleUrl";
import { MainButton } from "../../components/main-button/MainButton";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import keycloak from "../../keycloak";
import GroupListingPreview from "./component/GroupListingPreview";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import { MessageDialog } from "../../components/my-modal/MessageDialog";
import { useMessageModal } from "../../utils/hooks/useModals";
import Selected from "../../components/form-components/select";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import {
  getErrorMessage,
  handleFailure,
} from "../../utils/functions/utilFunctions";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { ClassNames } from "@emotion/react";

export default function ClassesRegisterPage() {
  let isDisabled = HandleUrl();

  const [incommingData, setincommingData] = useState({});

  const {
    open: openMessage,
    message,
    loading,
    setLoading,
    handleClose,
    handleClickOpen,
    changeState,
    handleSuccess,
    handleBadRequest,
    errorFallback,
  } = useMessageModal();

  const [className, setClassName] = useState("");
  const [createdGroups, setCreatedGroups] = useState([]);
  const [courses, setCourses] = useState([]);
  const [graduations, setGraduations] = useState([]);
  const [years, setYears] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [numberOfGroups, setNumberOfGroups] = useState(0);
  const [numberOfStudent, setNumberOfStudent] = useState(0);


  const [classData, setClassData] = useState(
    {
      year: 0,
      numberOfStudent: 0,
      numberOfGroup: 0,
      name: "Developers Sintaxy",
      courseId: null,
      semester: null,
      subjectId: null,
      graduationId: "",
      schoolYearId: null,
      id: null,
      createdDate: "",
      updatedDate: "",
      deletedDate: null
    }
  );

  const apiUrl = process.env.REACT_APP_API_URL;
  const { courseId, id } = useParams();

  /* const [id, setid] = useState(id); */

  const INITIAL_FORM_STATE = {
    course: incommingData?.course || "",
    graduation: incommingData?.graduation || "",
    year: incommingData?.year || "",
    semester: incommingData?.semester || "",
    subject: incommingData?.subject?.name || "",
    numberOfStudents: incommingData?.numberOfStudents || 0,
    numberOfGroups: incommingData?.numberOfGroups || 0,
  };

  const [status, setStatus] = useState({
    isLoading: false,
    hasGroups: false,
  });
  const [textBox, setTextBox] = useState([i18n.t("messages.createTitle"), ""]);

  const navigate = useNavigate()

  const navigateTo = (endpoint) => {
    navigate(endpoint);
  }

  useEffect(() => {
    setTextBox([
      i18n.t(
        status.hasGroups ? "messages.updateTitle" : "messages.createTitle"
      ),
      "",
    ]);
  }, [status.hasGroups]);

  console.log("Initial form state: ", INITIAL_FORM_STATE);

  const FORM_VALIDATION = Yup.object().shape({
    course: Yup.object().shape({
      name: Yup.string().required("Course name is required"),
      graduation: Yup.string().required("Graduation is required"),
    }),
    year: Yup.number().required("Year is required"),
    semester: Yup.string().required("Semester is required"),
    subject: Yup.string().required("Subject is required"),
    numberOfStudents: Yup.number().required("Number of students is required"),
    numberOfGroups: Yup.number().required("Number of groups is required"),
  });

  console.log("Initial values: ", INITIAL_FORM_STATE);
  console.log("className: ", className);

  const getGroups = async () => {
    try {
      const response = await axios.get(`${apiUrl}/group/${id}/class`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const getCourses = async () => {
    try {
      const response = await axios.get(`${apiUrl}/courses`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const getGraduations = async () => {
    try {
      const response = await axios.get(`${apiUrl}/graduations`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const getYears = async () => {
    try {
      const response = await axios.get(`${apiUrl}/years`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const getSemesters = async () => {
    try {
      const response = await axios.get(`${apiUrl}/semester`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  const getSubjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/subject`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getGroups()
        .then((response) => {
          console.log("Group Data response: ", response);
          const groups =
            response?.data && response?.data?.data?.length
              ? response?.data?.data.sort((a, b) => a.id - b.id)
              : [];
          setCreatedGroups((prev) => ({
            ...prev,
            groups,
          }));

          setStatus((prev) => ({
            ...prev,
            hasGroups: groups?.length > 0,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const fetchData = async () => {
        try {
          const [coursesResponse, schoolYearsResponse, semesterResponse, graduationResponse, subjectsResponse] = await Promise.all([
            getCourses(),
            getYears(),
            getSemesters(),
            getGraduations(),
            getSubjects(),
          ]);
          console.log("Semester Data: ", semesterResponse?.data);

          setCourses(coursesResponse?.data?.data);
          setYears(schoolYearsResponse?.data);
          setSemesters(semesterResponse?.data);
          setGraduations(graduationResponse?.data?.data);
          setSubjects(subjectsResponse?.data?.data);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };

      fetchData();
    }
  }, [id]);

  const getClasse = async () => {
    try {
      const response = await axios.get(`${apiUrl}/classes/${id}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      console.log("DADOS DA TURMA: ", response)
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getClasse()
        .then((response) => {
          console.log("Class Data response: ", response?.data);

          setincommingData(response?.data);
          setNumberOfGroups(response?.data?.numberOfGroups)
          setNumberOfStudent(response?.data?.numberOfStudents)
          setClassName(response?.data?._name);
          setCreatedGroups((prev) => ({
            ...prev,
            numberOfStudents: response?.data?.numberOfStudents,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  const handleClassNameChange = (ev) => {
    setClassName(ev.target.value);
  };
  const handleGroupNameChange = (groupId, newName) => {
    setCreatedGroups((prevGroups) => {
      // Find the group with the matching id
      const groupIndex = prevGroups.groups.findIndex(
        (group) => group.id === groupId
      );

      // If the group exists, update its name
      if (groupIndex !== -1) {
        const newGroupNames = { ...prevGroups };
        newGroupNames.groups[groupIndex].name = newName;
        return newGroupNames;
      }

      // If the group doesn't exist, return the previous state
      return prevGroups;
    });
  };

  function createGroups(values) {
    setLoading(true);
    handleClickOpen();

    try {
      const formData = {
        classeId: parseInt(id),
        numOfGroups: parseInt(values.numberOfGroups),
      };

      axios
        .post("group", formData, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        })
        .then(function (response) {
          if (response && response?.status <= 201) {
            const groups =
              response?.data && response?.data?.length ? response?.data : [];
            setCreatedGroups({
              groups,
              numberOfStudents: values.numberOfStudents,
            });
            setStatus((prev) => ({ ...prev, hasGroups: true }));
            handleSuccess();
          } else {
            let newResponse = {}
            if (!id) {
              newResponse.status = 400;
            }
            handleBadRequest(response ?? newResponse);
          }
        })
        .catch(function (error) {
          /*  console.log("É ESTA A RESPOSTA", error); */
          errorFallback(error);
        });
    } catch (error) {

      errorFallback(error);
    }
  }


  function updateGroups(values) {
    const errorFallback = (error) => {
      console.error(error);
      setTextBox([i18n.t("messages.wrong"), getErrorMessage(error)]);
      setStatus((prev) => ({ ...prev, isLoading: false }));
    };

    const handleFail = (response) => {
      console.error("Fail: ", response);
      setTextBox([i18n.t("messages.wrong"), handleFailure(response)]);
      setStatus((prev) => ({ ...prev, isLoading: false }));
    };

    setStatus((prev) => ({ ...prev, isLoading: true }));

    try {
      const groupsDto = createdGroups.groups.map((group) => ({
        groupId: group.id,
        name: group.name,
        studentsId: group.students.map((student) => parseInt(student.id)),
      }));

      const formData = {
        classeId: parseInt(id),
        numberOfGroups: parseInt(values.numberOfGroups),
        groupsDto,
      };

      console.log("values:", values);
      // Make a PUT request to update the group
      axios
        .put(`/group`, formData, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        })
        .then(function (response) {
          if (response && response?.status <= 201) {
            console.log(response, formData, "Atualizar grupos");
            setTextBox((prev) => {
              const textCopy = [...prev];
              textCopy[1] = i18n.t(
                status.hasGroups
                  ? "messages.updateMessage"
                  : "messages.createMessage"
              );
              return textCopy;
            });
            if (className !== incommingData._name) {
              updateClass(errorFallback, handleFail);
            }
            setStatus((prev) => ({ ...prev, isLoading: false }));
          } else {
            handleFail(response);
          }
        })
        .catch(function (error) {
          errorFallback(error);
        });
    } catch (error) {
      errorFallback(error);
    }
  }

  function updateClass(handleError, handleFail) {
    try {
      const formData = {
        name: className,
      };

      axios
        .patch(`/classes/name/${id}`, formData, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        })
        .then(function (response) {
          if (response && response?.status <= 201) {
            setTextBox((prev) => {
              const textCopy = [...prev];
              textCopy[1] = i18n.t("messages.updateMessage");
              return textCopy;
            });
            setStatus((prev) => ({ ...prev, isLoading: false }));
            console.log("Atualizar Classe: ", response);
            return;
          } else {
            handleFail(response);
          }
        })
        .catch(function (error) {
          handleError(error);
        });
    } catch (error) {
      handleError(error);
    }
  }

  const onChangeDataClass = (name, value) => {
    setClassData({ ...classData, [name]: value })
  };

  const createClass = (values) => {
    setLoading(true);
    handleClickOpen();
    const formData = {
      year: parseInt(values.year),
      numberOfStudents: parseInt(numberOfStudent),
      numberOfGroups: parseInt(numberOfGroups),
      name: "II1A",
      course: courses.find(c => c.id === values.course.name)?.name,
      semester: parseInt(values.semester),
      subject: subjects.find(c => c.id === values.subject)?.name,
      graduation: graduations.find(c => c.id === values.course.graduation)?.name,
      schoolYear: 20,
    };

    try {
      axios
        .post("classes/class", formData, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        })
        .then(function (response) {
          if (response && response?.status <= 201) {
            //setid(response.data?.id);
            handleSuccess();
          } else {
            handleBadRequest(response);
          }
        })
        .catch(function (error) {
          errorFallback(error);
        });
    } catch (error) {
      errorFallback(error);
    }
  };

  console.log("ID: ", id)

  return (
     <>
     <Box sx={{ 
      backgroundColor: "#FFFFFF", 
      marginRight:"10px",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", 
      padding: "20px", 
      marginTop: "-30px",  
      width: "1258px !important",
      top: "100px",  // Ajuste para controlar a posição vertical
 
      marginLeft: "-1px", 
      height:"10px",  // Ajuste para garantir que a altura seja dinâmica
      position: "absolute", // Posicionamento absoluto para ficar por cima
      zIndex: 1000, // Garante que a Box fique acima de outros elementos
    }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px", }}>
        <IconButton sx={{ 
          color: "#FBC02D", 
          marginRight: "0px", 
          display: "flex", 
          alignItems: "center", 
        }}
        onClick={() => navigate('/classes')}

        >
          <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-5px " , marginTop: "-8px", }} />
          <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px " , marginTop: "-8px", }} />
        </IconButton>
        <Typography variant="h6" sx={{ 
          fontWeight: 400, 
          fontSize: "18px", 
          color: "#FBC02D", 
          marginRight: "10px", 
          marginTop: "-8px",
        }}>
          Voltar
        </Typography>
        <Typography variant="h6" sx={{ 
          fontWeight:4600, 
          fontSize: "18px", 
          color: "#8893A9" ,
          marginTop: "-8px",
          gap:"20px",
        }}>
          Turma 
        </Typography>
      </Box>
    </Box>

      {/* <Box
        sx={{
          backgroundColor: "#FFFFFF",
        }}
        p={2}
        mb={4}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
          <Button sx={{ borderColor: "transparent" }} startIcon={<MdOutlineKeyboardDoubleArrowLeft size={32} />}>
            {i18n.t("others.back")}
          </Button>
          <Breadcrumbs aria-label="breadcrumb" sx={{ borderBottom: "none", color: "#8893A9" }}>
            <Link underline="hover" color="inherit" href="/classes">
              {i18n.t("course.class")}
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={`/classes/${id}/${courseId}/preview`}
            >
              {className}
            </Link>
            <Link
              underline="hover"
              color="secondary"
              href="#"
              aria-current="gerar grupo"
            >
              {i18n.t("others.generateGroups")}
            </Link>
          </Breadcrumbs>
        </Box>
      </Box> */}
      <Grid
        container
        style={{
          backgroundColor: "white",
        }}
      >
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values, { validateForm }) => {
            validateForm(values).then((errors) => {
              if (Object.keys(errors)?.length === 0) {
                // No validation errors
                setStatus((prev) => ({ ...prev, isLoading: true }));
                if (status.hasGroups) {
                  updateGroups(values);
                } else {
                  createClass(values);
                  /* createGroups(values); */
                  console.log("Creating values:", values);
                }
              } else {
                // Log validation errors
                console.log("Form validation errors:", errors);
              }
            });
          }}
          enableReinitialize
        >
          <Form style={{ padding: "50px 100px" }}>
            <Typography variant="h1" sx={{ marginBottom: "30px" }}>
              {i18n.t("others.createGroups")}
            </Typography>
            <Grid sx={{ display: "flex", flexDirection: "column" }}>
              <Grid sx={{ display: "flex" }}>
                <Grid item sx={{ width: "350px" }}>
                  {
                    typeof id === "string" ?
                      <Textfield
                        name="course.name"
                        label={i18n.t("others.course")}
                        disabled
                        onChange={(e) => onChangeDataClass("courseId", e.target.value)}
                        defaultValue={incommingData?.course?.name}
                      />
                      :
                      <Selected
                        name="course.name"
                        label={i18n.t("others.course")}
                        options={courses}
                        onChange={(e) => onChangeDataClass("courseId", e.target.value)}
                        defaultValue={incommingData?.course?.name}
                      />
                  }


                </Grid>

                <Grid item sx={{ width: "350px", marginLeft: "40px" }}>
                  {
                    typeof id === "string" ?
                      <Textfield
                        name="course.graduation"
                        label={i18n.t("others.graduation")}
                        options={graduations}
                        disabled
                        onChange={(e) => onChangeDataClass("graduationId", e.target.value)}
                        defaultValue={incommingData?.course?.graduation}
                      />
                      :
                      <Selected
                        name="course.graduation"
                        label={i18n.t("others.graduation")}
                        options={graduations}
                        disabled={isDisabled}
                        onChange={(e) => onChangeDataClass("graduationId", e.target.value)}
                        defaultValue={incommingData?.course?.graduation}
                      />
                  }
                </Grid>
              </Grid>

              <Grid sx={{ display: "flex", marginTop: "30px" }}>
                <Grid item sx={{ width: "160px" }}>

                  {
                    id ?
                      <Textfield
                        name="year"
                        label={i18n.t("others.year")}
                        options={years}
                        disabled
                        onChange={(e) => onChangeDataClass("schoolYearId", e.target.value)}
                        defaultValue={incommingData?.course?.year}
                      />
                      :
                      <Selected
                        name="year"
                        label={i18n.t("others.year")}
                        options={years}
                        disabled={isDisabled}
                        onChange={(e) => onChangeDataClass("schoolYearId", e.target.value)}
                        defaultValue={incommingData?.course?.year}
                      />
                  }
                </Grid>

                <Grid item sx={{ width: "160px", marginLeft: "30px" }}>

                  {
                    id ?
                      <Textfield
                        name="semester"
                        label={i18n.t("others.semester")}
                        options={semesters}
                        disabled
                        onChange={(e) => onChangeDataClass("semester", e.target.value)}
                        defaultValue={incommingData?.course?.semester}
                      />
                      : <Selected
                        name="semester"
                        label={i18n.t("others.semester")}
                        options={semesters}
                        disabled={isDisabled}
                        onChange={(e) => onChangeDataClass("semester", e.target.value)}
                        defaultValue={incommingData?.course?.semester}
                      />}
                </Grid>

                <Grid item sx={{ width: "350px", marginLeft: "40px" }}>

                  {id ?
                    <Textfield
                      name="subject"
                      label={i18n.t("others.subject")}
                      defaultValue={incommingData?._name}
                      options={subjects}
                      disabled
                      onChange={(e) => onChangeDataClass("subjectId", e.target.value)}
                    />
                    : <Selected
                      name="subject"
                      label={i18n.t("others.subject")}
                      defaultValue={incommingData?._name}
                      options={subjects}
                      disabled={isDisabled}
                      onChange={(e) => onChangeDataClass("subjectId", e.target.value)}
                    />}
                </Grid>
              </Grid>

              <Grid sx={{ display: "flex", marginTop: "30px" }}>
                <Grid item sx={{ width: "160px" }}>
                  <Textfield
                    type="number"
                    name="numberOfStudents"
                    disabled
                    onChange={e => setNumberOfStudent(e.target.value)}
                    label={i18n.t("others.nºStudents")}
                    value={numberOfStudent}
                  />
                </Grid>

                <Grid item sx={{ width: "160px", marginLeft: "30px" }}>
                  <Textfield
                    type="number"
                    name="numberOfGroups"
                    /* disabled={isDisabled} */
                    onChange={e => setNumberOfGroups(e.target.value)}
                    label={i18n.t("others.nºGroups")}
                    value={numberOfGroups}
                  />
                </Grid>

                <Grid
                  item
                  sx={{
                    marginLeft: "40px",
                    marginTop: "15px",
                  }}
                >
                  <Button
                    variant="text"
                    type="button"
                    disabled={!id}
                    onClick={() => createGroups({ numberOfStudents: numberOfStudent, numberOfGroups: numberOfGroups })}
                    sx={{
                      width: "150px",
                      height: "35.5px",
                    }}
                  >
                    {i18n.t("others.generateGroups")}
                  </Button>
                </Grid>


              </Grid>
              {/* {
                !status.hasGroups &&
                <Button
                  type="submit"
                  variant="outlined"
                  style={{
                    width: "95px",
                    color: "white",
                    height: "35.5px",
                    marginTop: "10px",
                    backgroundColor: "#FFC107",
                    border: "none",
                  }}
                >
                  {i18n.t(!id ? "button.save" : "button.update")}
                </Button>
              } */}
            </Grid>

            {status.hasGroups && (
              <>
                <Grid>
                  <GroupListingPreview
                    turma={createdGroups}
                    handleClassNameChange={handleClassNameChange}
                    className={className}
                    handleGroupNameChange={handleGroupNameChange}
                  />
                </Grid>
                <Grid item xs={4.2}>
                  <div
                    style={{
                      display: "flex",
                      paddingLeft: "480px",
                      paddingBottom: "80px",
                      paddingTop: "50px",
                      justifyContent: "flex-end",
                      width: "100%",
                      gap: "10px",
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>
                      <MainButton
                        url="/classes"
                        name={i18n.t("others.back")}
                      />
                    </span>

                    <span style={{ marginRight: "30px" }}>
                      <AlertDialog
                        button={id ? i18n.t("button.update") : i18n.t("button.register")}
                        goBack={i18n.t("class.title")}
                        head={textBox[0]}
                        body={
                          textBox[1] || (
                            <FallBackLoader isLoading={status.isLoading} />
                          )
                        }
                        urlGoBack="/classes"
                        stay={
                          status.hasGroups
                            ? i18n.t("others.class")
                            : i18n.t("others.title")
                        }
                        urlStay={
                          status.hasGroups
                            ? `/classes/${id}`
                            : `/classes`
                        }
                        type="submit"
                        isValid={true}
                        isLoading={status.isLoading}
                        disabled={isDisabled}
                      />
                    </span>
                  </div>
                </Grid>
              </>
            )}
          </Form>
        </Formik>
      </Grid>

      {changeState && (
        <MessageDialog
          id={id}
          open={openMessage}
          handleClose={handleClose}
          message={message}
          isLoading={loading}
        />
      )}
    </>
  );
}
