import { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as Yup from 'yup';
import AcademicInfo from "./Form-components/AcademicInfo";
import AdditionalInfo from "./Form-components/AdditionalInfo";
import PersonalInfo from "./Form-components/PersonalInfo";
import SecondarySchoolInfo from "./Form-components/SecondarySchoolInfo";
import { Formik, Form } from "formik";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loading from "../../components/loading/loading";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const FormPages = [
  AcademicInfo,
  PersonalInfo,
  SecondarySchoolInfo,
  AdditionalInfo,
];

const NextButton = styled(Button)({
  backgroundColor: "#FFC107",
  color: "white",
  padding: "5px 35px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#FFCC39",
  },
});

const PrevButton = styled(Button)({
  backgroundColor: "white",
  color: "#FFC107",
  padding: "5px 35px",
  borderColor: "#FFC107",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "white",
    borderColor: "#FFCC39",
  },
});

const FormContainer = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { keycloak } = useKeycloak();
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [incomingDate, setincomingDate] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [acceptUseTerms, setAcceptUseTerms] = useState(false);
  const [files, setFiles] = useState({})
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: "", severity: "success" })
  const handleClick = (m, s) => {
    setToast({ message: m, severity: s })
    setTimeout(() =>
      setOpen(true), 200);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const formschema = Yup.object().shape({
    year: Yup.string().required('Ano é obrigatório'),
    phone: Yup.string()
      .min(9, "O telefone deve ter no mínimo 9 dígitos")
      .max(9, "O telefone deve ter no máximo 9 dígitos")
      .required('O telefone é obrigatório'),
    email: Yup.string().email('Email inválido').required('O email é obrigatório'),
    
    documentType: Yup.string().required('Tipo de documento é obrigatório'),
    documentNumber: Yup.string().required('Número do documento é obrigatório'),
    name: Yup.string().required('Nome é obrigatório'),
    birthdate: Yup.date().required('Data de nascimento é obrigatória'),
    motherName: Yup.string().required('Nome da mãe é obrigatório'),
    fatherName: Yup.string().required('Nome do pai é obrigatório'),
    gender: Yup.string().required('Gênero é obrigatório'),
    maritalStatus: Yup.string().required('Estado civil é obrigatório'),
    nationality: Yup.string().required('Nacionalidade é obrigatória'),
    province: Yup.string().required('Província é obrigatória'),
    county: Yup.string().required('Município é obrigatório'),
    
    householdSize: Yup.number()
      .lessThan(50, "O número de pessoas na residência não pode ser maior que 50")
      .required('Número de pessoas na residência é obrigatório'),

    schoolOrigen: Yup.string().required('Origem da escola é obrigatória'),
    natureoftheschoolofprovenance: Yup.string().required('Natureza da escola de origem é obrigatória'),
    nameOfTheHighSchoolCourse: Yup.string().required('Nome do curso do ensino médio é obrigatório'),

    highSchoolFinalGrade: Yup.number()
      .typeError("Apenas números são permitidos")
      .min(4, "Nota mínima permitida é 4")
      .max(4, 'A nota deve ter até duas casas decimais')
      .moreThan(0, "A nota deve estar entre 0 e 20")
      .lessThan(20.01, "A nota deve estar entre 0 e 20")
      .required('Nota final do ensino médio é obrigatória'),

    studyFinancing: Yup.string().required('Forma de financiamento dos estudos é obrigatória'),
    studentRegime: Yup.string().required('Regime do estudante é obrigatório'),
    
    yearsOfEntryTheCourse: Yup.number()
      .lessThan(2024, "O ano de entrada deve ser menor que 2024")
      .moreThan(1990, "O ano de entrada deve ser maior que 1990")
      .required('Ano de entrada no curso é obrigatório'),

    studyInclusively: Yup.boolean().required('É obrigatório informar se estuda de forma inclusiva'),
    entryType: Yup.string().required('Tipo de ingresso é obrigatório'),

    schoolIssuingProfessionalDegree: Yup.string().optional(),
    stateAgency: Yup.string().optional(),
    profession: Yup.boolean().optional(),

    functionPerformed: Yup.string().required('Função desempenhada é obrigatória'),
    workingInStateAgency: Yup.boolean().required('É obrigatório informar se trabalha em órgão estatal'),

    professionalDegreeConferredBySchool: Yup.string().required('Grau profissional concedido pela escola é obrigatório'),

    deaf: Yup.boolean().required('É obrigatório informar se possui deficiência auditiva'),
    motorDeficiency: Yup.boolean().required('É obrigatório informar se possui deficiência motora'),
    mediumTechnician: Yup.boolean().required('É obrigatório informar se possui formação técnica de nível médio'),
    seniorTechnician: Yup.boolean().required('É obrigatório informar se possui formação técnica de nível superior'),
    police: Yup.boolean().required('É obrigatório informar se pertence à polícia'),
    military: Yup.boolean().required('É obrigatório informar se pertence às forças armadas'),
    blind: Yup.boolean().required('É obrigatório informar se possui deficiência visual'),
    otherDeficiency: Yup.boolean().required('É obrigatório informar se possui outra deficiência'),

    teacher: Yup.boolean().required('É obrigatório informar se é professor'),
    administrationEmployee: Yup.boolean().required('É obrigatório informar se trabalha na administração pública'),
});



  //useEffect(()=>{forceUpdate();},[files,formschema.errors])
  const [uploadedImage, setUploadedImage] = useState(null);
  const [formData, setFormData] = useState({
    year: "1º Ano",
    graduation: "",
    "course": "",
    "documentType": "",
    "documentNumber": "",
    "name": "",
    "birthdate": "",
    "motherName": "",
    "fatherName": "",
    "gender": "",
    "maritalStatus": "",
    "nationality": "",
    "province": "",
    "county": "",
    "householdSize": "",

    "schoolOrigen": "",
    "natureoftheschoolofprovenance": "",
    "nameOfTheHighSchoolCourse": "",
    "highSchoolFinalGrade": "",
    "studyFinancing": "",
    "studentRegime": "",
    "yearsOfEntryTheCourse": " ",

    "phone": "",
    "email": "",


    entryType: "",
    schoolIssuingProfessionalDegree: "",
    stateAgency: "",
    specialNeeds: false,
    profession: "",
    functionPerformed: "SSS",
    studyInclusively: true,
    workingInStateAgency: false,
    professionalDegreeConferredBySchool: false,
    deaf: false,
    motorDeficiency: false,
    mediumTechnician: false,
    seniorTechnician: false,
    police: false,
    military: false,
    blind: false,
    otherDeficiency: false,

    teacher: false,
    administrationEmployee: false,
  });
  const uploadFiles = async (files, id, type, data) => {
    try {
      const formData = new FormData();
      formData.append('entityID', id);
      formData.append('type', type)
      formData.append('ProfilePic', files["ProfilePic"]); // O nome 'file' deve corresponder ao esperado pela API
      formData.append('DocIdentification', files["DocIdentification"])
      formData.append('HighSchoolCertificate', files["HighSchoolCertificate"])
      formData.append('MedicalCertificate', files["MedicalCertificate"])
      formData.append('DescentFromFormerCombatant', files["DescentFromFormerCombatant"])
      formData.append('ProOfOfDeficiencyCarrier', files["ProOfOfDeficiencyCarrier"])
      formData.append('AdditionalAttachment', files["AdditionalAttachment"])

      const response = await axios.post(`${apiUrl}/enrollAttachment`, formData, {
        headers: {
          Authorization: "Bearer " + keycloak.token, // token de autenticação desgraçado
        },

        'Content-Type': 'multipart/form-data', // Importante para enviar arquivos

      });

      // A resposta da API pode conter informações úteis, dependendo do seu caso
      console.log('Resposta da Requisição de Upload:', response);

      handleClick("Ficheiros Upploaded Com Successo", "success")

      // Você pode atualizar o estado com o ID da imagem ou outras informações relevantes da resposta
      //setImageId(response.data.imageId);



    } catch (error) {
      console.error('Erro ao fazer upload do arquivo:', error);

      handleClick("Erro ao fazer upload do arquivo:", "error")
    } finally {
      const urlParams = new URLSearchParams(data.student);

      // Convert the URLSearchParams object to a string
      const paramString = urlParams.toString();
      window.location.href = "/enrolls/confirmation?" + paramString

    }
  };
  const postEnrolls = async (values) => {
    try {
      const { subjectIds, courseId, ...student } = values;
      const response = await axios.post(
        `${apiUrl}/enroll`,
        {
          //   student: {
          //     year: values.year,
          //     graduation: values.graduation,
          //     course: {
          //       id: values.course,
          //       //"name":values.course,
          //     },

          //     documentType: values.docType,
          //     documentNumber: values.docNumber,
          //     name: values.fullName,
          //     birthdate: values.birthDate, //"2023-09-18T09:55:37.518Z",
          //     motherName: values.motherName,
          //     fatherName: values.fatherName,
          //     gender: values.gender,
          //     maritalStatus: values.maritalStatus,
          //     nationality: values.nationality,
          //     province: values.province,
          //     county: values.municipality,
          //     householdSize: values.householdSize,
          //     specialNeeds: values.specialNeeds,
          //     schoolOrigen: values.schoolOrigin,
          //     natureoftheschoolofprovenance: values.natureOfOriginSchool,
          //     nameOfTheHightSchoolCourse: values.highSchoolCourse,
          //     hightSchoolFinalGrade: values.highSchoolFinalGrade,
          //     studyFinancing: values.studyFunding,
          //     studentRegime: values.studentRegime,
          //     yearsOfEntryTheCourse: values.yearEntryUniversity,
          //     termOfCommitment: values.termOfCommitment,
          //     studyInclusively: values.StudyInclusively,
          //     phone: values.phoneNumber,
          //     email: values.email,
          //     profession: values.profession,
          //     professionalDegreeConferredBySchool:
          //       values.schoolIssuingProfessionalDegree,
          //     functionPerformed: values.functionPerformed,
          //     workingInStateAgency: values.stateAgency,
          //     acessExam: values.examNumber,
          //     workForState: values.workForState,
          //   },
          student,
          subjectIds
        },

        {
          headers: {
            Authorization: "Bearer " + keycloak.token, // token de autenticação desgraçado
          },
        }
      ).then((data) => {
        console.log("MY RESPOSNSE", data)
        handleClick("Erroll Com Successo", "success")
        uploadFiles(files, data.data?.id, values?.documentType, data.data)
      })
      if (response && response.data && response.data.data) {
        console.log("Resposta da Requisição:", response);
        setincomingDate([...response.data.data]);
        setPage(response?.data?.page);
        setTotalPages(response?.data?.meta?.totalPages);
      }
    } catch (error) {
      console.error(error);
      let message = error?.response?.data?.message?.message
      handleClick(message, "error")
    }
  };

  const nextPage = (err = {}) => {

    if (page === FormPages.length - 1) {
      //go to /enroll/confirmation
      // window.location.href = "/enrolls/confirmation";
    } else {
      setPage(page + 1);

      // (err.gender || err.nationality ) && setPage(1)
    }
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const handleValidationBeforeSubmit = (validateForm, values, postEnrolls) => {
    setSubmitting(true);
    validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        const errorDetails = Object.values(errors)
          .map((field) => `• ${field}`)
          .join("\n");

        handleClick(
          `Por favor, preencha todos os campos corretamente.\n\nDetalhes:\n${errorDetails}`,
          "error"
        );

        setSubmitting(false);
      } else {
        if ((!files["ProfilePic"] || !files["DocIdentification"] || !files["MedicalCertificate"] || !files["HighSchoolCertificate"]) ||
          (values.entryType == "82ed8936-667a-450f-8528-537ff53da0bf" && console.log(files) && !files["ProOfOfDeficiencyCarrier"]) ||

          (values.entryType == "fd8cc416-13f3-441f-a23f-df959d17e212" && console.log(files) && !files["DescentFromFormerCombatant"])



        ) {
          handleClick("Verifique se adicionaste todos os arquivos Requeridos", "warning")
          setSubmitting(false)
        }
        else
          postEnrolls(values).finally(() => setSubmitting(false));
        console.log(values);
      }
    });
  };

  return (
    <div>
      <Snackbar open={open} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toast?.severity} sx={{ width: '100%' }}>
          {toast?.message}
        </Alert>
      </Snackbar>
      <Formik

        validationSchema={formschema}
        initialValues={{ ...formData }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          validateForm,
          /* and other goodies */
        }) => (
          <Form>
            {page == 0 && (

              <AcademicInfo setFiles={setFiles} setFormData={setFormData} errors={errors} touched={touched} values={values} />

            )}
            {page == 1 && (

              <PersonalInfo
                uploadedImage={uploadedImage} setUploadedImage={setUploadedImage}
                setFormData={setFormData} setFiles={setFiles} files={files} values={values} errors={errors} touched={touched} setSelectedSubjects={setSelectedSubjects} selectedSubjects={selectedSubjects} />
            )}
            {page == 2 && (

              <SecondarySchoolInfo setFiles={setFiles} files={files} errors={errors} touched={touched} />

            )}
            {page == 3 && (

              <AdditionalInfo
                errors={errors} touched={touched}
                files={files}
                acceptUserTermsProps={{ acceptUseTerms, setAcceptUseTerms }}
                values={values}
                setFormData={setFormData}
                setFiles={setFiles}
              />

            )}
            <div className="button-group" style={{ paddingTop: "100px" }}>
              <PrevButton
                variant="outlined"
                onClick={prevPage}
                disabled={page === 0}
              >
                Anterior
              </PrevButton>
              {page != 3 && <NextButton
                type="button"
                variant="contained"
                onClick={() => nextPage(errors)}
              >
                Próximo
              </NextButton>}
              {page == 3 && <NextButton
                type="button"
                disabled={!acceptUseTerms || isSubmitting}
                variant="contained"
                onClick={() => handleValidationBeforeSubmit(validateForm, values, postEnrolls)}
              >
                Submeter
              </NextButton>}
            </div>
            {isSubmitting && <Loading />}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormContainer;
