import { Grid, Typography } from '@mui/material';
import './styles/academic-info.css';
import { useEffect } from 'react';
import CollapsibleTeste from '../../../components/collapsiTable/CollapsibleTeste';
import PropTypes from 'prop-types';


const listaData = [
    { id: 1, nome: "Joaquim Barata", ano: "1º ano" },
    { id: 2, nome: "Maria Silva", ano: "2º ano" },
    // Adicione mais dados conforme necessário
  ];
  
  const secondTitle = ["1º Semestre", "2º Semestre",];

  const columnsTitle = [
    { label: "Ano", field: "ano" },
    { label: "Professor", field: "nome" },
  ];
  

const Subjects= ({setFormData,values,subject}) => {

  let graduation =[];
  let  course =[];

  
  useEffect(()=>{
    if(values)
    values.course = course?.find((c)=>c.name == "Medicina")?.id
    values.graduation = graduation?.find((g)=>g.name == "Licenciatura")?.id
  if(setFormData)
    setFormData({...values})
  },[course,graduation])
  return (
  <div className='academic-info'>
   
    <h2>Inscreva-se</h2>
    <Typography sx={{ textAlign: 'justify', color:"#949494"}} variant='p'>Seja bem-vindo ao processo de matrícula no 1º ano do curso de Medicina da Faculdade de Medicina da Universidade de Agostinho Neto, Angola. Para dar início ao processo de matrícula, forneça as informações em cada campo de forma sequenciada.</Typography>
    <Typography sx={{ paddingTop: "30px", paddingBottom: "20px", color: "#003B76" }} variant='h3'>Unidade curriculares Inscritas</Typography>
   
    <Grid container gap={6}>
      <Grid item xs={10}>
      < CollapsibleTeste
                            title="1º Ano"
                            secondTitles={secondTitle}
                            columnsTitle={columnsTitle}
                            tableData={listaData}
                          />
      </Grid>
      
      <Grid item xs={10}>
      < CollapsibleTeste
                            title="2º Ano"
                            secondTitles={secondTitle}
                            columnsTitle={columnsTitle}
                            tableData={listaData}
                          />
      </Grid>
      
    </Grid>

  </div>
);
};
Subjects.propTypes = {
  setFormData: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default Subjects;
