import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { Grid, Typography, IconButton } from "@mui/material";
import { i18n } from "../../../translate/i18n";
import { MainButton } from "../../../components/main-button/MainButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
export default function TeacherSelectorModal({ currentTeacher, open, onClose, onConfirm, createdYearId, coordinate = () => { } }) {
  const [selectedOption, setSelectedOption] = useState("");
  const [employee, setEmployees] = useState([]);
  const [courses, setCourses] = useState([]);
  const [courseSelections, setCourseSelections] = useState({});
  const[loading,setLoading ] = useState(true);
  const [isCoordinator, setIsCoordinator] = useState(false);
  const [secondSelectedOption, setSecondSelectedOption] = useState("");
   const [subjectList,setSubjectList]  =useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [subjectCourses, setSubjectCourses] = useState({});
  const [selectedPeriods, setSelectedPeriods] = useState([]);
  const { keycloak } = useKeycloak();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [strandOptions, setStrandOptions] = useState([]);
  const [periodLabels, setPeriodLabels] = useState([]);
  const [isSecondSelectVisible, setIsSecondSelectVisible] = useState(false);

  const handleSubjectCheckboxChange = (subjectId) => {
    setSelectedSubjects((prevSelected) => {
      if (prevSelected.includes(subjectId)) {
        return prevSelected.filter((id) => id !== subjectId);
      } else {
        return [...prevSelected, subjectId];
      }
    });
  };

  const handleSelectChange = (subjectId, value) => {
    setCourseSelections((prevSelections) => ({
      ...prevSelections,
      [subjectId]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setIsCoordinator(!isCoordinator);
    setIsSecondSelectVisible(!isCoordinator);
    setSecondSelectedOption("");
  };
  const schoolYearId = isNaN(createdYearId) ? null : createdYearId;
  const coordinatedSubjectId = isCoordinator ? (isNaN(secondSelectedOption) ? null : secondSelectedOption) : null;

  const handleSecondSelectChange = (subjectId, value) => {
    setSubjectCourses((prevSelections) => ({
      ...prevSelections,
      [subjectId]: value,
    }));
  };
  const getList = async (type, set, filter) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
        params: filter,
      });
      console.log('Resposta da Requisição:', response);
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
     
    } 
  };
  const handlePeriodCheckboxChange = (periodId) => {
    setSelectedPeriods((prevSelected) => {
      if (prevSelected?.includes(periodId)) {
        return prevSelected.filter((id) => id !== periodId);
      } else {
        return [...prevSelected, periodId];
      }
    });
  };
  

  const handleAddTeacher = async () => {
    try {
      setLoading(true);
      const availabilityIds = selectedPeriods.map((periodId) => periodId);

      console.log('availabilityIds:', availabilityIds);


      const subjectAssignments = selectedSubjects.map((subjectId) => ({
        variantId: subjectCourses[subjectId],
        courseSemesterSubjectId: courseSelections[subjectId],
      }));

      const payload = {
        schoolYearId: schoolYearId,
        teacherId: String(selectedOption),
        coordinatedSubjectId: coordinatedSubjectId,
        availabilityIds: availabilityIds,
        subjectAssignment: subjectAssignments,
      };
      
      if (currentTeacher?.id) delete payload.schoolYearId;
      const response = await (!currentTeacher? axios
        .post : axios.put)(
          `${apiUrl}/teacher-semester-subject-plan/${currentTeacher && currentTeacher.teacherAssignmentId ? currentTeacher.teacherAssignmentId : "add-to-school-year"}`,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + keycloak.token,
            },
          }
        );
      if (coordinate)
        coordinate()
      console.log('Add Teacher Response:', response);

      if (onConfirm) {
        onConfirm();
      }
      onClose();
    } catch (error) {
      console.error('Error adding teacher:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Executando useEffect com currentTeacher:", currentTeacher);
  
    getList("employees/teachers", setEmployees);
    getList("subject", setSubjectList);
    getList("period", setPeriodLabels);
    getList("strand", setStrandOptions);
    getList("curricular-plan", setCourses);  
  
    console.log("Chamadas de `getList` executadas.");
  
    setSelectedOption({});
    setSecondSelectedOption("");
    setSelectedSubjects([]);
    setSelectedPeriods([]);
    setSubjectCourses({});
    
    if (currentTeacher) {
      const periods = currentTeacher?.periods?.map((period) => period?.id);
      console.log("Períodos do professor atual:", periods);
      setSelectedPeriods(periods);
      setSelectedOption(currentTeacher.id);
  
      currentTeacher.courses.forEach((course) => {
        console.log("Cursos do professor atual:", course);
  
        course.subjects.forEach((element) => {
          console.log("Elemento do curso atual:", element);
  
          handleSubjectCheckboxChange(element.subject.id);
          console.log("Marcado checkbox para subject.id:", element.subject.id);
  
          handleSelectChange(element.subject.id, element.subject.courseSemesterSubjectId);
          console.log("CLEMENTINAAlterado select para subject.id:", element.subject.id, "Com valor:", element.subject.courseSemesterSubjectId);
  
          handleSecondSelectChange(element.subject.id, element.subject.variant.id);
          console.log("Alterado segundo select para subject.id:", element.subject.id, "Com variant.id:", element.subject.variant.id);
        });
      });
    }
  }, [currentTeacher]);
  
  

  return (
    <Modal open={open} onClose={onClose}>
      <Grid
        container
        spacing={3}
        sx={{
          position: "absolute", top: "50%",left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "white", padding: "20px", width: "40%", maxHeight: "80%",overflowY: "auto",border: "1px solid #000",}}>
        <Grid container justifyContent="flex-end">
          <IconButton onClick={onClose} sx={{ position: "absolute", top: 0, right: 0, marginRight: 2 }}>
            <img src="/icon/close.svg" alt="Close" style={{ width: 35.96, height: 35.96 }} />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <Typography>{i18n.t("others.teacher")}</Typography>
          <Autocomplete
            options={employee}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            value={currentTeacher&&{firstName:currentTeacher?.name,lastName:"",id:currentTeacher?.id}||employee.find((option) => option.id === selectedOption) }
            onChange={(e, newValue) => setSelectedOption(newValue ? newValue.id : "")}
          disabled={currentTeacher}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: false }}
                sx={{
                  width: "85%", height: "10px",marginBottom: "5px",
                  '& .MuiSelect-root': {
                    padding: 0,
                  },
                  '& .MuiInput-root': {
                    padding: 0,
                  },
                }}
              />
            )}
          />
        </Grid>
        {!currentTeacher&&(
        <Grid item xs={4}>
          <Typography>Coordenador</Typography>
          <Checkbox name="isCoordinator" checked={isCoordinator} onChange={handleCheckboxChange} />
          <span>sim</span>
        </Grid>
        )}
         
        {isCoordinator &&!currentTeacher && (
          <Grid item xs={6}>
            <Typography>Unidade curricular</Typography>
            {isSecondSelectVisible ? (
              <Select
                name="subjectId"
                label="Second Graduation"
                value={secondSelectedOption}
                onChange={(e) => setSecondSelectedOption(e.target.value)}
                sx={{ width: "100%", height: "40px", marginBottom: "10px" }}
              >
                {employee?.find((e => e.id == selectedOption))?.subjects?.map((subject) => (
                  <MenuItem key={subject.id} value={subject.id}>
                    {subject.name}
                  </MenuItem>
                ))}
              </Select>
            ) : null}
          </Grid>

        )}
        
        <Grid item xs={12}>
          <Typography  style={{ marginTop: '15px' }}>Disponibilidade</Typography>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            {periodLabels?.map((period) => (
              <div key={period.id} style={{ marginRight: '10px' }}>
                <Checkbox
                  checked={ selectedPeriods?.includes(period?.id)}
                  onChange={() => handlePeriodCheckboxChange(period?.id)}
                />
                <span>{period.name}</span>
              </div>
            ))}
          </div>
        </Grid>
        {selectedOption && (
          <Grid item xs={15} >
            <Typography>Unidade curriculares</Typography>
            {employee?.find((e => e.id == selectedOption))?.subjects?.map((subject) => (
              <Grid key={subject.id} item spacing={2} display={"flex"} >
                <Grid item alignItems="center" display={"flex"}  xs={5} sm={12} md={5}>
                  <Grid item >
                    <Checkbox
                      name="subjectId"
                      checked={selectedSubjects.includes(subject.id)}
                      onChange={() => handleSubjectCheckboxChange(subject.id)}
                    />
                  </Grid>
                  <Grid item >
                    <span>{subject.name}</span>
                  </Grid>
                </Grid>
                <Grid item  xs={5} sm={12} md={7}  display={"flex"} alignItems="center" justifyContent={"flex-end"} >
                  <Grid item sx={{marginRight:"3px"}}>
                  <Select
                  name="courseId"
                  label="Cursos"
                  value={courseSelections[subject.id] || ""}
                  onChange={(e) => handleSelectChange(subject.id, e.target.value)}
                  sx={{ width: "120px", height: "40px" }}
>
                 {courses.filter(c => {
                 const match = subject?.id === c.course?.courseSemesterSubjects?.find((cSS) => subject?.id === cSS.subject?.id)?.subject?.id;
                 console.log("Filtrando cursos para subject.id:", subject?.id, "-> Match:", match, "Curso:", c);
                  return match;
                 }).map((course) => {
                 const courseSemesterSubjectId = course.course.courseSemesterSubjects.find(cSS => cSS.subject?.id === subject?.id)?.id;
                console.log("Mapeando curso:", course, "Com courseSemesterSubjectId:", courseSemesterSubjectId);
                 return (
                <MenuItem key={course.course.id} value={courseSemesterSubjectId}>
                {course?.course?.name}
                 </MenuItem>
                  );
                 })}
                 </Select>

                  </Grid>   
                  <Grid item >
                    <Select
                      name={`strandId-${subject.id}`}
                      label="Strand"
                      value={subjectCourses[subject.id] || ""}
                      onChange={(e) => handleSecondSelectChange(subject?.id, e.target.value)}
                      sx={{ width: "120px", height: "40px" }}
                    >
                      {strandOptions.map((strand) => (
                        <MenuItem key={strand.id} value={strand.id}>
                          {strand.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  </Grid>
                </Grid>
            ))}
          </Grid>
        )}
        <Grid item xs={2}>
          <div style={{ display: 'flex', gap: '10px', paddingLeft: '100px' }}>
            <span style={{ marginLeft: '10px' }}>
              <MainButton onClick={onClose} name={i18n.t("button.cancel")} />
            </span>-
            <span>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#FFA000', width: '90px', height: '30px', color: '#FFFFFF' }}
                color="primary"
                onClick={handleAddTeacher}
              >
                {i18n.t("button.add")}
              </Button>
            </span>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
}


TeacherSelectorModal.propTypes = {
  currentTeacher: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  createdYearId: PropTypes.string.isRequired,
  coordinate: PropTypes.func,
};
