import { Grid, Typography } from '@mui/material';
import './styles/academic-info.css';
import Select from '../../../components/form-components/select';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import axios from "axios";
import PropTypes from 'prop-types';
import { i18n } from '../../../translate/i18n';
const years = [
  { name: '1º Ano', id: '1º Ano' },
  { name: '2º Ano', id: '2º Ano' },
  { name: '3º Ano', id: '3º Ano' },
  { name: '4º Ano', id: '4º Ano' },
]
const AcademicInfo = ({ errors, touched, setFormData, values, incomingDate }) => {
  const { keycloak } = useKeycloak();
  const [graduation, setGraduation] = useState([]);
  const [course, setCourse] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [currentGraduation, setCurrentGraduation] = useState(null);

  const getList = async (type, set) => {


    try {
      console.log(`Buscando dados de ${type}`);
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
      });
      console.log('Resposta da Requisição:', response);
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  useEffect(() => {
    console.log('Entrou no useEffect');

    //const transformCurriculumPlanar=(cp)=>setCourse(cp.map(c=>c.course))

    getList("graduations", setGraduation);
    getList("/courses/from-school-year", setCourse);

    console.log('Saiu do useEffect');
  }, []);
  useEffect(() => {
    if (incomingDate) {

      values.year = incomingDate.year || '';

      // Outras atribuições conforme necessário...
    } else {

      values.year = '1º Ano'; // Defina um valor padrão ou deixe vazio, dependendo do requisito.

      // Outras atribuições conforme necessário...
    }

    setFormData({ ...values });
  }, [incomingDate, course, graduation]);



  const isAluno = keycloak?.tokenParsed?.resource_access?.['sga-api']?.roles?.includes('Estudante');
  const isSecretary = keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles?.includes("Secretaria")
  console.log("É SECRETARIA: ", isSecretary)
  const headingText = isAluno
    ? 'Renovação de matrícula'
    : (isSecretary ? i18n.t("sidebar.StudentRegistration") : "Inscreva-se")

  const welcomeText = isAluno
    ? 'Seja bem-vindo ao processo de renovação do curso de Medicina da Faculdade de Medicina da Universidade de Agostinho Neto, Angola.'
    : (isSecretary ? "" : "Seja bem-vindo ao processo de matrícula no 1º ano do curso de Medicina da Faculdade de Medicina da Universidade de Agostinho Neto, Angola. Para dar início ao processo de matrícula, forneça as informações em cada campo de forma sequenciada.");



  return (
    <div className='academic-info'>
      <h2>{headingText}</h2>
      <Typography sx={{ textAlign: 'justify', color: "#949494" }} variant='p'>{welcomeText}</Typography>
      <Typography sx={{ paddingTop: "30px", paddingBottom: "20px", color: "#003B76" }} variant='h3'>Dados Académicos</Typography>

      <Grid container gap={6}>
        <Grid item xs={5}>
          {errors.graduation && touched.graduation ? (
            <div className="error-message">{errors.graduation}</div>
          ) : null}
          <Select
            onChange={(e) => { setCurrentGraduation(e.target.value); values.course = null; }}
            name='graduation'
            options={graduation}
            defaultValue={graduation?.find((g) => g.name === "Licenciaturas")?.id || graduation?.find((g) => g.id === incomingDate?.graduation)?.id}
            label={<span>{i18n.t("sidebar.graduation")}<span className="red-asterisk">*</span></span>}

          />
        </Grid>
        <Grid item xs={5}>
          {errors.course && touched.course ? (
            <div className="error-message">{errors.course}</div>
          ) : null}
          <Select
            name='course'
            options={isSecretary ? course : course.filter((c) => c?.graduation === graduation?.find((g) => g.id === currentGraduation)?.name)}
            label={<span>Curso<span className="red-asterisk">*</span></span>}
          />
        </Grid>
        <Grid item xs={2} sx={{ marginTop: "-20px" }}>
          {errors.year && touched.year ? (
            <div className="error-message">{errors.year}</div>
          ) : null}
          <Select
            name='year'
            options={years}
            label="Ano"
            disabled={isSecretary ? false : true}
            defaultValue="1º Ano"
          />
        </Grid>
      </Grid>
    </div>
  );
};
AcademicInfo.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  incomingDate: PropTypes.shape({
    year: PropTypes.string,
    graduation: PropTypes.string
  }),
};

export default AcademicInfo;
