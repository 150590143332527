import { Grid, IconButton, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import axios from "axios";
import Textfield from "../../../components/form-components/text-field";
import { i18n } from "../../../translate/i18n";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Loading from "../../../components/loading/loading";
import DisciplinaModal from "../../staff/components/forms-fields/modal";
import ButtonWithTitle from "../../../components/styleButton/styleButton";
import CloseIcon from "@mui/icons-material/Close";
import { useKeycloak } from "@react-keycloak/web";
import CourseStudents from "../create/CourseStudents";
import CourseTeachers from "../create/CourseTeachers";
import CourseDisciplines from "./CourseDisciplines";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import FilterList from "../../../components/filterList/FilterList";
import AutoSearch from "../../../components/autoSearch/AutoSearch";
import SchedulesSelect from "../components/buttonSelect/ShedulesSelect";
import { flattenArray } from "../../../utils/functions/tableFilterFunctions";
import { useFilter } from "../../../utils/hooks/useSearch";

export function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 2 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

export const solveDate = (coordinatorData) => {
   let d = new Date(coordinatorData?.courseCoordination?.schoolYear?.startDate);
   let EndDate = new Date(coordinatorData?.courseCoordination?.schoolYear?.endDate);
   return (
      coordinatorData?.courseCoordination?.schoolYear?.startDate &&
      d.getFullYear() + (coordinatorData?.courseCoordination?.schoolYear?.endDate && "-" + EndDate.getFullYear())
   );
};

export default function RegisterCourse(props) {
   let isDisabled = true;
   const { keycloak } = useKeycloak();
   const [coordinatorData, setCoordinatorData] = useState([]);
   const navigate = useNavigate(); // Hook para navegação

   const [loading, setLoading] = useState(false);
   const apiUrl = process.env.REACT_APP_API_URL;
   const loggedUsername = keycloak.tokenParsed.preferred_username;
   const { id } = useParams();
   const [schoolYearId, setSchoolYearId] = useState();

   const extractUniqueValues = (array, key) => {
      return [...new Set(array?.map((item) => item[key]))];
   };

  
   const flattenedSemesters = flattenArray(coordinatorData?.courseSemesterSubjects, "semester");
   const semesters = [
      ...new Set(
         extractUniqueValues(flattenedSemesters, "semester")
            .sort((a, b) => a - b)
            .map((item) => item)
      ),
   ];

   const years = Array.from({ length: coordinatorData?.totalNumberOfYears }, (_, i) => i + 1);
   const flattenedSubject = flattenArray(coordinatorData?.courseSemesterSubjects, "subject");
   const subjects = [...new Set(extractUniqueValues(flattenedSubject, "subject").map((item) => item.name))];

   const [selectedYear, setSelectedYear] = useState([]);
   const [selectedSemester, setSelectedSemester] = useState([]);
   const [selectedSubject, setSelectedSubject] = useState([]);
   const [selectedStrand, setSelectedStrand] = useState([]);
   const [searchTerm, setSearchTerm] = useState("");
   const [value, setValue] = useState(0);

   const schedulesTypes = [
      { name: "Teorica", url: "scheduler" },
      { name: "Pratica", url: "scheduler-p" },
   ];

   const strand = schedulesTypes.map((item) => item.name);

   const { setCanFilter, canFilter } = useFilter();

   const filters = [
      {
         label: i18n.t("others.subject"),
         options: subjects,
         selectedOptions: selectedSubject,
         setSelectedOptions: setSelectedSubject,
      },
      {
         label: i18n.t("others.year"),
         options: years,
         selectedOptions: selectedYear,
         setSelectedOptions: setSelectedYear,
      },
      {
         label: i18n.t("others.semester"),
         options: semesters,
         selectedOptions: selectedSemester,
         setSelectedOptions: setSelectedSemester,
      },
      {
         label: i18n.t("others.strand"),
         options: strand,
         selectedOptions: selectedStrand,
         setSelectedOptions: setSelectedStrand,
      },
   ];

   const getCoordinatorByUserId = async () => {
      setLoading(true);
      try {
         const courseResponse = await axios.get(`${apiUrl}/courses/${id}`, {
            headers: {
               Authorization: "Bearer " + keycloak.token,
            },
         });
         const courseData = courseResponse?.data;

         setCoordinatorData(courseData);
         setSchoolYearId(courseData?.courseCoordination?.schoolYear?.id);
         setSelectedDeparts(courseData?.department);
      } catch (error) {
         console.error("Erro ao obter dados do coordenador:", error);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (keycloak.token) {
         getCoordinatorByUserId();
      }
   }, [keycloak, id]);

   //Departments Modal functionalities
   let departmentsIncommingData = [];
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [selectedDeparts, setSelectedDeparts] = useState([]);

   const saveDepartmentNames = () => {
      // Salve os nomes das disciplinas em seu estado ou estrutura de dados preferida
      console.log(
         "Salvando nomes das Unidade curriculares:",
         selectedDeparts.map((depart) => depart.name)
      );
   };

   const handleRemoveDepartment = (department) => {
      const updatedDeparts = selectedDeparts.filter((currentDepart) => currentDepart?.id !== department?.id);
      setSelectedDeparts(updatedDeparts);
   };

   return (
      <>
       <Box sx={{ 
      backgroundColor: "#FFFFFF", 
      marginRight:"10px",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", 
      padding: "20px", 
      marginTop: "-30px",  
      width: "1230px !important",
      top: "100px",  // Ajuste para controlar a posição vertical
 
      marginLeft: "-50px", 
      height:"10px",  // Ajuste para garantir que a altura seja dinâmica
      position: "absolute", // Posicionamento absoluto para ficar por cima
      zIndex: 1000, // Garante que a Box fique acima de outros elementos
    }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px", }}>
        <IconButton sx={{ 
          color: "#FBC02D", 
          marginRight: "0px", 
          display: "flex", 
          alignItems: "center", 
        }}
        onClick={() => navigate('/courses')}

        >
          <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-5px " , marginTop: "-8px", }} />
          <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px " , marginTop: "-8px", }} />
        </IconButton>
        <Typography variant="h6" sx={{ 
          fontWeight: 400, 
          fontSize: "18px", 
          color: "#FBC02D", 
          marginRight: "10px", 
          marginTop: "-8px",
        }}>
          Voltar
        </Typography>
        <Typography variant="h6" sx={{ 
          fontWeight:4600, 
          fontSize: "18px", 
          color: "#8893A9" ,
          marginTop: "-8px",
          gap:"20px",
        }}>
          Curso
        </Typography>
      </Box>
    </Box>
         {loading && <Loading />}
         <Grid
            backgroundColor="white"
            sx={{
               padding: "55px 110px 50px",
               margin: "0 -100px 20px -50px",
            }}
         >
            <Formik>
               <Form>
                  <Typography variant="h1" gutterBottom paddingBottom={"30px"}>
                     {i18n.t("course.titleCreate")}
                  </Typography>

                  <Grid
                     container
                     spacing={2}
                     //xs={12}
                     style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        flexDirection: "row",
                        Width: "1183px",
                        //margin: "auto",
                     }}
                  >
                     <Grid item xs={9} md={5.3} xl={4}>
                        <Grid>
                           <Textfield
                              name="name"
                              value={coordinatorData?.name}
                              disabled={isDisabled}
                              label={i18n.t("others.name")}
                           />
                        </Grid>

                        <Grid style={{ marginTop: "30px" }}>
                           <Textfield
                              name="graduation"
                              value={coordinatorData?.graduation}
                              label={i18n.t("others.graduationType")}
                              disabled={isDisabled}
                           ></Textfield>
                        </Grid>
                        <Grid style={{ marginTop: "30px" }}>
                           <Textfield
                              name="coordenator"
                              value={loggedUsername}
                              label={i18n.t("others.coordinator")}
                              disabled={isDisabled}
                           ></Textfield>
                        </Grid>
                        <Grid style={{ marginTop: "30px" }}>
                           <label>
                              <p
                                 style={{
                                    fontSize: "13px",
                                    fontWeight: "600",
                                    color: "#42474B",
                                    margin: "0px",
                                    border: "none",
                                 }}
                              >
                                 {i18n.t("others.department")}
                              </p>
                           </label>
                           <Grid>
                              <ButtonWithTitle
                                 title={
                                    selectedDeparts?.length > 0
                                       ? `${i18n.t("department.addedDeparts")}`
                                       : `${i18n.t("department.addDepart")}`
                                 }
                                 onClick={() => setIsModalOpen(true)}
                                 disabled={isDisabled}
                              />
                              <Grid container spacing={1}>
                                 {selectedDeparts?.length
                                    ? selectedDeparts.map((department) => (
                                         <Grid item key={department.id}>
                                            <div
                                               style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  marginRight: 2,
                                                  marginBottom: 1,
                                                  background: "#E9ECF5",
                                                  padding: "1px",
                                                  marginTop: "8px",
                                               }}
                                            >
                                               <Typography key={department.id}>{department.name}</Typography>
                                               <IconButton
                                                  size="small"
                                                  onClick={() => handleRemoveDepartment(department)}
                                               >
                                                  {!isDisabled && <CloseIcon />}
                                               </IconButton>
                                            </div>
                                         </Grid>
                                      ))
                                    : null}
                              </Grid>
                           </Grid>
                           <DisciplinaModal
                              open={isModalOpen}
                              onClose={() => setIsModalOpen(false)}
                              onConfirm={(selectedDeparts) => {
                                 setSelectedDeparts(selectedDeparts);
                                 saveDepartmentNames();
                                 setIsModalOpen(false);
                              }}
                              title={i18n.t("others.department")}
                              data={departmentsIncommingData}
                              selected={selectedDeparts}
                           />
                        </Grid>
                     </Grid>
                     <Grid item xs={9} md={5.3} xl={4}>
                        <Grid item>
                           <Textfield
                              name="lativeYear"
                              value={solveDate(coordinatorData)}
                              label={i18n.t("others.lativeYear")}
                              disabled={isDisabled}
                           ></Textfield>
                        </Grid>
                        <Grid
                           style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                              marginTop: "30px",
                           }}
                        >
                           <Grid item>
                              <Textfield
                                 name="tuition"
                                 disabled={isDisabled}
                                 label={i18n.t("others.tuition")}
                                 value={coordinatorData?.price}
                              />
                           </Grid>
                           <Grid item>
                              <Textfield
                                 name="numberOfStudents"
                                 disabled={isDisabled}
                                 label={i18n.t("others.numberOfStudents")}
                                 type="number"
                                 value={coordinatorData?.totalStudents}
                              />
                           </Grid>
                        </Grid>
                        <Grid
                           style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                              marginTop: "30px",
                           }}
                        >
                           <Grid item>
                              <Textfield
                                 name="Workload"
                                 disabled={isDisabled}
                                 value={coordinatorData?.workingHours}
                                 label={i18n.t("others.workload")}
                              />
                           </Grid>
                           <Grid item>
                              <Textfield
                                 name="year"
                                 disabled={isDisabled}
                                 label={i18n.t("others.year")}
                                 type="number"
                                 value={coordinatorData?.workingHours}
                              />
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Form>
            </Formik>
         </Grid>
         {!loading && (
            <Grid
               style={{
                  backgroundColor: "white",
                  padding: "15px",
                  marginLeft: "-50px",
                  marginRight: "-100px",
               }}
            >
               <Box>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#F1F1E1",
                        height: "75px",
                        marginX: "15px",
                     }}
                  >
                     <Tabs
                        value={value}
                        onChange={(event, newValue) => setValue(newValue)}
                        aria-label="basic tabs example"
                        sx={{
                           height: "10px",
                           display: "flex",
                           alignItems: "center",
                           margin: "auto",
                           marginLeft: "20px",
                        }}
                     >
                        <Tab
                           label={i18n.t("others.disciplines")}
                           style={{
                              backgroundColor: value === 0 ? "#FFC107" : "",
                              color: value === 0 ? "#f3f3f3" : "#2B434E",
                           }}
                        />
                        <Tab
                           label={i18n.t("others.schedules")}
                           style={{
                              backgroundColor: value === 1 ? "#FFC107" : "",
                              color: value === 1 ? "#f3f3f3" : "#2B434E",
                           }}
                        />
                        <Tab
                           label={i18n.t("others.student")}
                           style={{
                              backgroundColor: value === 2 ? "#FFC107" : "",
                              color: value === 2 ? "#f3f3f3" : "#2B434E",
                           }}
                        />
                        <Tab
                           label={i18n.t("others.teacher")}
                           style={{
                              backgroundColor: value === 3 ? "#FFC107" : "",
                              color: value === 3 ? "#f3f3f3" : "#2B434E",
                           }}
                        />
                     </Tabs>
                     <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        {value !== 1 && value !== 0 && (
                           <AutoSearch
                              placeholder={i18n.t("others.search")}
                              sx={{ backgroundColor: "white" }}
                              search={searchTerm} // Passando o termo de pesquisa
                              setSearch={(value) => setSearchTerm(value)} // Passando a função para atualizar o termo de pesquisa
                           />
                        )}

                        {value !== 0 && (
                           <FilterList
                              filters={filters}
                              setCanFilter={setCanFilter}
                              canFilter={canFilter}
                              cardStyle={{
                                 position: "absolute",
                                 zIndex: "1",
                                 top: "39.8rem",
                                 right: "21.2rem",
                              }}
                           />
                        )}
                     </Box>
                  </Box>

                  <TabPanel value={value} index={0}>
                     <CourseDisciplines
                        schoolYearId={schoolYearId}
                        totalNumberOfYears={coordinatorData?.totalNumberOfYears}
                     />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                     <SchedulesSelect
                        selectedYear={selectedYear}
                        selectedSemester={selectedSemester}
                        selectedSubject={selectedSubject}
                        selectedStrand={selectedStrand}
                        setCanFilter={setCanFilter}
                        canFilter={canFilter}
                     />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                     <CourseStudents
                        searchTerm={searchTerm}
                        selectedYear={selectedYear}
                        selectedSemester={selectedSemester}
                        selectedSubject={selectedSubject}
                     />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                     <CourseTeachers searchTerm={searchTerm} />
                  </TabPanel>
               </Box>
            </Grid>
         )}
      </>
   );
}
