import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { i18n } from '../../translate/i18n';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';

export default function ReportPage() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { keycloak } = useKeycloak();
  const [studentData, setStudentData] = useState(null);
  const [studentDiscipline, setStudentDiscipline] = useState(null);
  const [semesters, setSemesters] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState(null);
  const [evaluationData, setEvaluationData] = useState([]);
  const [evaluationTypes, setEvaluationTypes] = useState([]);
  const [reportData, setReportData] = useState([]);

  const typeMapping = {
    "B I": "BI",
    "B II": "BII",
    "T I": "TI",
    "T II": "TII",
    "P I": "PI",
    "P II": "PII",
    "Avaliação continua": "averageGrade",
    "Nota Final": "finalGrade"
  };

  const organizeGradesByType = (evaluationData, studentDisciplineData) => {
    const gradesByType = {};
    evaluationData.forEach(evaluation => {
      const type = typeMapping[evaluation.evaluationType];
      if (!gradesByType[type]) {
        gradesByType[type] = {};
      }
      if (!gradesByType[type][evaluation.subject]) {
        gradesByType[type][evaluation.subject] = [];
      }
      gradesByType[type][evaluation.subject].push(evaluation.value);
    });
  
    // Incorporar as notas finalGrade e averageGrade
    if (studentDisciplineData && studentDisciplineData.length > 0) {
      studentDisciplineData.forEach(subjectData => {
        const subjectName = subjectData.subject.name;
        if (!gradesByType['finalGrade']) {
          gradesByType['finalGrade'] = {};
        }
        if (!gradesByType['averageGrade']) {
          gradesByType['averageGrade'] = {};
        }
        gradesByType['finalGrade'][subjectName] = [subjectData.finalGrade];
        gradesByType['averageGrade'][subjectName] = [subjectData.averageGrade];
      });
    }
  
    return gradesByType;
  };
  

  const goToPreviousYear = () => {
    const currentIndex = years.findIndex(year => year.id === selectedYear.id);
    if (currentIndex > 0) {
      setSelectedYear(years[currentIndex - 1]);
    }
  };

  const goToNextYear = () => {
    const currentIndex = years.findIndex(year => year.id === selectedYear.id);
    if (currentIndex < years.length - 1) {
      setSelectedYear(years[currentIndex + 1]);
    }
  };

  const goToPreviousSemester = () => {
    const currentIndex = selectedSemester ? semesters.findIndex(semester => semester.id === selectedSemester.id) : -1;
    if (currentIndex > 0) {
      setSelectedSemester(semesters[currentIndex - 1]);
    }
  };

  const goToForeachSemester = () => {
    const currentIndex = selectedSemester ? semesters.findIndex(semester => semester.id === selectedSemester.id) : -1;
    if (currentIndex < semesters.length - 1) {
      setSelectedSemester(semesters[currentIndex + 1]);
    }
  };

  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStudentData = async () => {
    try {
      const userId = keycloak.idTokenParsed.sub;
  
      const studentResponse = await axios.get(`${apiUrl}/students/user-account/${userId}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      setStudentData(studentResponse.data);

      const studentId = studentResponse.data.id;
      const subjectsResponse = await axios.get(`${apiUrl}/students/${studentId}/subjects`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      setStudentDiscipline(subjectsResponse.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEvaluationData = async () => {
    try {
      const studentId = studentData.id;
      const semesterId = selectedSemester.id;

      const evaluationResponse = await axios.get(`${apiUrl}/evaluation/student/${studentId}/semester/${semesterId}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      setEvaluationData(evaluationResponse.data);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchStudentData();
    getList("semester", setSemesters);
    getList("years", setYears);
    setEvaluationTypes(Object.keys(typeMapping));
  }, [apiUrl, keycloak.token]);

  useEffect(() => {
    if (semesters.length > 0) {
      setSelectedSemester(semesters[0]);
    }
  }, [semesters]);

  useEffect(() => {
    if (years.length > 0) {
      setSelectedYear(years[0]);
    }
  }, [years]);

  useEffect(() => {
    if (selectedSemester && studentData) {
      fetchEvaluationData();
    }
  }, [selectedSemester, studentData]);

  useEffect(() => {
    if (studentDiscipline && studentDiscipline.data && Array.isArray(studentDiscipline.data)) {
      const report = studentDiscipline.data.map(subjectData => ({
        name: subjectData.subject.name,
        acronym: subjectData.subject.acronym,
        finalGrade: subjectData.finalGrade
      }));
      setReportData(report);
    }
  }, [studentDiscipline]);

  return (
    <Grid container component={Paper}>
      <Grid item xs={12}>
        <TableContainer sx={{ overflow: 'hidden' }}>
          <Table sx={{ minWidth: 650, border: '1px solid blue', borderRight: '1px solid blue' }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={4} style={{ height: "59px", borderBottom: '1px solid blue', background: ' #CCD8E4', color: 'black' }}>
                  <Typography
                    variant="h3"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "24px"
                    }}
                  >
                    {i18n.t("Relatório de Avaliações")}
                    <Box display="flex" justifyContent={"end"} >
                      <Grid container  >
                        <Grid item display={"flex"} >
                          <Button onClick={goToPreviousYear} className="arrow-button" style={{ border: 'none', background: 'none', color: 'black' }}>&lt;</Button>
                          <Typography
                            variant="p"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              fontSize: "12px"
                            }}
                          >
                            {selectedYear && i18n.t(selectedYear.value)}ano
                          </Typography>
                          <Button onClick={goToNextYear} className="arrow-button" style={{ border: 'none', background: 'none', color: 'black' }}>&gt;</Button>
                        </Grid>
                      
                      <Grid item display={"flex"}>
                        <Button onClick={goToPreviousSemester} className="arrow-button" style={{ border: 'none', background: 'none', color: 'black' }}>&lt;</Button>
                        <Typography
                          variant="p"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontSize: "12px"
                          }}
                        >
                          {selectedSemester && i18n.t(selectedSemester.name)}
                        </Typography>
                        <Button onClick={goToForeachSemester} className="arrow-button" style={{ border: 'none', background: 'none', color: 'black' }}>&gt;</Button>
                      </Grid>
                      </Grid>
                    </Box>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell style={{ borderBottom: '1px solid blue', background: '#CCD8E4', color: 'black', width: 200 }}>{i18n.t("Unidade curricular")}</TableCell>
                {reportData.map((subject) => (
                  <TableCell key={subject} align="center" style={{ borderBottom: '1px solid blue', background: '#CCD8E4', color: 'black', width: 200 }}>
                    {subject.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {evaluationTypes.map((evaluationType) => (
                <TableRow key={evaluationType}>
                  <TableCell component="th" scope="row" style={{ borderBottom: '1px solid blue', color: 'black', height: '100%' }}>
                    {i18n.t(evaluationType)}
                  </TableCell>
                  {reportData.map((subject) => (
                    <React.Fragment key={subject}>
                      <TableCell align="center" style={{ width: 200, borderLeft: '1px solid blue', borderRight: '1px solid blue', borderBottom: '1px solid blue', height: '100%' }}>
                        {(organizeGradesByType(evaluationData, studentDiscipline.data)[typeMapping[evaluationType]] && organizeGradesByType(evaluationData, studentDiscipline.data)[typeMapping[evaluationType]][subject.name]) ?
                          organizeGradesByType(evaluationData, studentDiscipline.data)[typeMapping[evaluationType]][subject.name].join(', ') : "-"}
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid> 
    </Grid>
  );
}
