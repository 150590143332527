import { Box, Button, Grid, Typography, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import CustomPagination from "../../components/pagination";
import { i18n } from "../../translate/i18n";
import { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import FilterList from "../../components/filterList/FilterList";
import { AddBoxRounded } from "@mui/icons-material";
import Loading from "../../components/loading/loading";
import { MenuItem, Select } from "@mui/material";
import { useFilter, useSearch } from "../../utils/hooks/useSearch";
import FallBackCard from "../../components/FallBackComponents/FallBackCard";
import usePagination from "../../utils/hooks/usePagination";
import {
  deleteItem as eliminate,
  editItem as edit,
  visualizeItem,
  getItem as get,
  newItemPage,
} from "../../utils/functions/tableCRUDFunctions";
import {
  extractUniqueValues,
  filterFunction,
  matchFunction,
  determineArray,
} from "../../utils/functions/tableFilterFunctions";
import AlertDialogForConfirm from "../../components/confirmation/AlertDialog";
import { useConfirmDeleteModal } from "../../utils/hooks/useModals";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8FAFD",
    color: "#939a9f",
  },
}));

export default function ExamsListingPage() {
  const apiUrl = `${process.env.REACT_APP_API_URL}/accessExams`;
  const pathUrl = "/exams";
  const { keycloak } = useKeycloak();
  const [IncomingData, setIncomingData] = useState([]);
  const navigate = useNavigate();
  const newItem = newItemPage(navigate, `${pathUrl}/create`);
  const [isLoading, setIsLoading] = useState(true);

  const {
    open,
    deleteItemId,
    message,
    loading,
    setLoading,
    success,
    handleClose,
    handleClickOpen,
    handleSuccess,
    handleBadRequest,
    errorFallback,
  } = useConfirmDeleteModal();

  const analyzeItem = visualizeItem(`${pathUrl}/view`, navigate);
  const editItem = edit(`${pathUrl}/edit`, navigate);

  // Table filters
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState([]);
  const [selectedGradType, setSelectedGradType] = useState([]);

  const schoolYears = extractUniqueValues(IncomingData, "schoolYear");
  const gradTypes = extractUniqueValues(IncomingData, "graduationType");
  const courses = extractUniqueValues(IncomingData, "course");

  const filters = [
    {
      label: i18n.t("others.schoolYear"),
      options: schoolYears,
      selectedOptions: selectedCourse,
      setSelectedOptions: setSelectedCourse,
    },
    {
      label: i18n.t("others.graduationType"),
      options: gradTypes,
      selectedOptions: selectedSchoolYear,
      setSelectedOptions: setSelectedSchoolYear,
    },
    {
      label: i18n.t("others.course"),
      options: courses,
      selectedOptions: selectedGradType,
      setSelectedOptions: setSelectedGradType,
    },
  ];

  const filteringProperties = [
    { schoolYear: selectedCourse },
    { graduationType: selectedSchoolYear },
    { course: selectedGradType },
  ];

  //Search functionality

  const matchingProperties = ["schoolYear", "graduationType", "course"];

  const {
    searchTerm,
    setSearchTerm,
    filteredData: searchResults,
  } = useSearch(IncomingData, matchFunction, matchingProperties);
  const { filteredData, setCanFilter, canFilter } = useFilter(
    searchResults,
    filterFunction,
    filteringProperties
  );

  //Pagination  & Items per page filter
  const items = determineArray(
    IncomingData,
    filteredData,
    searchTerm,
    canFilter
  );

  const {
    currentPage,
    totalPages,
    slicedItems,
    setCurrentPage,
    pageSize,
    handlePageSizeChange,
    availablePageSizes,
  } = usePagination(
    items,
    [5, 10, 20, 100],
    undefined,
    searchTerm || canFilter
  );

  console.log("Items: ", items);
  console.log("Sliced items", slicedItems);
  console.log("Filtered items", filteredData);
  console.log("Incoming items", IncomingData);

  const getItem = get(
    apiUrl,
    keycloak,
    setIncomingData,
    setIsLoading,
    currentPage
  );

  const deleteItem = eliminate(
    apiUrl,
    keycloak,
    getItem,
    setLoading,
    handleSuccess,
    handleBadRequest,
    errorFallback
  );

  useEffect(() => {
    if (keycloak.token) {
      getItem();
    }
  }, [keycloak.token]);

  return (
    <>
      <Grid
        container
        component={Paper}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "relative",
          boxShadow: "none",
          padding: { xs: 2, md: 3 },
        }}
      >
        {isLoading && <Loading />}
        <Grid item sx={{ width: "100%" }}>
          {!isLoading && (
            <div className="container-da-tabela-responsive">
              <TableContainer
                sx={{
                  overflowX: "auto",
                  border: "1px solid #E9ECF5",
                }}
              >
                <Table
                  sx={{
                    minWidth: "100%",
                    tableLayout: "fixed",
                  }}
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        colSpan={4} // Ajuste o colSpan conforme necessário
                        sx={{
                          height: "59px",
                          fontSize: { xs: "12px", sm: "14px" },
                        }}
                      >
                        <Typography
                          variant="h3"
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            alignItems: "flex-start",
                            justifyContent: { sm: "space-between" },
                            gap: 2,
                            fontSize: { xs: "14px", sm: "16px" },
                          }}
                        >
                          {i18n.t("ExamsList.title")}
                          <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                          <Box sx={{ width: { xs: "100%", sm: "300px" } }}>
                            <AutoSearch
                              placeholder={i18n.t("others.search")}
                              search={searchTerm}
                              setSearch={setSearchTerm}
                              handler={() => setSearchTerm("")}
                              sx={{ width: "100%" }}
                            />
                          </Box>
                          <Box sx={{ width: { xs: "100%", sm: "100px" }, marginLeft: { xs: 0, sm: "20px" } }}>
                            <Select
                              value={pageSize}
                              onChange={handlePageSizeChange}
                              displayEmpty
                              inputProps={{ "aria-label": "Page size" }}
                              sx={{
                                width: { xs: "100%", sm: "80px" },
                                height: "40px",
                                fontSize: { xs: "10px", sm: "12px" },
                              }}
                            >
                              {availablePageSizes.map((size) => (
                                <MenuItem key={size} value={size}>
                                  {size}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                           <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            width: { xs: "100%", sm: "120px" },
                            height: "40px",
                            border: "1px solid #C3CAD9",
                            marginRight: "20px",
                            borderRadius: "5px",
                          }}>
                            <FilterList
                              cardStyle={{
                                position: "absolute",
                                zIndex: "1",
                                top: "140px",
                                right: "20px",
                              }}
                              filters={filters}
                              setCanFilter={setCanFilter}
                              canFilter={canFilter}
                            />
                            {i18n.t("others.filter")}
                          </Box> 
                          <Button
                            variant="outlined"
                            sx={{
                              height: "35px",
                              width: "80px",
                              color: "#FFC107",
                              backgroundColor: "#FFFFFF",
                              borderColor: "#FFC107",
                              "&:hover": {
                                color: "#FFC107",
                                backgroundColor: "#FFFFFF",
                                borderColor: "#FFC107",
                              },
                            }}
                            onClick={newItem}
                          >
                            <AddBoxRounded />
                            {i18n.t("button.new")}
                          </Button>     
                                                </Box>
                          </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      height="56px"
                      sx={{
                        backgroundColor: "#F8FAFD",
                        "& th": {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
                        },
                      }}
                    >
                      <StyledTableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.schoolYear")} </Typography> </StyledTableCell>
                      <StyledTableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.course")} </Typography>  </StyledTableCell>
                      <StyledTableCell align="left"> <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.graduationType")} </Typography> </StyledTableCell>
                      <StyledTableCell align="center"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.options")} </Typography> </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {IncomingData && IncomingData.map((data) => (
                      <TableRow
                        key={data.id}
                        sx={{
                          "& td": {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
                          },
                        }}
                      >
                        <TableCell component="th" scope="row"> <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> {data.schoolYear} </Typography></TableCell>
                        <TableCell align="left"> <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500,}}> {data.course?.name} </Typography></TableCell>
                        <TableCell align="left"> <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },  fontWeight: 500,}} > {data.course?.graduation} </Typography></TableCell>
                        <TableCell align="center"> <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}>
                          <ActionButtons
                            id={data.id}
                            handleVisualize={analyzeItem}
                            handleEdit={editItem}
                            handleDeleteItem={() => handleClickOpen(data.id)}
                          />
                       </Typography> </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <FallBackCard data={IncomingData} isLoading={isLoading} />
              </TableContainer>
            </div>
          )}
        </Grid>
      </Grid>
      {!isLoading && IncomingData?.length ? (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: { xs: 5, sm: 10 },
          }}
        >
          <CustomPagination
            setPage={setCurrentPage}
            totalPages={totalPages}
            page={currentPage}
          />
        </Grid>
      ) : null}
      
      {deleteItemId && (
        <AlertDialogForConfirm
          id={deleteItemId}
          open={open}
          handleClose={handleClose}
          handleDeleteItem={deleteItem}
          message={message}
          isSuccessful={success}
          isLoading={loading}
        />
      )}
    </>
  );
}