import { i18n } from "../../translate/i18n";

export const formatDateToLocale = (dateString, locale = "pt-AO") => {
  let date = new Date(dateString);
  let formattedDate = date.toLocaleDateString(locale);
  return formattedDate;
};

export function formatCurrencyToLocale(
  value,
  locale = "pt-AO",
  currency = "AOA"
) {
  return value?.toLocaleString(locale, {
    style: "currency",
    currency: currency,
  });
}

export const truncateText = (string, length = 20) => {
  const str = string ? string : "";
  return str.length > length ? str.substring(0, length) + "..." : str;
};

export const extractSemesterName = (selectedSemester) => {
  const parts = selectedSemester?.split("º");
  if (parts[1]) {
    const number = parts[1].trim().replace(/\D/g, "");
    return number + "º Semestre";
  } else {
    // Handle the case where parts[1] is undefined
    // You can return a default value or throw an error
    return "1º Semestre";
  }
};

export const emptySetters = (settersToEmpty) => {
  console.log("settersToEmpty: ", settersToEmpty);
  settersToEmpty.forEach((setter) => setter(""));
};

export const handleFailure = (response) => {
  const errorMessages = {
    networkError: i18n.t("request.netError"),
    serverError: i18n.t("request.serverError"),
    unauthorized: i18n.t("request.unauthorized"),
    badRequest: i18n.t("request.badRequest"),
    // Add more error messages as needed
  };
  console.log("util response: ", response);
  if (response?.status === 401) {
    return errorMessages.unauthorized;
  } else if (response?.status >= 500) {
    return errorMessages.serverError;
  } else if (response?.status >= 400) {
    return errorMessages.networkError;
  }
  return errorMessages.badRequest;
};
//response.data.message
//error.response.data.message.message
//error.response.data

export const getErrorMessage = (error) => {
  const errorMessages = {
    syntaxError: i18n.t("request.syntaxError"),
    // Add more error messages as needed
  };
  console.log("util ERROR: ", error);
  if (error?.message) {
    return errorMessages.syntaxError;
  }
};
