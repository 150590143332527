import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import CustomPagination from "../../components/pagination";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import { useKeycloak } from "@react-keycloak/web";
import { i18n } from "../../translate/i18n";
import FilterList from "../../components/filterList/FilterList";
import { AddBoxRounded } from "@mui/icons-material";
import AlertDialogForConfirm from "../../components/confirmation/AlertDialog";
import Loading from "../../components/loading/loading";
import { truncateText } from "../../utils/functions/utilFunctions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8FAFD",
    color: "#939a9f",
  },
}));

export default function SubjectListingPage() {
  const { keycloak } = useKeycloak();
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [incomingDate, setIncomingDate] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const pathUrl = "/subject";
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    console.log("Closing modal");
    setDeleteItemId(null);
    setOpen(false);
  };
  const handleClickOpen = (id) => {
    console.log("Opening modal. ID:", id);
    setDeleteItemId(id);
    setOpen(true);
  };
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    const searchSubject = async (searchTerm) => {
      try {
        const response = await axios.get(
          `${apiUrl}/subject?search=${encodeURIComponent(searchTerm)}`,
          {
            headers: {
              Authorization: "Bearer " + keycloak.token,
            },
          }
        );
        setSearchResults(
          response.data?.data.map((depart) => ({
            ...depart,
          }))
        );
        return response.data;
      } catch (error) {
        console.error("Error searching departments:", error);
        throw error;
      }
    };
    const delaySearch = setTimeout(() => {
      if (search.trim() !== "") {
        searchSubject(search);
      }
    }, 500);
    return () => clearTimeout(delaySearch);
  }, [search, keycloak.token]);
  const getSubjectListingPage = async () => {
    setLoading(true);
    if (!page) {
      setLoading(false);
      return;
    }
    const params = new URLSearchParams({ page: page });
    console.log("PAGE: ", params.toString())

    try {
      const response = await axios.get(
        `${apiUrl}/subject?` + params.toString(),
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
      setIncomingDate(response.data.data);
      console.log("RESPONSE: ", response.data)
      setPage(response.data.meta.currentPage);
      setTotalPages(response.data.meta.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (keycloak.token) {
      getSubjectListingPage();
    }
  }, [keycloak.token, page]);
  const handleDataToVisualize = (id) => {
    navigate(`/subjects/${id}?disabled=true`);
  };
  const handleDataToedit = (id) => {
    navigate(`/subjects/${id}/edit`);
  };
  const handleDeleteItem = async (id) => {
    console.log("Deleting item with ID:", id);
    setDeleteItemId(id);
    try {
      const response = await axios.delete(`${pathUrl}/${id}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      if (response.status === 200) {
        setIncomingDate(
          incomingDate.filter((incomingDate) => incomingDate.id !== id)
        );
      } else {
        console.error(
          "Failed to delete employee. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      handleClose();
    }
  };

  const CreateSubject = () => {
    navigate("/subjects/create");
  };

  return (
    <>
      <Grid
        container
        component={Paper}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "relative",
          alignItems: { xs: "left", md: "initial" },
          boxShadow: "none",
          padding: { xs: 2, md: 3 },
        }}
      >
        {loading && <Loading />}
        {!loading && (
          <Grid item xs={12}>
            <div className="container-da-tabela-responsiva">
              <TableContainer sx={{ overflowX: "auto", border: "1px solid #E9ECF5" }}>
                <Table sx={{ minWidth: '100%', tableLayout: 'fixed' }} aria-label="a subject table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" colSpan={4} style={{ height: "59px" }}>
                        <Typography
                          variant="h3"
                          gutterBottom
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {i18n.t("others.titlesub")}
                          <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                            <Box sx={{ width: { xs: "100%", sm: "300px" } }}>
                              <AutoSearch
                                placeholder={i18n.t("others.search")}
                                search={search}
                                setSearch={setSearch}
                                handler={() => setSearch("")}
                                sx={{ width: "100%" }}
                              />
                            </Box>
                            <Box sx={{ width: { xs: "100%", sm: "100px" }, marginLeft: { xs: 0, sm: "20px" } }}>
                              <Select
                                onChange={() => { }}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Page size",
                                }}
                                sx={{
                                  width: "80px",
                                  height: "40px",
                                }}
                              >
                                {[5, 10, 20, 50, 100].map((size) => (
                                  <MenuItem key={size} value={size}>
                                    {size}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                            <Box
                              sx={{
                                display: "flex", alignItems: "center", width: "120px", height: "40px", border: "1px solid #C3CAD9", marginRight: "20px", borderRadius: "5px",
                              }}
                            >
                              <FilterList
                                cardStyle={{
                                  position: "absolute",
                                  zIndex: "1",
                                  top: "140px",
                                  right: "323px",
                                }}
                                filters={[]}
                                setCanFilter={() => { }}
                                canFilter={false}
                              />
                              {i18n.t("others.filter")}
                            </Box>
                            <Box>
                              <Button
                                variant="outlined"
                                sx={{
                                  height: "35px",
                                  width: "80px",
                                  color: "#FFC107",
                                  backgroundColor: "#FFFFFF",
                                  borderColor: "#FFC107",
                                  "&:hover": {
                                    color: "#FFC107",
                                    backgroundColor: "#FFFFFF",
                                    borderColor: "#FFC107",
                                  },
                                }}
                                onClick={CreateSubject}
                              >
                                <AddBoxRounded />
                                {i18n.t("button.new")}
                              </Button>
                            </Box>
                          </Box>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow height="56px" sx={{ backgroundColor: "#F8FAFD" }}>
                      <StyledTableCell align="left">{i18n.t("others.name")}</StyledTableCell>
                      <StyledTableCell align="left">{i18n.t("others.Sigla")}</StyledTableCell>
                      <StyledTableCell align="left">{i18n.t("others.description")}</StyledTableCell>
                      <StyledTableCell align="center">{i18n.t("others.options")}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(search.trim() === "" ? incomingDate : searchResults).map((subj) => (
                      <TableRow key={subj.id}>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                          <Tooltip title={subj.name}>
                            <span>{subj.name}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">{subj.acronym}</TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                          <Tooltip title={subj.description}>
                            <span>{subj.description}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <ActionButtons
                            id={subj.id}
                            handleVisualize={handleDataToVisualize}
                            handleEdit={handleDataToedit}
                            handleDeleteItem={() => handleClickOpen(subj.id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        )}
      </Grid>

      {loading ? null : (
        <Grid sx={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
          <CustomPagination
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        </Grid>
      )}

      {deleteItemId && (
        <AlertDialogForConfirm
          id={deleteItemId}
          open={open}
          handleClose={handleClose}
          handleDeleteItem={handleDeleteItem}
        />
      )}
    </>
  );
}