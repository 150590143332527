import * as React from "react";
import { Grid, Typography } from "@mui/material";
import HandleUrl from "../../../utils/HandleUrl";
import { i18n } from "../../../translate/i18n";

import UploadButton from "../../../components/form-components/upload-button";
import { useState } from "react";




export default function Upload({setFiles, files, disabled, edit, id}) {

    let isDisabled = HandleUrl();




    return (
        <Grid container spacing={3} >

            <Grid item xs={6} marginBottom={2} >
                <Typography sx={{ color: "#003B76" }} variant="h3">{i18n.t("others.DocumentsAttached")}</Typography>
            </Grid>


            <Grid item xs={12} container spacing={1}>

                <Grid item xs={6.5} >
                    <UploadButton title="Anexar BILHETE DE IDENTIDADE" edit={edit}  id={id} apiEndpoint={`${process.env.REACT_APP_API_URL}/employee-attachment`}   entityParamFirst={true} set={setFiles} name="DocIdentification" files={files} disabled={isDisabled} />
                </Grid>

                <Grid item xs={6.5} >
                    <UploadButton title="Anexar NUMERO DE CONTA " edit={edit} id={id} set={setFiles} name="AccountNumber" files={files} apiEndpoint={`${process.env.REACT_APP_API_URL}/employee-attachment`}  entityParamFirst={true} disabled={isDisabled} />
                </Grid>

                <Grid item xs={6.5} >
                    <UploadButton title="Anexar CONTRACTO DE TRABALHO" edit={edit}  id={id} set={setFiles} name="WorkContract" files={files} apiEndpoint={`${process.env.REACT_APP_API_URL}/employee-attachment`}  entityParamFirst={true}  disabled={isDisabled} />
    
                </Grid>

                <Grid item xs={6.5} >
                    <UploadButton title="Anexar CERTIFICADO DE LICENCIATURA" edit={edit}  id={id} set={setFiles} name="DegreeCertificate" files={files} apiEndpoint={`${process.env.REACT_APP_API_URL}/employee-attachment`}   entityParamFirst={true}disabled={isDisabled} />
                </Grid>
                <Grid item xs={6.5} >
                    <UploadButton title="Anexar CERTIFICADO DE MESTRADO" edit={edit}  id={id} set={setFiles} name="MasterCertificate" files={files} apiEndpoint={`${process.env.REACT_APP_API_URL}/employee-attachment`}  entityParamFirst={true} disabled={isDisabled}/>
                </Grid>

            </Grid>

        </Grid>

    )

};