import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import iconVisualizar from '../../assets/iconVisualizar.svg';
import iconEDit from '../../assets/iconEDit.svg';
import iconDelet from '../../assets/iconDelet.svg';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import ArticleIcon from '@mui/icons-material/Article';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconeDocumento from "../../assets/listing_to_do_list_document_file_icon_147456.png" ;
import { Navigate } from "react-router-dom";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
function DisciplinasList() {
  const handleDetalhesClick = (id) => {
    navigate(`/disciplinass/${cursoId}`); 
};
  const [loading, setLoading] = useState(true);
  const [courseDetails, setCourseDetails] = useState(null);
  const [expandedYear, setExpandedYear] = useState('');

  const [expandedSemester, setExpandedSemester] = useState('');
  const { cursoId } = useParams();
  const [expandedAno, setExpandedAno] = useState('');
  const [expandedSemestre, setExpandedSemestre] = useState('');
  const [disciplinasData, setDisciplinasData] = useState([]);
  const navigate = useNavigate();
  const extrairNumero = (texto) => {
    if (texto === undefined || texto === null) {
      return null; // Retorna null se o valor for indefinido ou nulo
    }
    const match = String(texto).match(/\d+/); // Converte para string antes de aplicar .match
    return match ? parseInt(match[0], 10) : null; // Retorna o número ou null se não encontrar
  };
  
  const apiUrl = `${process.env.REACT_APP_API_URL}/classes`;

  const handleChangeAno = (panel) => (event, newExpanded) => {
    setExpandedAno(newExpanded ? panel : '');
  };
  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await axios.get(`/courses/${cursoId}`);
        setCourseDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar detalhes do curso:', error);
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [cursoId]);
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(apiUrl);
        const courses = response.data.data;
  
        const groupedData = courses.reduce((acc, item) => {
          console.log("veraaa",item);

          const { year, semester, subject, course} = item;
         
         

          const yearLabel = `${year}º Ano`;
          const semesterLabel = `${semester}º Semestre`;
  
          if (!acc[yearLabel]) {
            acc[yearLabel] = {};
          }
  
          if (!acc[yearLabel][semesterLabel]) {
            acc[yearLabel][semesterLabel] = {
              subjects: [],
              course, // Adiciona o `course` aqui para cada semestre
            };
          }
  
          acc[yearLabel][semesterLabel].subjects.push(subject.name);
  
  
          return acc;
        }, {});
  
        let formattedData = Object.entries(groupedData).map(([year, semesters]) => ({
          ano: year,
          anoOriginal: Object.values(semesters)[0].year, 
          cursos: Object.values(semesters).map((semData) => semData.course), // Obtém o nome do curso
          semestres: Object.entries(semesters).map(([semester, semData]) => ({
            semestre: semester,
            semestreOriginal: semData.semester,
            disciplinas: semData.subjects,
            curso: semData.course, // Inclui o nome do curso no semestre
          })),
        }));
  
        formattedData = formattedData.sort((a, b) => {
          const yearA = parseInt(a.ano.split('º')[0], 10); // Extrai o número do ano
          const yearB = parseInt(b.ano.split('º')[0], 10);
          return yearA - yearB; // Ordenação crescente
        });
  
        setDisciplinasData(formattedData);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar cursos:', error);
        setLoading(false);
      }
    };
  
    fetchCourses();
  }, [apiUrl]);
  if (loading || !courseDetails) {
    return <Typography>Carregando os Dados do Curso...</Typography>;
  }

  const totalNumberOfYears = courseDetails.totalNumberOfYears || 0;
  const classes = courseDetails.classes || [];
  const years = Array.from({ length: totalNumberOfYears }, (_, index) => index + 1);

  // Agrupar semestres por ano sem duplicação
  const groupedSemestersByYear = () => {
    const grouped = {};
    classes.forEach((classe) => {
      const { year, semester, subject } = classe;
      if (!grouped[year]) grouped[year] = {};
      if (!grouped[year][semester]) grouped[year][semester] = [];
      grouped[year][semester].push({ subject });
    });
    return grouped;
  };

  const groupedDisciplines = groupedSemestersByYear();

  const handleYearChange = (panel) => (event, isExpanded) => {
    setExpandedYear(isExpanded ? panel : '');
  };

  const handleSemesterChange = (semester) => {
    // Alterna entre expandir e desexpandir o semestre
    setExpandedSemester(semester === expandedSemester ? '' : semester);
  };


  return (
    <>
     <Box sx={{ 
      backgroundColor: "#FFFFFF", 
      marginRight:"10px",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", 
      padding: "20px", 
      marginTop: "-30px",  
      width: "1249px !important",
      top: "100px",  // Ajuste para controlar a posição vertical
 
      marginLeft: "-2px", 
      height:"10px",  // Ajuste para garantir que a altura seja dinâmica
      position: "absolute", // Posicionamento absoluto para ficar por cima
      zIndex: 1000, // Garante que a Box fique acima de outros elementos
    }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px", }}>
        <IconButton sx={{ 
          color: "#FBC02D", 
          marginRight: "0px", 
          display: "flex", 
          alignItems: "center", 
        }}
        onClick={() => navigate('/schedules')}

        >
          <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-5px " , marginTop: "-8px", }} />
          <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px " , marginTop: "-8px", }} />
        </IconButton>
        <Typography variant="h6" sx={{ 
          fontWeight: 400, 
          fontSize: "18px", 
          color: "#FBC02D", 
          marginRight: "10px", 
          marginTop: "-8px",
        }}>
          Voltar
        </Typography>
        <Typography variant="h6" sx={{ 
          fontWeight:4600, 
          fontSize: "18px", 
          color: "#8893A9" ,
          marginTop: "-8px",
          gap:"20px",
        }}>
          Horário
        </Typography>
      </Box>
    </Box>

    <Box
  sx={{
    backgroundColor: "#FFF",
    height: "100vh", 
    padding: "40px", 
    maxWidth: "13000px", 
    margin: "0 auto",
    marginTop: "-15px", 
  }}
>
    <div>
      <div
        style={{
          backgroundColor: '#F1F1E1B2',
          color: 'white',
          padding: '10px',
          width: '1,101px',
          height: '56px',
          top: '130px',
          left: '268px',
          gap: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <div
            style={{
              backgroundColor: '#FFC107',
              color: '#FFFFFF',
              width: '95px',
              height: '26px',
              margin: '0px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '2px',
              fontSize: '1rem',
              padding: '10px',
              gap: '2px',
            }}
          >
            Unidade curriculares
          </div>
        </div>
      </div>
      </div>


      {years.map((year) => (
        <Accordion
          key={year}
          expanded={expandedYear === `year-${year}`}
          onChange={handleYearChange(`year-${year}`)}
        >
          <AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls={`panel-${year}-content`}
  id={`panel-${year}-header`}
  sx={{
    backgroundColor: '#E4EBF5',
    '&:hover': { backgroundColor: '#D0DBE8' },
  }}
>
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '10px', 
    }}
  >
    <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                backgroundColor: '#E4EBF5',
                border: '2px solid black',
                marginRight: '8px',
              }}
            >
              <KeyboardArrowDownIcon
                sx={{ color: '#42474B', fontSize: '30px' }}
              />
            </div> 
               <Typography variant="h6">{`${year}º Ano`}</Typography>
               </Box>
                </AccordionSummary>

        
              <AccordionDetails>
            {groupedDisciplines[year] ? (
              Object.keys(groupedDisciplines[year]).map((semester) => (
                <Box key={semester} sx={{ mb: 2,  }}>
                  <Box
                    component="a"
                    href={`#semester-${semester}`}
                    onClick={() => handleSemesterChange(semester)}
                    sx={{
                      textDecoration: 'none',
                      cursor: 'pointer',
                      color: '#000000',
                    }}
                    
                  >
                      <Divider sx={{ mt: 1, color:"#E9ECF5" }} />
                      <Typography
                      variant="subtitle1"
                          sx={{
                                mb: 2,
                                backgroundColor: '#F7F9FC',
                                width: '1120px',
                                height: '60px',
                                paddingLeft: '20px',
                                display: 'flex',
                                marginLeft: '-17px',
                                marginTop: '-17px',
                                marginRight: '-17px',
                                alignItems: 'center',
                                gap: '10px', 
                                }}
  
                                >
                            <Divider sx={{ mt: 1, color:"#E9ECF5" }} />

               <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                backgroundColor: '#F7F9FC',
                border: '2px solid black',
                marginRight: '8px',
              }}
            >
              <KeyboardArrowDownIcon
                sx={{ color: '#42474B', fontSize: '30px',marginLeft: '-17px', marginRight: '-17px' }}
              />
              
            </div>  {`${Number(semester)}º semestre`}
                </Typography>
      <Divider
    sx={{
      mt: 1,
      borderColor: "#E9ECF5",
      marginTop:"-15px",
      marginLeft: '-17px', 
      marginRight: '-17px'
    }}
  />
                  </Box>
                 
                  {expandedSemester === semester && (
                    

                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop:"-50px" }}>
  {/* Ícone ao lado do botão */}

                <IconButton size="small" color="secondary"     onClick={() => navigate('/schedule-tables')}
 >
                      <img src={iconVisualizar} alt="Visualize" style={{ width: '18px', height: '18px', color: '#FFC107'[700] }} />
                </IconButton>

                <IconButton size="small" color="secondary"  onClick={() => {
                const yearNumber = extrairNumero(`${year}`); // Garante que year seja uma string
                const semesterNumber = extrairNumero(`${semester}`); // Garante que semester seja uma string
                console.log('Year:', yearNumber, 'Semester:', semesterNumber);
    
              if (yearNumber && semesterNumber) {
                console.log('Navegando para:', `/scheduless/${cursoId}/${yearNumber}/${semesterNumber}`);
               navigate(`/scheduless/${cursoId}/${yearNumber}/${semesterNumber}`);
                 } else {
               console.error('Erro: Os números de ano ou semestre são inválidos.');
                 }
                }} >
                    <img src={iconEDit} alt="Visualize" style={{ width: '17px', height: '17px', color: '#FFC107'[700] }} />
                </IconButton>

                <IconButton size="small" color="secondary" onClick={e => ("id")} >
                   <img src={iconDelet} alt="Visualize" style={{ width: '17px', height: '17px', color: '#FFC107'[700] }} />
                </IconButton>
  <div
    style={{
      width: '30px',
      height: '30px',
      backgroundColor: 'transparent',
      border: '3px solid #FFC107',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      marginRight: '5px',
    }}
  >
    <img
      src={IconeDocumento} // Use a variável importada
      alt="Ícone de Documento"
      style={{
        width: '20px',
        height: '20px',
        objectFit: 'contain',
        filter: 'brightness(0) saturate(100%) invert(78%) sepia(79%) saturate(283%) hue-rotate(1deg) brightness(103%)',
      }}
    />
  </div>
  <Divider sx={{ mt: 1, color:"#E9ECF5" }} />

  {/* Mostrar botão apenas se o semestre estiver expandido */}
    <Button
      variant="contained"
      size="small"
      sx={{
        backgroundColor: '#FFC107',
        color: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 10px',
        borderRadius: '8px',
        width: 'auto',
        '&:hover': {
          backgroundColor: '#FFB300',
        },
      }}
      onClick={() => {
        const yearNumber = extrairNumero(`${year}`); // Garante que year seja uma string
        const semesterNumber = extrairNumero(`${semester}`); // Garante que semester seja uma string
        console.log('Year:', yearNumber, 'Semester:', semesterNumber);
    
        if (yearNumber && semesterNumber) {
          console.log('Navegando para:', `/disciplinass/${cursoId}/${yearNumber}/${semesterNumber}`);
          navigate(`/disciplinass/${cursoId}/${yearNumber}/${semesterNumber}`);
        } else {
          console.error('Erro: Os números de ano ou semestre são inválidos.');
        }
      }}
           >
      Criar Horário
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '20px',
          height: '25px',
          borderRadius: '50%',
          backgroundColor: '#FFC107',
          border: '2px solid #FFFFFF',
          marginLeft: '10px',
        }}
      >
        <ArrowForwardIcon
          sx={{
            color: '#FFFFFF',
            fontSize: '16px',
          }}
        />
      </div>
    </Button>
</Box>
  )}
{expandedSemester === semester && (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column', 
      alignItems: 'center',
      justifyContent: 'center',
      height: "90px",
      marginTop: '20px', 
    }}
  >
    {/* Palavra Disciplina */}
    <Typography
      variant="body2"
      sx={{
        textAlign: 'center',
        color: '#333333',
        fontSize: '16px',
        marginRight: '1005px',
        marginTop: '10px',
        fontWeight: 600, 

        marginBottom: '-80px', 
      }}
    >
      Unidade curricular
    </Typography>

    {/* Linha abaixo */}
    <Divider
      sx={{
        width: 'calc(100% + 30px)', 
        borderColor: '#E9ECF5',
        marginTop: '95px',
        marginLeft: '-15px', 
        marginRight: '-15px', 
      }}
    />
  </Box>
)}




                  {expandedSemester === semester && groupedDisciplines[year][semester] ? (
                    groupedDisciplines[year][semester].map((discipline, idx) => (
                      <Box key={idx} sx={{ ml: 2, mt: 1, }}>
                        
  <Typography
    variant="body2"
    sx={{
      fontWeight: '', height:"1px",     top: "500000px",marginTop: "15px",  
    }}
  >
    {discipline.subject ? discipline.subject.name : 'Unidade curricular não informada'}
  </Typography>
  <Divider
    sx={{
      mt: 1,
      borderColor: "#E9ECF5", 
      marginLeft: '-33px', 
      marginRight: '-17px',
      marginTop:"40px"
    }}
  />
</Box>

                    ))
                  ) : (
                    <></>
                  )}
                </Box>
              ))
            ) : (
              <Typography>Sem informações disponíveis para este ano</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
    </>

  );
}

export default DisciplinasList;
