import { Alert, Button, Grid, Snackbar, Typography } from "@mui/material";
import "./styles/payment-analyse.css";
import UploadButton from "../../../components/form-components/upload-button";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import axios from "axios"; // Importe o axios aqui
import { useKeycloak } from "@react-keycloak/web";
const PaymentAnalyse = () => {
  const { keycloak } = useKeycloak();
  const { id } = useParams();
  const [files, setFiles] = useState({});
  const [nome, setNome] = useState(""); // Estado para o nome
  const [anoMatricula, setAnoMatricula] = useState(""); //
  const [documentNumber, setDocumentNumber] = useState("");
  const [entityId, setEntityId] = useState(null);
  const [status, setStatus] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isSenderLoading, setIsSenderLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: "", severity: "success" });
  const handleClick = (m, s) => {
    setToast({ message: m, severity: s });
    setTimeout(() => setOpen(true), 200);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const postInfo = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/enroll-payment`,
        {
          enrollId: id,
        },
        {
          headers: {
            Authorization: "Bearer " + keycloak.token, // token de autenticação desgraçado
          },
        }
      );

      // A resposta da API pode conter informações úteis, dependendo do seu caso
      console.log("Resposta da Requisição:", response);

      handleClick("Info Enviado Com Successo", "success");

      window.location.href = "/enrolls";
    } catch (error) {
      console.error("Erro ao Enviar Info", error);

      handleClick("Erro ao Enviar Info:", "error");
    } 
  };
  ///
  const uploadFiles = async (files, id, name, type) => {
    setIsSenderLoading(true);
    try {
      const formData = new FormData();
      formData.append("entityID", id);
      console.log(files);
      formData.append(name, files[name]); // O nome 'file' deve corresponder ao esperado pela API

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/enrollAttachment/${type}`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token, // token de autenticação desgraçado
          },

          "Content-Type": "multipart/form-data", // Importante para enviar arquivos
        }
      );

      // A resposta da API pode conter informações úteis, dependendo do seu caso
      console.log("Resposta da Requisição de Upload:", response);

      handleClick("Ficheiros Upploaded Com Successo", "success");
      if (type == "rup") postInfo(id);
      else window.location.href = "/enrolls";
    } catch (error) {
      console.error("Erro ao fazer upload do arquivo:", error);

      handleClick("Erro ao fazer upload do arquivo:", "error");
    } finally {
      setIsSenderLoading(false);
    }
  };

  ///
  const solveStatus = (val) => {
    switch (val) {
      case "AWAITING_PAYMENT":
        return { rupe: false, prof: true, note: true };
      case "RUPE_GENERATED":
        return { rupe: true, prof: false, note: true };
      case "PAYMENT_REJECTED":
        return { rupe: true, prof: true, note: true };
      case "PAID":
        return { rupe: true, prof: true, note: false };
      case "LIQUIDATED":
        return { rupe: true, prof: true, note: true };
      case "UNDER_ANALYSIS":
        return { rupe: true, prof: true, note: true };
      case "WAITING_FOR_STUDENT_INFO":
        return { rupe: true, prof: true, note: true };
      case "ENROLLED":
        return { rupe: true, prof: true, note: true };

      default:
        return { rupe: true, prof: true, note: true };
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${apiUrl}/enroll/${id}`, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        });
        const data = response.data;

        // Agora, você pode acessar os dados, como nome e ano da matrícula, a partir de 'data'
        setNome(data.student.name);
        setAnoMatricula(data.student.year);
        setDocumentNumber(data.student?.documentNumber);
        setStatus(data.status); // Suponha que o ano esteja em 'year'
        setEntityId(data.id);
        console.log(anoMatricula);
        // Faça o que for necessário com esses dados
        // Atualize o estado ou exiba as informações na página
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [id]);

  const NextButton = styled(Button)({
    backgroundColor: "#FFC107",
    color: "white",
    width: "100%",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FFCC39",
    },
  });

  const role = keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles;

  return (
    <div className="payment-analysis">
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={toast?.severity}
          sx={{ width: "100%" }}
        >
          {toast?.message}
        </Alert>
      </Snackbar>
      <Typography
        sx={{ paddingTop: "38px", paddingBottom: "30px", color: "#003B76" }}
        variant="h1"
      >
        Pagamento
      </Typography>
      <Typography
        sx={{ paddingTop: "0px", paddingBottom: "30px", color: "#003B76" }}
        variant="h3"
      >
        Dados da Matrícula
      </Typography>

      <div className="flex-wrap s-18 pb-22">
        <div className="w-240">
          <p className="ref">Nome:</p>
        </div>
        <div className="w-480">
          <p className="value">{nome}</p>
        </div>
      </div>

      <div className="flex-wrap s-18 pb-22">
        <div className="w-240">
          <p className="ref">Identificação:</p>
        </div>
        <div className="w-480">
          <p className="value">{documentNumber}</p>
        </div>
      </div>

      <div className="flex-wrap s-18 pb-69">
        <div className="w-240">
          <p className="ref">Ano de Matrícula:</p>
        </div>
        <div className="w-480">
          <p className="value">{anoMatricula}</p>
        </div>
      </div>

      <Typography
        sx={{ paddingTop: "0px", paddingBottom: "30px", color: "#003B76" }}
        variant="h3"
      >
        Anexos:
      </Typography>

      <div className="flex-wrap s-18 pb-22">
        <div className="w-240">
          <p className="ref">RUPE:</p>
        </div>
        <div className="w-480">
          <UploadButton
            files={files}
            id={entityId}
            set={setFiles}
            name="RupDoc"
            disabled={!role?.includes("Superadmin") && solveStatus(status)?.rupe}
            apiEndpoint={`${process.env.REACT_APP_API_URL}/enrollAttachment`}
            entityParamFirst={false}
          />
        </div>
      </div>

      <div className="flex-wrap s-18 pb-22">
        <div className="w-240">
          <p className="ref">Comprovante de pagamento:</p>
        </div>
        <div className="w-480">
          <UploadButton
            files={files}
            id={entityId}
            name="ConfirmationPayment"
            set={setFiles}
            disabled={!role?.includes("Superadmin") && solveStatus(status)?.prof}
            apiEndpoint={`${process.env.REACT_APP_API_URL}/enrollAttachment`}
            entityParamFirst={false}
          />
        </div>
      </div>

      <div className="flex-wrap s-18 pb-69">
        <div className="w-240">
          <p className="ref">Nota de Liquidação:</p>
        </div>
        <div className="w-480">
          <UploadButton
            files={files}
            id={entityId}
            name="Liquidation"
            set={setFiles}
            disabled={!role?.includes("Superadmin") && solveStatus(status)?.note}
            apiEndpoint={`${process.env.REACT_APP_API_URL}/enrollAttachment`}
            entityParamFirst={false}
          />
        </div>
      </div>

      <Grid container spacing={5}>
        <Grid item xs={10}></Grid>
        <Grid style={{ padding: 0, alignItems: "end" }} item xs={2}>
          <div
            className="rup-button"
            style={
              solveStatus(status)?.rupe &&
              solveStatus(status)?.prof &&
              solveStatus(status)?.note
                ? { display: "none" }
                : {}
            }
          >
            <NextButton
              variant="contained"
              onClick={() => {
                if (!solveStatus(status).rupe)
                  uploadFiles(files, id, "RupDoc", "rup");
                if (!solveStatus(status).prof)
                  uploadFiles(
                    files,
                    id,
                    "ConfirmationPayment",
                    "payment-confirmation"
                  );
                if (!solveStatus(status).note)
                  uploadFiles(files, id, "Liquidation", "accept-payment");
              }}
            >
              {isSenderLoading
                ? "Carregando"
                : !solveStatus(status).rune
                ? "Enviar"
                : !solveStatus(status).prof
                ? "Enviar"
                : !solveStatus(status).note
                ? "Enviar"
                : ""}
            </NextButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentAnalyse;
