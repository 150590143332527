import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import CustomPagination from "../../components/pagination";
import { i18n } from "../../translate/i18n";
import { useKeycloak } from "@react-keycloak/web";
import { format } from "date-fns";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import FilterList from "../../components/filterList/FilterList";
import { AddBoxRounded } from "@mui/icons-material";
import AlertDialogForConfirm from "../../components/confirmation/AlertDialog";
import Loading from "../../components/loading/loading";
import { truncateText } from "../../utils/functions/utilFunctions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8FAFD",
    color: "#939a9f",
  },
}));

const DepartmentListing = () => {
  const { keycloak } = useKeycloak();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [incomingDate, setIncomingDate] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [search, setSearch] = useState("");
  const pathUrl = "/departments";
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const searchDepartment = async (searchTerm) => {
      try {
        const response = await axios.get(
          `${apiUrl}/departments?search=${encodeURIComponent(searchTerm)}`,
          {
            headers: {
              Authorization: "Bearer " + keycloak.token,
            },
          }
        );

        setSearchResults(
          response.data?.data.map((depart) => ({
            ...depart,
            createdDate: formatarData(depart.createdDate),
          }))
        );

        return response.data;
      } catch (error) {
        console.error("Error searching departments:", error);
        throw error;
      }
    };

    const delaySearch = setTimeout(() => {
      if (search.trim() !== "") {
        searchDepartment(search);
      }
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [search, keycloak.token]);

  const getDepartmentListing = async () => {
    setLoading(true);
    if (!page) {
      setLoading(false);
      return;
    }

    const params = new URLSearchParams({
      page: page,
    });

    try {
      const response = await axios.get(
        `${apiUrl}/departments?` + params.toString(),
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );

      setIncomingDate(
        response.data?.data.map((depart) => ({
          ...depart,
          createdDate: formatarData(depart.createdDate),
        }))
      );
      setPage(response.data.page);
      setTotalPages(response.data.meta.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (keycloak.token) {
      getDepartmentListing();
    }
  }, [keycloak.token, page, search]);

  const formatarData = (data) => {
    return format(new Date(data), "dd/MM/yyyy");
  };

  const handleClickOpen = (id) => {
    setDeleteItemId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setDeleteItemId(null);
    setOpen(false);
  };

  const CreateDepart = () => {
    navigate("/departments/create");
  };

  const handleDisplayDataToVisualize = (id) => {
    navigate(`${pathUrl}/${id}?disabled=true`);
  };

  const handleDisplayDataToedit = (id) => {
    navigate(`/departments/${id}/edit`);
  };

  const handleDeleteItem = async (id) => {
    try {
      const response = await axios.delete(`${apiUrl}/departments/${id}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      if (response.status === 200) {
        setIncomingDate(
          incomingDate.filter((incomingDate) => incomingDate.id !== id)
        );
      } else {
        console.error(
          "Failed to delete employee. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
    } finally {
      handleClose(); // Move handleClose to the end to ensure it's called even if there's an error
    }
  };

  return (
    <>
      <Grid
        container
        component={Paper}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "relative",
          boxShadow: "none",
          padding: { xs: 2, md: 3 },
        }}
      >
        {loading && <Loading />}
        {!loading && (
          <Grid item xs={12}>
            <div className="container-da-tabela-responsiva">
              <TableContainer
                sx={{ overflowX: "auto", border: "1px solid #E9ECF5" }}
              >
                <Table sx={{ minWidth: '100%', tableLayout: 'fixed' }} aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        colSpan={5}
                        style={{ height: "59px" }}
                      >
                        <Typography
                          variant="h3"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {i18n.t("department.title")}
                          <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                            <Box sx={{ width: { xs: "100%", sm: "300px" } }}>
                              <AutoSearch
                                placeholder={i18n.t("others.search")}
                                search={search}
                                setSearch={setSearch}
                                handler={() => setSearch("")}
                                sx={{ width: "100%" }}
                              />
                            </Box>

                            <Box sx={{ width: { xs: "100%", sm: "100px" }, marginLeft: { xs: 0, sm: "20px" } }}>
                              <Select
                                onChange={(e) => {}}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Page size",
                                }}
                                sx={{
                                  width: "80px",
                                  height: "40px",
                                }}
                              >
                                {[5, 10, 20, 50, 100].map((size) => (
                                  <MenuItem key={size} value={size}>
                                    {size}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "120px",
                                height: "40px",
                                border: "1px solid #C3CAD9",
                                marginRight: "20px",
                                borderRadius: "5px",
                              }}
                            >
                              <FilterList
                                cardStyle={{
                                  position: "absolute",
                                  zIndex: "1",
                                  top: "140px",
                                  right: "323px",
                                }}
                                filters={[]}
                                setCanFilter={() => {}}
                                canFilter={false}
                              />
                              {i18n.t("others.filter")}
                            </Box>

                            <Box>
                              <Button
                                variant="outlined"
                                sx={{
                                  height: "35px",
                                  width: "80px",
                                  color: "#FFC107",
                                  backgroundColor: "#FFFFFF",
                                  borderColor: "#FFC107",
                                  "&:hover": {
                                    color: "#FFC107",
                                    backgroundColor: "#FFFFFF",
                                    borderColor: "#FFC107",
                                  },
                                }}
                                onClick={CreateDepart}
                              >
                                <AddBoxRounded />
                                {i18n.t("button.new")}
                              </Button>
                            </Box>
                          </Box>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ backgroundColor: "#F8FAFD", height: "56px", color: "#939a9f" }}>
                      <StyledTableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.name")}</Typography></StyledTableCell>
                      <StyledTableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.Sigla")}</Typography></StyledTableCell>
                      <StyledTableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.description")}</Typography></StyledTableCell>
                      <StyledTableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.date")}</Typography></StyledTableCell>
                      <StyledTableCell align="center"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.options")}</Typography></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(search.trim() === "" ? incomingDate : searchResults).map((depart) => (
                      <TableRow key={depart.id}>
                        <TableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> {truncateText(depart.name, 45)}</Typography></TableCell>
                        <TableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> {depart.acronym}</Typography></TableCell>
                        <TableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> {truncateText(depart.description, 45)}</Typography></TableCell>
                        <TableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> {depart.createdDate}</Typography></TableCell>
                        <TableCell align="center"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> 
                          <ActionButtons
                            id={depart.id}
                            handleVisualize={handleDisplayDataToVisualize}
                            handleEdit={handleDisplayDataToedit}
                            handleDeleteItem={() => handleClickOpen(depart.id)}
                          />
                       </Typography> </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        )}
      </Grid>

      {!loading && (
        <Grid sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <CustomPagination
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        </Grid>
      )}

      {deleteItemId && (
        <AlertDialogForConfirm
          id={deleteItemId}
          open={open}
          handleClose={handleClose}
          handleDeleteItem={handleDeleteItem}
        />
      )}
    </>
  );
};

export default DepartmentListing;