import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Form, Formik } from "formik";
import * as React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "../../components/form-components/select";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import HandleUrl from "../../utils/HandleUrl";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { yellow } from "@mui/material/colors";
import {
  getErrorMessage,
  handleFailure,
} from "../../utils/functions/utilFunctions";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function EditExamsPage() {
  let isDisabled = true;
  const isViewPage = HandleUrl();
  const [course, setCourse] = useState([]);
  const [genders, setGenders] = useState([]);
  const [observations, setObservations] = useState([
    {
      name: "Novo estudante",
      id: "NOVO ESTUDANTE",
    },
    {
      name: "Repetente",
      id: "REPETENTE",
    }
  ]);
  let oneGender = "";

  const [gender, setGender] = useState();
  const [schoolYear, setSchoolYear] = useState([]);
  const [graduations, setGraduations] = useState([]);
  const [accessExam, setAcessExam] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [resultList, setResultList] = useState([]);

  const [studentsEvaluated, setStudentsEvaluated] = useState([]);
  const { keycloak } = useKeycloak();
  const [editRow, setEditRow] = useState(null);
  const navigate = useNavigate();
  const [processNumber, setProcessNumber] = useState();
  const [studentName, setStudentName] = useState();
  const [results, setResults] = useState();

  const [status, setStatus] = useState({
    canClose: false,
    isLoading: false,
  });

  let setTextBox = [i18n.t("messages.createTitle"), ""];

  const [toast, setToast] = useState({ message: "", severity: "success" });
  const [open, setOpen] = useState(false);
  const handleClick = (m, s) => {
    setToast({ message: m, severity: s });
    setTimeout(() => setOpen(true), 200);
  };
  const handleEditChange = (index, field, value) => {
    const updatedStudentsEvaluated = [...studentsEvaluated];
    if (field === "processNumber") {
      // Certifique-se de que "processNumber" seja um número
      updatedStudentsEvaluated[index][field] = parseInt(value);
    } else {
      updatedStudentsEvaluated[index][field] = value;
    }
    setStudentsEvaluated(updatedStudentsEvaluated);
    console.log(updatedStudentsEvaluated[index]);
  };
  const fetchUpdatedData = () => {
    axios
      .get(`accessExams/${id.id}`)
      .then((response) => {
        setStudentsEvaluated(response.data?.studentsEvaluated);
        console.log(
          "Estudantes avaliados atualizados:",
          response.data?.studentsEvaluated
        );
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleSaveEdit = (index) => {
    const studentToUpdate = studentsEvaluated[index];

    // Verifique se processNumber é um número
    const processNumber = parseInt(studentToUpdate.processNumber);

    if (isNaN(processNumber)) {
      console.error("processNumber não é um número válido.");
      // Lidar com o erro ou apresentar uma mensagem de erro ao usuário, se necessário.
      return;
    }

    // Remover a propriedade "id" do objeto
    const { id, ...studentData } = studentToUpdate;

    // Extrair apenas o ID do gênero
    const genderId = studentToUpdate.gender.id;

    // Montar o objeto da solicitação
    const updatedStudent = {
      ...studentData,
      gender: genderId || studentToUpdate.gender, // Enviar apenas o ID do gênero
      processNumber, // Enviar o processNumber como um número
    };
    // ... código de atualização ...
    console.log("Valor de gender após a atualização: ", updatedStudent.gender);

    axios
      .put(`student-evaluated/${studentToUpdate.id}`, updatedStudent)
      .then((response) => {
        console.log("Estudante atualizado com sucesso:", response);

        fetchUpdatedData();
        console.log("Student updated successfully:", response);

        setEditRow(null);
      })
      .catch((error) => {
        console.error("Erro ao atualizar o estudante:", error);
      });
  };

  const handleDeleteRow = (index) => {
    try {
      const studentToDelete = studentsEvaluated[index];

      console.log("Estudante a ser excluído:", studentToDelete);

      axios
        .delete(`student-evaluated/${studentToDelete.id}`, {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        })
        .then((response) => {
          console.log("Estudante excluído com sucesso:", response);

          fetchUpdatedData();
          console.log("Student deleted successfully:", response);
        })
        .catch((error) => {
          console.error("Erro ao excluir o estudante:", error);
        });
    } catch (error) {
      console.error("Erro ao excluir o estudante:", error);
    }
  };

  let id = useParams();

  const INITIAL_FORM_STATE = {
    schoolYear: accessExam?.schoolYear,
    graduation: accessExam?.graduation?.id,
    NumberOfVacancies: accessExam?.NumberOfVacancies,
    numberOfCandidates: studentsEvaluated?.length,
    coordinator: accessExam?.coordinator,
    course: accessExam?.course?.id,
  };
  console.log("INITIAL_FORM_STATE", INITIAL_FORM_STATE);

  const SECOND_FORM_STATE = {
    processNumber: processNumber,
    studentName: studentName,
    gender: gender,
    grade: results,
  };

  const FORM_VALIDATION = Yup.object().shape({
    graduation: Yup.string().required(i18n.t("messages.required")),
    schoolYear: Yup.string().required(i18n.t("messages.required")),
    coordinator: Yup.string().required(i18n.t("messages.required")),
    NumberOfVacancies: Yup.string().required(i18n.t("messages.required")),
    numberOfCandidates: Yup.string().required(i18n.t("messages.required")),
    course: Yup.string().required(i18n.t("messages.required")),
  });

  const SECOND_FORM_VALIDATION = Yup.object().shape({
    processNumber: Yup.string().required(i18n.t("messages.required")),
    studentName: Yup.string().required(i18n.t("messages.required")),
    gender: Yup.string().required(i18n.t("messages.required")),
    grade: Yup.number()
      .typeError("Resultado tem que ser um número")
      .min(0)
      .max(20, "Resultado tem que ser entre 0 e 20")
      .typeError("")
      .required(i18n.t("messages.required")),
  });

  useEffect(() => {
    const requests = [
      axios.get("/curricular-Plan"),
      axios.get("/gender"),
      axios.get(`/accessExams/${id?.id}`),
      axios.get("/departments"),
      axios.get("/graduations"),
      axios.get("/departments"),

    ];
    axios.all(requests).then((responses) => {
      setAcessExam(responses[2].data);
      setCourse(
        responses[0].data?.data.map((c) => ({
          name: c.course?.name,
          id: c.course?.id,
        }))
      );
      console.log(responses[1].data, "GENDERS")
      setGenders(responses[1].data);
      setSchoolYear(responses[2].data?.schoolYear);
      setStudentsEvaluated(responses[2].data?.studentsEvaluated);
      setDepartments(responses[5]?.data?.data);
      setGraduations(responses[4].data?.data);
      console.log(responses[2].data);
    });
  }, []);

  function createExamedStudents(values, setValues, resetForm) {
    try {
      setStatus({ ...status, isLoading: true });

      const formData = {
        accessExamId: id.id,
        processNumber: parseInt(values.processNumber),
        studentName: values.studentName,
        gender: values.gender,
        grade: values.grade ? parseFloat(values.grade) : "N/B",
        observation: values.observation, // Inclua a observação aqui

      };

      axios
        .post("student-evaluated", formData, {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        })
        .then(function (response) {
          console.log("Candidato adicionado com sucesso:", response);
          if (response && response?.status <= 201) {
            setGender("");
            setProcessNumber("");
            setStudentName("");
            setResults("");

            // Busca os dados atualizados do servidor
            fetchUpdatedData();
            // Exibe mensagem de sucesso
            handleClick("Candidato adicionado com sucesso!", "success");

            // Reseta os campos do formulário
            resetForm();

            setStatus({ ...status, isLoading: false, canClose: true });
          } else {
            setTextBox = [i18n.t("messages.wrong"), handleFailure(response)]
            setStatus({ ...status, isLoading: false, canClose: true });
          }

        })
        .catch(function (error) {
          console.log("DEU PAU AQUI")
          console.error(error);
          let message = error?.response?.data?.message?.message;
          if (
            message === "Student with this process number is already evaluated"
          ) {
            message =
              "Candidato com este número de teste de acesso já se encontra matriculado";
          }
          setTextBox = [i18n.t("messages.wrong"), message || getErrorMessage(error)];
          setStatus({ ...status, isLoading: false, canClose: true });
          handleClick(message, "error");
        });
    } catch (error) {
      console.error(error);
      console.log("DEU PAU AQUI")
      setTextBox = [i18n.t("messages.wrong"), getErrorMessage(error)]
      setStatus({ ...status, isLoading: false, canClose: true });
    }
  }


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={toast?.severity} sx={{ width: "100%" }}>
          {toast?.message}
        </Alert>
      </Snackbar>
      <Box sx={{
        backgroundColor: "#FFFFFF",
        marginRight: "10px",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        padding: "20px",
        marginTop: "-30px",
        width: "1250px !important",
        top: "100px",

        marginLeft: "-5px",
        height: "10px",
        position: "absolute",
        zIndex: 1000,
      }}>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px", }}>
          <IconButton sx={{
            color: "#FBC02D",
            marginRight: "0px",
            display: "flex",
            alignItems: "center",
          }}
            onClick={() => navigate('/exams')}

          >
            <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-5px ", marginTop: "-8px", }} />
            <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px ", marginTop: "-8px", }} />
          </IconButton>
          <Typography variant="h6" sx={{
            fontWeight: 400,
            fontSize: "18px",
            color: "#FBC02D",
            marginRight: "10px",
            marginTop: "-8px",
          }}>
            Voltar
          </Typography>
          <Typography variant="h6" sx={{
            fontWeight: 4600,
            fontSize: "18px",
            color: "#8893A9",
            marginTop: "-8px",
            gap: "20px",
          }}>
            Candidato
          </Typography>
        </Box>
      </Box>

      <div
        style={{
          backgroundColor: "white",
          padding: "80px",
          paddingTop: "55px",
        }}
      >
        {accessExam ? (
          <Formik
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values) => {
              setResultList([...resultList, values.numberOfCandidates]);
              values.numberOfCandidates = "";
            }}
            enableReinitialize
          >
            <Form>
              <Typography variant="h3" style={{ color: "#003B76", marginBottom: "20px" }}>
                {i18n.t("ExamsList.editExms")}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={5}>
                  <Select
                    name="course"
                    value={accessExam?.course?.id}
                    options={course}
                    label={i18n.t("others.course")}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Textfield
                    name="schoolYear"
                    options={schoolYear}
                    label={i18n.t("others.schoolYear")}
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ marginTop: "20px" }}>
                <Grid item xs={12} sm={6} md={5}>
                  <Select
                    name="graduation"
                    value={accessExam?.graduation?.id}
                    options={graduations}
                    label={i18n.t("others.graduation")}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Textfield
                    name="coordinator"
                    label={i18n.t("others.coordinator")}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={4.7}>
                      <Textfield
                        name="numberOfVacancies"
                        value={accessExam?.numberOfVacancies}
                        label={i18n.t("others.NumberOfVacancies")}
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={6} md={4.7}>
                      <Textfield
                        name="numberOfCandidates"
                        value={studentsEvaluated?.length}
                        label={i18n.t("others.numberOfCandidates")}
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        ) : null}
      </div>

      <br />
      <div
        style={{
          backgroundColor: "white",
          paddingTop: "8px",
          padding: "20px",
          width: "100%",
          boxSizing: "border-box",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            paddingTop: "8px",
            padding: "20px",
            width: "100%",
            boxSizing: "border-box",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(233, 236, 245, 1)",
              padding: "20px",
              border: "1px solid #E9ECF5",
              borderRadius: "4px",
            }}
          >
            <Formik
              initialValues={{
                processNumber: "",
                studentName: "",
                gender: "",
                grade: "",
                observation: "",
              }}
              validationSchema={Yup.object().shape({
                processNumber: Yup.string().required(i18n.t("messages.required")),
                studentName: Yup.string().required(i18n.t("messages.required")),
                gender: Yup.string().required(i18n.t("messages.required")),
                grade: Yup.string()
                  .matches(/^(N\/B|\d{1,2})$/, "Digite um número válido ou N/B")
                  .optional(),
                observation: Yup.string().required(i18n.t("messages.required")),
              })}
              onSubmit={(values, { setValues, resetForm }) => {
                createExamedStudents({
                  ...values,
                  grade: values.grade.trim() === "" ? "N/B" : values.grade,
                }, setValues, resetForm);
              }}
              enableReinitialize
            >
              <Form>
                <Grid container spacing={2}>
                  <Grid item sm={12} lg={2} md={5} xs={2}>
                    <Textfield
                      name="processNumber"
                      label={i18n.t("others.examTestNo")}
                      disabled={isViewPage}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} lg={2} md={5} xs={2}>
                    <Textfield
                      name="studentName"
                      label="Nome do Estudante"
                      disabled={isViewPage}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} lg={2} md={5} xs={2}>
                    <Select
                      name="gender"
                      options={genders}
                      defaultValue={oneGender}
                      label={i18n.t("others.Sex")}
                      disabled={isViewPage}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} lg={2} md={5} xs={2}>
                    <Select
                      name="observation"
                      options={observations}
                      defaultValue={""}
                      label={i18n.t("student.studentStatus")}
                      disabled={isViewPage}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    />
                    {/*  <Textfield 
        name="observation" 
        label="Observação" 
        disabled={isViewPage} 
        InputProps={{ disableUnderline: true }} 
        sx={{
          "& .MuiInputBase-root": {
            border: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }} 
      /> */}
                  </Grid>
                  <Grid item sm={12} lg={2} md={5} xs={2}>
                    <Textfield
                      name="grade"
                      label={i18n.t("others.Results")}
                      disabled={isViewPage}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} lg={2} md={5} xs={2}>
                    <Button
                      style={{
                        width: "100%",
                        height: "30px",
                        padding: "5px",
                        backgroundColor: "#FFC107",
                        color: "white",
                        marginTop: "15px",
                        border: "none",
                        borderRadius: "3px",
                        cursor: isViewPage ? "default" : "pointer",
                      }}
                      type="submit"

                      disabled={isViewPage || status.isLoading}
                    >
                      {status.isLoading ? i18n.t("messages.loading") : i18n.t("button.add")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>

            <Grid container component={Paper} sx={{ boxShadow: "none", marginTop: "20px" }}>
              <Grid item xs={12}>
                <TableContainer
                  sx={{
                    overflowX: "auto",
                    border: "1px solid #E9ECF5",
                  }}
                >
                  <Table
                    sx={{
                      minWidth: "100%",
                      tableLayout: "fixed",
                    }}
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>{i18n.t("others.examTestNo")}</TableCell>
                        <TableCell align="center">{i18n.t("others.name")}</TableCell>
                        <TableCell align="center">{i18n.t("others.Sex")}</TableCell>
                        <TableCell align="center">{i18n.t("student.studentStatus")}</TableCell>
                        <TableCell align="center">{i18n.t("others.Results")}</TableCell>
                        <TableCell align="center">{i18n.t("course.actions")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentsEvaluated
                        .sort((a, b) => a.processNumber - b.processNumber)
                        .map((data, index) => (
                          <TableRow key={data.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              <Typography>
                                {editRow === index ? (
                                  <input
                                    type="number"
                                    value={data.processNumber}
                                    onChange={(e) => handleEditChange(index, "processNumber", e.target.value)}
                                    style={{ fontSize: "inherit" }}
                                  />
                                ) : (
                                  data.processNumber
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell align="center">
                              <Tooltip title={data.studentName}>
                                <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px" }, fontWeight: 500, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                  {editRow === index ? (
                                    <input
                                      type="text"
                                      value={data.studentName}
                                      onChange={(e) => handleEditChange(index, "studentName", e.target.value)}
                                      style={{ fontSize: "inherit" }}
                                    />
                                  ) : (
                                    data.studentName
                                  )}
                                </Typography>
                              </Tooltip>
                            </TableCell>

                            <TableCell align="center">
                              <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px" }, fontWeight: 500 }}>
                                {editRow === index ? (
                                  <select
                                    style={{ width: "100%", height: "20px", fontSize: "inherit" }}
                                    name="gender"
                                    options={genders}
                                    defaultValue={data.gender?.id}
                                    label={i18n.t("others.Sex")}
                                    value={data.gender?.id}
                                    onChange={(e) => handleEditChange(index, "gender", e.target.value)}
                                  >
                                    {genders.map((gender) => (
                                      <option key={gender.id} value={gender.id}>
                                        {gender?.name}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  data.gender?.name
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell align="center">
                              <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "12px", lg: "14px" }, fontWeight: 500 }}>
                                {editRow === index ? (
                                  <input
                                    type="text"
                                    value={data.observation}
                                    onChange={(e) => handleEditChange(index, "observation", e.target.value)}
                                    style={{ fontSize: "inherit" }}
                                  />
                                ) : (
                                  data.observation || ""
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell align="center">
                              {editRow === index && !isViewPage ? (
                                <input
                                  type="number"
                                  value={data.grade || ""}
                                  onChange={(e) => handleEditChange(index, "grade", parseFloat(e.target.value))}
                                  style={{ fontSize: "inherit" }}
                                />
                              ) : (
                                <span style={{ width: "100%" }}>{data.grade || ""}</span>
                              )}
                            </TableCell>

                            <TableCell align="center">
                              {!isViewPage && editRow === index ? (
                                <IconButton onClick={() => handleSaveEdit(index)}>
                                  <SaveIcon sx={{ color: yellow[700] }} fontSize="small" />
                                </IconButton>
                              ) : (
                                <div style={{ alignSelf: "end" }}>
                                  <IconButton onClick={() => setEditRow(index)}>
                                    <EditIcon sx={{ color: yellow[700] }} fontSize="small" />
                                  </IconButton>
                                  <IconButton size="small" color="secondary" onClick={() => handleDeleteRow(index)}>
                                    <DeleteIcon sx={{ color: yellow[700] }} fontSize="small" />
                                  </IconButton>
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}