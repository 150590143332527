import { Box, Grid, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import Select from "../../components/form-components/select";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import HandleUrl from "../../utils/HandleUrl";
import { MainButton } from "../../components/main-button/MainButton";
import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import { createItem as create } from "../../utils/functions/formCRUDFunctions";
import { Navigate, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function CreateExamsPage() {
  const isDisabled = HandleUrl();
  const [course, setCourse] = useState([]);
  let schoolYear = "";

  const [graduation, setGraduation] = useState([]);
  const [numberOfCandidates, setNumberOfCandidates] = useState();
  const [numberOfVacancies, setNumberOfVacancies] = useState();
  const navigate = useNavigate();

  const [status, setStatus] = useState({
    isLoading: true,
  });

  const [textBox, setTextBox] = useState([i18n.t("messages.createTitle"), ""]);
  const INITIAL_FORM_STATE = {
    department: "",
    schoolYear: "",
    graduation: "",
    numberOfVacancies: numberOfVacancies,
    numberOfCandidates: numberOfCandidates,
    coordinator: "",
    course: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    graduation: Yup.string().required(i18n.t("messages.required")),
    schoolYear: Yup.string().required(i18n.t("messages.required")),
    coordinator: Yup.string().required(i18n.t("messages.required")),
    numberOfVacancies: Yup.string().required(i18n.t("messages.required")),
    numberOfCandidates: Yup.string().optional(),
    course: Yup.string().required(i18n.t("messages.required")),
  });

  const { keycloak } = useKeycloak();
  const apiUrl = process.env.REACT_APP_API_URL;

  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList("graduations", setGraduation);
    getList("curricular-plan", setCourse);
  }, []);

  const formatFormData = (values) => ({
    schoolYear: values.schoolYear,
    graduation: values.graduation,
    department: values.department,
    coordinator: values.coordinator,
    numberOfVacancies: parseInt(values.numberOfVacancies),
    course: values.course,
  });

  const createItem = create(
    formatFormData,
    `${apiUrl}/accessExams`,
    setTextBox,
    setStatus,
    keycloak
  );

  return (
    <>
      <Box sx={{
        backgroundColor: "#FFFFFF",
        marginRight: "10px",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        padding: "10px", // Reduzido o padding
        marginTop: "-30px",
        width: { xs: "90%", sm: "75%" }, // Responsivo: 90% em telas pequenas, 75% em médias
        top: "100px",
        marginLeft: "auto", // Centraliza horizontalmente
        marginRight: "auto", // Centraliza horizontalmente
        height: "auto", // Mantido para altura dinâmica
        minHeight: "30px", // Altura mínima para manter o layout
        position: "absolute",
        zIndex: 1000,
      }}>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
          <IconButton sx={{
            color: "#FBC02D",
            marginRight: "0px",
            display: "flex",
            alignItems: "center",
          }}
            onClick={() => navigate('/exams')}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px", marginTop: "-8px", transform: "translateY(21px)" }} />
            <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px", marginTop: "-8px", transform: "translateY(21px)" }} />
          </IconButton>
          <Typography variant="h6" sx={{
            fontWeight: 400,
            fontSize: "18px",
            color: "#FBC02D",
            transform: "translateY(21px)",
            marginRight: "10px",
            marginTop: "-8px",
          }}>
            Voltar
          </Typography>
          <Typography variant="h6" sx={{
            fontWeight: 4600,
            fontSize: "18px",
            color: "#8893A9",
            marginTop: "-8px",
            transform: "translateY(21px)",
            gap: "20px",
          }}>
            {i18n.t("sidebar.Exams")}
          </Typography>
        </Box>
      </Box>
      <div style={{ backgroundColor: "white", padding: "80px", paddingTop: "55px" }}>
        <Formik
          initialValues={{ ...INITIAL_FORM_STATE }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {
            createItem(values);
          }}
          enableReinitialize
        >
          <Form>
            <Typography variant="h3" style={{ color: "#003B76" }}>
              {i18n.t("button.exams")}
            </Typography>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Select
                  name="course"
                  options={course.filter(courseFilter => courseFilter.course).map((item) => ({
                    name: item?.course?.name ?? "",
                    id: item?.course?.id ?? "",
                  }))}
                  label={i18n.t("others.course")}
                  disabled={isDisabled}
                />
              </Grid>

              <Grid item xs={5}>
                <Textfield
                  name="schoolYear"
                  options={schoolYear}

                  label={i18n.t("others.schoolYear")}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Select
                  name="graduation"
                  options={graduation}
                  label={i18n.t("others.graduation")}
                  disabled={isDisabled}
                />
              </Grid>

              <Grid item xs={5}>
                <Textfield
                  name="coordinator"
                  label={i18n.t("others.coordinator")}
                  disabled={isDisabled}
                />
              </Grid>

              <Grid item xs={8} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={4.7}>
                    <Textfield
                      name="numberOfVacancies"
                      label={i18n.t("others.NumberOfVacancies")}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={10}>
                <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                  <MainButton url="/exams" name={i18n.t("button.cancel")} />
                  <AlertDialog
                    show={true}
                    button={i18n.t("button.register")}
                    head={textBox[0]}
                    body={textBox[1] || <FallBackLoader isLoading={status.isLoading} />}
                    goBack={i18n.t("ExamsList.title")}
                    urlGoBack="/exams"
                    stay={i18n.t("ExamsList.titleCreate")}
                    urlStay="/exams/create"
                    isValid={true}
                    type="submit"
                  />
                </div>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </div>
    </>
  );
}