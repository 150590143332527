import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import HandleUrl from "../../utils/HandleUrl";
import { MainButton } from "../../components/main-button/MainButton";
import { useEffect } from "react";
import { useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import { getItem as get } from "../../utils/functions/tableCRUDFunctions";
import {
  createItem as create,
  updateItem as update,
} from "../../utils/functions/formCRUDFunctions";

export default function RoleForm() {
  const isDisabled = HandleUrl();

  const { keycloak } = useKeycloak();
  let page =1;
  const apiUrl = `${process.env.REACT_APP_API_URL}/employee-role`;
  const baseRouteUrl = "/cargo";
  const [INITIAL_FORM_STATE, setINITIAL_FORM_STATE] = useState({
    name: "",
    description: "",
    id: "",
  });
  const { id } = useParams();
  const isUpdate = !!id;
  const navigate = useNavigate(); // Hook para navegação


  const [status, setStatus] = useState({
    isLoading: true,
  });
  console.log(INITIAL_FORM_STATE)
  const [textBox, setTextBox] = useState([
    i18n.t(id ? "messages.updateTitle" : "messages.createTitle"),
    "",
  ]);
  const FORM_VALIDATION = Yup.object().shape({
    description: Yup.string().required(i18n.t("messages.required")),
    name: Yup.string().required(i18n.t("messages.required")),
    id: Yup.string().optional(),
  });

  const getItem = get(
    `${apiUrl}/${id}`,
    keycloak,
    setINITIAL_FORM_STATE,
    undefined,
    page
  );
  useEffect(() => {
    if (id) {
      getItem();
    }
  }, [id]);

  const formatFormData = (values) => ({
    name: values.name,
    description: values.description,
  });

  const createItem = create(formatFormData, apiUrl, setTextBox, setStatus, keycloak, "Cargo criado com sucesso!");
  const updateItem = update(formatFormData, apiUrl, setTextBox, setStatus, keycloak, "Cargo atualizado com sucesso!");
  return (
    <>
    <Box sx={{
    backgroundColor: "#FFFFFF",
    marginRight: "10px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    padding: "10px",
    marginTop: "-30px",
    width: { xs: "100%", sm: "100%", md: "80%", lg: "75%" }, // Aumentando a largura
    top: "100px",
    marginLeft: "auto", 
    height: "auto",  
    minHeight: "30px", 
    position: "absolute",
    zIndex: 1000,
}}>
    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <IconButton sx={{
            color: "#FBC02D",
            marginRight: "0px",
            display: "flex",
            alignItems: "center",
        }}
        onClick={() => navigate('/cargo')}
        >
            <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px", marginTop: "-8px", transform: "translateY(21px)" }} />
            <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px", marginTop: "-8px", transform: "translateY(21px)" }} />
        </IconButton>
        <Typography variant="h6" sx={{
            fontWeight: 400,
            fontSize: "18px",
            color: "#FBC02D",
            transform: "translateY(21px)",
            marginRight: "10px",
            marginTop: "-8px",
        }}>
            Voltar
        </Typography>
        <Typography variant="h6" sx={{
            fontWeight: 4600,
            fontSize: "18px",
            color: "#8893A9",
            marginTop: "-8px",
            transform: "translateY(21px)",
            gap: "20px",
        }}>
Cargo
        </Typography>
    </Box>
    </Box>
   <div
  style={{
    backgroundColor: "white",
    padding: "40px", 
   }}
   >
  <Formik
    initialValues={{
      ...INITIAL_FORM_STATE,
    }}
    validationSchema={FORM_VALIDATION}
    onSubmit={(values) => {
      if (id) {
        updateItem(values);
      } else {
        createItem(values);
      }
    }}
    enableReinitialize
  >
    {({ values }) => (
      <Form>
        <Typography variant="h1">
          {isUpdate ? values.name : i18n.t("role.titleCreate")}
        </Typography>
        <br />

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}> {/* Ajustado para ser responsivo */}
            <Textfield
              name="name"
              label={i18n.t("others.name")}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}> {/* Ajustado para ser responsivo */}
            <Textfield
              name="description"
              label={i18n.t("others.description")}
              disabled={isDisabled}
              multiline
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "20px", // Ajustado para um espaçamento mais compacto
              gap: "10px",
            }}
          >
            <MainButton
              url={baseRouteUrl}
              name={i18n.t("button.cancel")}
            />
            <AlertDialog
              button={
                id ? i18n.t("button.update") : i18n.t("button.register")
              }
              goBack={i18n.t("role.title")}
              head={textBox[0]}
              body={
                textBox[1] || (
                  <FallBackLoader isLoading={status.isLoading} />
                )
              }
              urlGoBack={baseRouteUrl}
              stay={
                id ? i18n.t("others.role") : i18n.t("role.titleCreate")
              }
              urlStay={
                id ? `${baseRouteUrl}/${id}` : `${baseRouteUrl}/add`
              }
              type="submit"
              isValid={true}
              isLoading={status.isLoading}
              disabled={isDisabled}
            />
          </div>
        </Grid>
      </Form>
    )}
  </Formik>
</div>
    </>
  );
}
