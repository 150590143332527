import { Box, Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import CustomPagination from "../../components/pagination";
import { i18n } from "../../translate/i18n";
import { useKeycloak } from "@react-keycloak/web";
import { AddBoxRounded } from "@mui/icons-material";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import AlertDialogForConfirm from "../../components/confirmation/AlertDialog";
import Loading from "../../components/loading/loading";
import { truncateText } from "../../utils/functions/utilFunctions";
import FilterList from "../../components/filterList/FilterList";

export default function GraduationListingPage() {
  const { keycloak } = useKeycloak();
  const [currentPage, setCurrentPage] = useState(1);
  let totalPages = 1;
  const pathUrl = "/graduations";
  const [incomingData, setIncomingData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClickOpen = (id) => {
    console.log("Opening modal. ID:", id);
    setDeleteItemId(id);
    setOpen(true);
  };

  const handleClose = () => {
    console.log("Closing modal");
    setDeleteItemId(null);
    setOpen(false);
  };

  const CreateGradua = () => {
    navigate("/graduation/create");
  };


  useEffect(() => {
    const searchGraduations = async (searchTerm) => {
      try {
        const response = await axios.get(
          `${apiUrl}/graduations?search=${encodeURIComponent(searchTerm)}`,
          {
            headers: {
              Authorization: "Bearer " + keycloak.token,
            },
          }
        );

        setSearchResults(
          response.data?.data.map((depart) => ({
            ...depart,
          }))
        );

        return response.data;
      } catch (error) {
        console.error("Error searching departments:", error);
        throw error;
      }
    };

    const delaySearch = setTimeout(() => {
      if (search.trim() !== "") {
        searchGraduations(search);
      }
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [search, keycloak.token]);

  const getEmployee = async () => {
    setLoading(true);
    if (!page) {
      setLoading(false);
      return;
    }

    const params = new URLSearchParams({ page: page });

    try {
      const response = await axios.get(
        `${apiUrl}/graduations?` + params.toString(),
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
      setIncomingData(response.data.data);
      console.log(incomingData);
      if (localStorage.getItem("enployeeslPage")) setPage(response.data.page);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (keycloak.token) {
      getEmployee();
    }
  }, [keycloak.token, page]);

  // ActionButtons props functions
  const handleDisplayDataToVisualize = (id) => {
    navigate(`${pathUrl}/${id}?disabled=true`);
  };

  const handleEdit = (id) => {
    navigate(`${pathUrl}/${id}/edit`);
  };
  const handleDeleteItem = async (id) => {
    console.log("Deleting item with ID:", id);
    setDeleteItemId(id);
    try {
      const response = await axios.delete(`${pathUrl}/${id}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      if (response.status === 200) {
        setIncomingData(
          incomingData.filter((incomingDate) => incomingDate.id !== id)
        );
      } else {
        console.error(
          "Failed to delete employee. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      handleClose(); // Move handleClose to the end to ensure it's called even if there's an error
    }
  };

  return (
    <>
      <Grid
        container
        component={Paper}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "relative",
          boxShadow: "none",
          padding: { xs: 2, md: 3 },
        }}
      >
        {loading && <Loading />}
        <Grid item xs={12}>
          {loading ? null : (
           <TableContainer
           sx={{
             overflowX: "auto",
             border: "1px solid #E9ECF5",
           }}
         >
           <Table
             sx={{
               minWidth: "100%",
               tableLayout: "fixed",
             }}
             aria-label="a dense table"
           >
           <TableHead>
             <TableRow>
               <TableCell
                align="left"
                 colSpan={3}
                 sx={{
                 height: "59px",
                fontSize: { xs: "12px", sm: "14px" },
                   }}
                  >
                      <Typography
                        variant="h3"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {i18n.t("others.listOfGraduation")}
                        <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                          <Box sx={{ width: { xs: "100%", sm: "300px" } }}>
                            <AutoSearch
                              placeholder={i18n.t("others.search")}
                              search={search}
                              setSearch={setSearch}
                              handler={() => setSearch("")}
                              sx={{ width: "100%" }}
                            />
                          </Box>

                          <Box sx={{ width: { xs: "100%", sm: "100px" }, marginLeft: { xs: 0, sm: "20px" } }}>
                            <Select
                              onChange={() => {}}
                              displayEmpty
                              inputProps={{
                                "aria-label": "Page size",
                              }}
                              sx={{
                                width: "80px",
                                height: "40px",
                              }}
                            >
                              {[5, 10, 20, 50, 100].map((size) => (
                                <MenuItem key={size} value={size}>
                                  {size}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "120px",
                              height: "40px",
                              border: "1px solid #C3CAD9",
                              marginRight: "20px",
                              borderRadius: "5px",
                            }}
                          >
                            <FilterList
                              cardStyle={{
                                position: "absolute",
                                zIndex: "1",
                                top: "140px",
                                right: "323px",
                              }}
                              filters={[]}
                              setCanFilter={() => {}}
                              canFilter={false}
                            />
                            {i18n.t("others.filter")}
                          </Box>

                          <Box>
                            <Button
                              variant="outlined"
                              sx={{
                                height: "35px",
                                width: "80px",
                                color: "#FFC107",
                                backgroundColor: "#FFFFFF",
                                borderColor: "#FFC107",
                                "&:hover": {
                                  color: "#FFC107",
                                  backgroundColor: "#FFFFFF",
                                  borderColor: "#FFC107",
                                },
                              }}
                              onClick={CreateGradua}
                            >
                              <AddBoxRounded />
                              {i18n.t("button.new")}
                            </Button>
                          </Box>
                        </Box>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: "#F8FAFD", height: "56px", color: "#939a9f" }}>
                    <TableCell sx={{ color: "#939a9f", width: "40%" }} align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.name")}</Typography></TableCell>
                    <TableCell sx={{ color: "#939a9f", width: "40%" }} align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.description")}</Typography></TableCell>
                    <TableCell sx={{ color: "#939a9f", width: "30%" }} align="center"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}> {i18n.t("others.options")}</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(search.trim() === "" ? incomingData : searchResults).map((data, i) => (
                    <TableRow key={data.id}>
                      <TableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> {data.name}</Typography></TableCell>
                      <TableCell align="left"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> {truncateText(data.description)}</Typography></TableCell>
                      <TableCell align="center"><Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: 500, }}> 
                        <ActionButtons
                          id={data.id}
                          handleVisualize={handleDisplayDataToVisualize}
                          handleEdit={handleEdit}
                          handleDeleteItem={() => handleClickOpen(data.id)}
                        />
                     </Typography> </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>

      {!loading && (
        <Grid sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <CustomPagination
            setPage={setCurrentPage}
            totalPages={totalPages}
            page={currentPage}
          />
        </Grid>
      )}

      {deleteItemId && (
        <AlertDialogForConfirm
          id={deleteItemId}
          open={open}
          handleClose={handleClose}
          handleDeleteItem={handleDeleteItem}
        />
      )}
    </>
  );
}